<script setup lang="ts">
import type { ClassBreaks } from '@/composables/classBreaks'
import type { RoadFeatureProperties, RoadMetric } from '@/composables/cyclability/cyclabilityRoads'
import type { HTMLAttributes } from 'vue'
import { tv } from 'tailwind-variants'

interface SegmentProperties extends RoadFeatureProperties {
  id: string
  roadName: string
  path: string | null
  [key: string]: any
}

interface Props {
  item: SegmentProperties
  title?: string
  name?: string
  subName?: string
  class?: HTMLAttributes['class']
  pathsMetric: RoadMetric | null
  compareTo: RoadMetric | null
  showCompareTo?: boolean
  classBreaks?: ClassBreaks
  isLink?: boolean
}

defineOptions({
  name: 'CyclabilitySegmentRowCard',
  inheritAttrs: false,
})

const props = defineProps<Props>()
const emit = defineEmits(['click'])

const { isSmall } = useResponsiveSidebarContainer()
const { getMetricIndice, getMetricValue } = useCyclabilityLabels()
const { translateRoads } = useCyclabilityTranslation()

function getGlobalMetricValue(item: SegmentProperties, metric: RoadMetric | null): number {
  if (!metric) {
    return 0
  }

  return (item[`${metric}Value`] as number) || 0
}

const ui = tv({
  slots: {
    container: 'group hover:cursor-pointer overflow-hidden',
    content: 'flex',
    left: 'flex space-x-2 justify-end items-center',
    leftItems: 'flex-1',
  },
  variants: {
    direction: {
      column: {
        container: 'h-[120px]',
        content: 'flex-col',
        left: 'text-left flex-row px-2 pb-1',
        leftItems: 'w-1/2 flex-1',
      },
      row: {
        container: 'h-[56px]',
        content: 'flex-row',
        left: 'text-right flex-row-reverse',
        leftItems: 'min-w-48 max-w-48',
      },
    },
  },
  defaultVariants: {
    direction: 'row',
  },
})

const classes = computed(() => ui({
  direction: isSmall.value ? 'column' : 'row',
}))
</script>

<template>
  <DCard
    no-padding
    no-elevation
    :class="[props.class, classes.container()]"
    @click="emit('click')"
  >
    <div :class="classes.content()">
      <div class="flex flex-1 space-x-2 justify-center items-center min-w-0">
        <div class="aspect-video h-14 object-cover rounded-md overflow-hidden flex-none shrink-0">
          <DAsyncImg
            :src="item.path || ''"
            :alt="title || name || subName || ''"
          />
        </div>

        <div class="flex-1 overflow-hidden">
          <p
            v-if="title"
            class="font-bold group-hover:text-blue-500 transition-colors duration-300 cd-ellipsis"
          >
            {{ title }}
          </p>
          <p
            v-if="name"
            class="font-bold group-hover:text-blue-500 transition-colors duration-300 cd-ellipsis"
          >
            {{ name }}
          </p>
          <p
            v-if="subName"
            class="text-sm text-gray-500 group-hover:text-blue-300 transition-colors duration-300 cd-ellipsis"
          >
            {{ subName }}
          </p>
        </div>
      </div>

      <div
        v-if="isSmall"
        class="flex text-xs px-2 pt-1 overflow-hidden"
      >
        <p class="flex-1 cd-ellipsis">
          {{ translateRoads(pathsMetric || '') }}
        </p>
        <p
          v-if="showCompareTo"
          class="flex-1 cd-ellipsis"
        >
          {{ translateRoads(compareTo || '') }}
        </p>
      </div>

      <div :class="classes.left()">
        <LabeledValue
          v-if="pathsMetric"
          :class="classes.leftItems()"
          :value="getGlobalMetricValue(item, pathsMetric) || getMetricValue(item, pathsMetric)"
          :break-value="getMetricValue(item, pathsMetric, true)"
          :indice="getMetricIndice(item, pathsMetric)"
          :class-breaks="classBreaks"
          upper-bound-included
          inverse
          column
        />

        <LabeledValue
          v-if="showCompareTo"
          :class="classes.leftItems()"
          :value="getGlobalMetricValue(item, compareTo) || getMetricValue(item, compareTo)"
          :indice="getMetricIndice(item, compareTo)"
          inverse
          column
        />
      </div>

      <div
        v-if="!isSmall && isLink"
        class="flex-center opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-300"
      >
        <DIcon
          path="chevron-right"
          size="lg"
          class="text-blue-500"
        />
      </div>
    </div>
  </DCard>
</template>
