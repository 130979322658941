import type { DocumentNode, OperationVariables, TypedDocumentNode } from '@apollo/client'

type Gql = '' | DocumentNode | TypedDocumentNode

const queries: [string, Gql][] = Object.entries(
  import.meta.glob('@/graphql/queries/*.gql', {
    eager: true,
    import: 'default',
  }),
)

export function useWriteCacheForMetric(kpi: MaybeRef<string>) {
  const kpiRef = ref(kpi)
  const { resolveClient } = useApolloClient()
  const client = resolveClient('default')

  const gql = computed((): Gql | undefined => {
    const query
      = kpiRef.value
      && queries.find(([path]) => path.includes(`/${kpiRef.value}.gql`))

    return query && query[1]
  })

  const { variables: globalVariables } = useMetricData(
    kpiRef,
    {},
    {
      queryOptions: {
        enabled: false,
        fetchPolicy: 'cache-only',
      },
    },
  )

  return {
    writeCache(variables: MaybeRef<OperationVariables>, data: any) {
      if (!gql.value) {
        return
      }

      client.writeQuery({
        query: gql.value,
        variables: {
          ...unref(globalVariables),
          ...unref(variables),
        },
        data: {
          [kpiRef.value]: {
            metric: kpiRef.value,
            help: null,
            data,
          },
        },
      })
    },
  }
}

export function useWriteCache(queryName: MaybeRef<string>) {
  const queryNameRef = ref(queryName)
  const { resolveClient } = useApolloClient()
  const client = resolveClient('default')

  const gql = computed(() => {
    const query
      = queryNameRef.value
      && queries.find(([path]) => path.includes(`/${queryNameRef.value}.gql`))

    return query && query[1]
  })

  return {
    writeCache(variables: MaybeRef<OperationVariables>, data: any) {
      if (!gql.value) {
        return
      }

      client.writeQuery({
        query: gql.value,
        variables: unref(variables),
        data: {
          [queryNameRef.value]: {
            ...data,
          },
        },
      })
    },
  }
}
