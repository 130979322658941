<script setup lang="ts">
import type { CyclabilityData } from '@/composables/cyclability/cyclabilityData'
import type { LngLatLike } from 'maplibre-gl'

interface CyclabilityMapProps {
  zoom?: number
  center?: LngLatLike
  data: CyclabilityData
  isLoading?: boolean
}

defineOptions({
  name: 'CyclabilityMap',
})

withDefaults(defineProps<CyclabilityMapProps>(), {
  zoom: 7,
  center: () => [-0.09, 51.505],
  data: () => ({}),
})

const emit = defineEmits(['on-zoom'])
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
    @zoomend="({ zoom }) => emit('on-zoom', zoom)"
  >
    <MapLibrePopup
      :offset="[0, -5]"
      :layer-ids="['cells-grid-points', 'city-network', 'cells-grid']"
    >
      <template #element="{ feature }">
        <div class="max-w-28 whitespace-normal text-xs">
          <template v-if="(feature?.layer?.id === 'cells-grid' || feature?.layer?.id === 'cells-grid-points') && data.areas?.metric">
            <p>
              <span>{{ data.areas.metricInfo?.name }}</span><br>
              <span class="font-bold">{{ formatNumber(feature?.properties?.[data.areas.metric]) }}</span>
            </p>
          </template>

          <template v-else-if="feature?.layer?.id === 'city-network'">
            <p v-if="data.network?.metric === 'road'">
              <span>{{ data.network?.metricInfo?.name }}</span><br>
              <span class="font-bold">{{ data.network?.labels?.[feature?.properties?.fluctuo_classification_id - 1] }}</span>
            </p>
            <p v-else-if="data.network?.metric">
              <span>{{ data.network.metricInfo.name }}</span><br>
              <span class="font-bold">{{ formatNumber(feature?.properties?.[data.network.metric]) }}</span>
            </p>
          </template>
        </div>
      </template>
    </MapLibrePopup>

    <MapLibreLayerKeepOrder>
      <CityNetwork
        v-if="data.network"
        layer-id="city-network"
        v-bind="data.network"
        :is-loading="isLoading"
      />
      <CellsGrid
        v-if="data.areas"
        layer-id="cells-grid"
        poi-layer-before-id="city-network"
        v-bind="data.areas"
      />
    </MapLibreLayerKeepOrder>

    <CyclabilityMapLegends :data="data" />
  </MapLibre>
</template>
