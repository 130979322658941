<script setup lang="ts">
import type { DateRange } from '@/stores/filters'

interface Props {
  dateRange: DateRange | null
  comparativeRange: DateRange | null
  withComparison?: boolean
  minDate?: Date
  maxDate?: Date
  disabledDates?: Date[]
}

const props = withDefaults(defineProps<Props>(), {
  dateRange: null,
  comparativeRange: null,
  withComparison: false,
  disabledDates: () => [],
})

const emit = defineEmits<{
  'update:dateRange': [value: DateRange | null]
  'update:comparativeRange': [value: DateRange | null]
  'compare': []
}>()

const { dateRange, comparativeRange } = useVModels(props, emit)

const { t } = useI18n()
const selectedRange = ref<number | null>(null)
const selectedComparative = ref<number>(0)

const { relativeRanges, relativeComparatives } = useDateRangeFilter()

watchEffect(() => {
  if (dateRange.value) {
    const { start, end } = dateRange.value
    const defaultDateIndex = Object.values(relativeRanges).findIndex(
      ({ period }) => {
        return (
          period.start.getTime() === start.getTime()
          && period.end.getTime() === end.getTime()
        )
      },
    )

    selectedRange.value = Number(Object.keys(relativeRanges)[defaultDateIndex]) || null
  }
})

watchEffect(() => {
  if (props.withComparison && comparativeRange.value && dateRange.value) {
    const { start, end } = comparativeRange.value
    const defaultDateIndex = Object.values(relativeComparatives).findIndex(
      ({ period }) => {
        const compare = period(dateRange.value as DateRange)
        return (
          compare.start.getTime() === start.getTime()
          && compare.end.getTime() === end.getTime()
        )
      },
    )

    selectedComparative.value = Number(Object.keys(relativeComparatives)[defaultDateIndex]) || 0
  }
})
</script>

<template>
  <div class="date-range-picker">
    <div class="hidden md:block">
      <DatePicker
        v-model="dateRange"
        :disabled-dates="disabledDates"
        :min-date="minDate"
        :max-date="maxDate"
      />

      <div v-if="withComparison && comparativeRange">
        <p class="text-center text-sm">
          {{ t('Compared to') }}
        </p>
        <DatePicker
          v-model="comparativeRange"
          class="comparated-date"
          :disabled-dates="disabledDates"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </div>
    </div>

    <div
      class="flex flex-col space-y-2 border-l border-grey-100 py-4 px-6 text-center"
    >
      <DateRangeForm v-model="dateRange" />

      <DButton
        v-for="(relative, index) in relativeRanges"
        :key="index"
        :class="{ active: selectedRange === Number(index) }"
        variant="outline-primary"
        size="sm"
        @click="() => { dateRange = relative?.period ?? null }"
      >
        {{ relative?.name }}
      </DButton>

      <a
        v-if="withComparison"
        class="mb-3 py-2 text-sm font-bold text-blue-500 underline"
        @click="() => emit('compare')"
      >
        {{ comparativeRange ? t('Cancel comparaison') : t('Compare to') }}
      </a>

      <template v-if="withComparison && comparativeRange">
        <DateRangeForm v-model="comparativeRange" />

        <DButton
          v-for="(relative, index) in relativeComparatives"
          :key="index"
          :class="{ active: selectedComparative === index }"
          variant="outline-primary"
          size="sm"
          @click="() => {
            if (dateRange) {
              comparativeRange = relative.period(dateRange)
            }
          }"
        >
          {{ relative.name }}
        </DButton>
      </template>
    </div>
  </div>
</template>

<style>
@reference '@/assets/style/index.css';

.date-range-picker {
  .comparated-date.vc-container {
    --blue-200: #f1ebfc;
    --blue-600: #ab89ee;
  }

  @apply flex flex-col md:flex-row bg-white;
}
</style>
