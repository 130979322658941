<script setup lang="ts">
import type { CyclabilityData } from '@/composables/cyclability/cities/cyclabilityCitiesData'

import {
  endOfDay,
  startOfDay,
  subMonths,
} from 'date-fns'

defineOptions({
  name: 'CityCyclabilityBikesPerformance',
})

const { t } = useI18n()
const { providerNameBySlug } = useProviderName()
const { writeCache } = useWriteCacheForMetric('cityCyclabilityBikesPerformance')
const { dateRange: defaultDateRange, dateRangeLimit, getDataInRange, getLastDateRange, city } = useCyclabilityCitiesData()
const { translateRange } = useDateTranslation()

const dateRange = computed(() => {
  if (!dateRangeLimit.value) {
    return defaultDateRange.value
  }

  return {
    start: startOfDay(subMonths(dateRangeLimit.value?.end, 2)),
    end: endOfDay(dateRangeLimit.value?.end),
  }
})

interface ProvidersByCity {
  [key: string]: {
    BIKE?: string[]
    STATION?: string[]
  }
}

const providersByCity: ProvidersByCity = {
  barcelona: {
    BIKE: ['ecooltra'],
    STATION: ['bicing', 'nextbike', 'donkey'],
  },
  sarajevo: {
    STATION: ['nextbike'],
  },
  skopje: {
    STATION: [],
  },
}

const lastDateRange = computed(() => getLastDateRange(dateRange.value))

function calculateTrendData(data: CyclabilityData[], target: keyof Omit<CyclabilityData, 'date'>) {
  const avgLength = (data?.length || 1) / 2
  if (data.filter(item => item[target] !== undefined).length > 0) {
    return data?.reduce((acc: number, item) => acc + item[target], 0) / avgLength
  }
}

function updateCachedData(dataInRange: CyclabilityData[], comparedDataInRange: any) {
  // Calculate daily averages for fleet
  const fleet = calculateTrendData(dataInRange, 'freeFloatingVehicles')
  const station = calculateTrendData(dataInRange, 'stationBasedVehicles')
  const totalAvgFleet = (fleet || 0) + (station || 0)

  // Keep total trips
  const trips = calculateTrendData(dataInRange, 'freeFloatingTrips')
  const stationTrips = calculateTrendData(dataInRange, 'stationBasedTrips')
  const totalAvgTrips = (trips || 0) + (stationTrips || 0)

  // Calculate daily averages for previous period fleet
  const fleetLast = calculateTrendData(comparedDataInRange, 'freeFloatingVehicles')
  const stationLast = calculateTrendData(comparedDataInRange, 'stationBasedVehicles')
  const totalAvgFleetLast = (fleetLast || 0) + (stationLast || 0)

  // Keep total trips for previous period
  const tripsLast = calculateTrendData(comparedDataInRange, 'freeFloatingTrips')
  const stationTripsLast = calculateTrendData(comparedDataInRange, 'stationBasedTrips')
  const totalAvgTripsLast = (tripsLast || 0) + (stationTripsLast || 0)

  const fleetTrendPercent = (totalAvgFleet - totalAvgFleetLast) / totalAvgFleetLast * 100
  const tripsTrendPercent = (totalAvgTrips - totalAvgTripsLast) / totalAvgTripsLast * 100

  const fleetTrendDirection = fleetTrendPercent > 0 ? Directions.UP : Directions.DOWN
  const tripsTrendDirection = tripsTrendPercent > 0 ? Directions.UP : Directions.DOWN

  const fleetTrendPositive = fleetTrendPercent > 0
  const tripsTrendPositive = tripsTrendPercent > 0

  const fleetTrend = {
    percent: Number(fleetTrendPercent.toFixed(2)),
    direction: fleetTrendDirection,
    positive: fleetTrendPositive,
  }

  const tripsTrend = {
    percent: Number(tripsTrendPercent.toFixed(2)),
    direction: tripsTrendDirection,
    positive: tripsTrendPositive,
  }

  writeCache({
    city: city.value,
    dateRange: dateRange.value,
  }, {
    fleet: {
      total: {
        __typename: 'Percent',
        value: Math.round(totalAvgFleet),
        trend: fleetTrend,
      },
      vehicleTypes: {
        ...(fleet && { BIKE: Math.round(fleet) }),
        ...(station && { STATION: Math.round(station) }),
      },
      providers: {
        ...(fleet && city.value && typeof city.value === 'string' && providersByCity[city.value]?.BIKE && { BIKE: providersByCity[city.value].BIKE }),
        ...(station && city.value && typeof city.value === 'string' && providersByCity[city.value]?.STATION && { STATION: providersByCity[city.value].STATION }),
      },
    },
    trips: {
      total: {
        __typename: 'Percent',
        value: Math.round(totalAvgTrips),
        trend: tripsTrend,
      },
      vehicleTypes: {
        ...(trips && { BIKE: Math.round(trips) }),
        ...(stationTrips && { STATION: Math.round(stationTrips) }),
      },
      providers: {
        ...(trips && city.value && typeof city.value === 'string' && providersByCity[city.value]?.BIKE && { BIKE: providersByCity[city.value].BIKE }),
        ...(stationTrips && city.value && typeof city.value === 'string' && providersByCity[city.value]?.STATION && { STATION: providersByCity[city.value].STATION }),
      },
    },
  })
}

interface VehicleData {
  fleet: {
    providers: Record<string, string[]>
  }
  trips: {
    providers: Record<string, string[]>
  }
}

function vehicleTypesTitles(type: 'fleet' | 'trips', data: VehicleData) {
  const providers = data[type].providers
  const result: Record<string, string> = {}

  Object.entries(providers).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      result[key] = t(`cyclability.bikes_performance.${key.toLowerCase()}`) + (value.length > 0 ? ` (${value.map(slug => providerNameBySlug(slug)).join(', ')})` : '')
    }
  })

  return result
}

watch([dateRange, city], () => {
  if (!dateRange.value) {
    return
  }

  const dataInRange = getDataInRange(dateRange.value)
  const comparedDataInRange = getDataInRange(lastDateRange.value)

  updateCachedData(dataInRange, comparedDataInRange)
}, { immediate: true })
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityBikesPerformance"
    :used-filters="[]"
  >
    <template #title>
      {{ t('cyclability.bikes_performance.title') }}
    </template>

    <template #subtitle>
      {{ t('dates.daterange_formated_human', translateRange(dateRange)) }}
    </template>

    <template #default="{ data }">
      <div class="flex flex-col xl:flex-row gap-4 flex-wrap">
        <div class="rounded-md bg-grey-50 p-4 flex-1">
          <p class="text-sm mb-2">
            <DIcon
              path="fleet"
              size="sm"
            />{{ t('cyclability.bikes_performance.avg_daily_fleet') }}
          </p>
          <TrendMetricWithVehiclesTypes
            :data="data.fleet.total"
            :vehicles-types="data.fleet.vehicleTypes"
            :accept="Object.keys(data.fleet.vehicleTypes) as ('BIKE' | 'STATION')[]"
            :trend-title="`${t('cyclability.bikes_performance.percent_growth_vs')} ${t('dates.daterange_formated_human', translateRange(lastDateRange))}`"
            :titles="vehicleTypesTitles('fleet', data)"
          />
        </div>
        <div class="rounded-md bg-grey-50 p-4 flex-1">
          <p class="text-sm mb-2">
            <DIcon
              path="trips"
              size="sm"
            />{{ t('cyclability.bikes_performance.avg_daily_trips') }}
          </p>
          <TrendMetricWithVehiclesTypes
            :data="data.trips.total"
            :vehicles-types="data.trips.vehicleTypes"
            :accept="Object.keys(data.trips.vehicleTypes) as ('BIKE' | 'STATION')[]"
            :trend-title="`${t('cyclability.bikes_performance.percent_growth_vs')} ${t('dates.daterange_formated_human', translateRange(lastDateRange))}`"
            :titles="vehicleTypesTitles('trips', data)"
          />
        </div>
      </div>
    </template>
  </MetricCard>
</template>
