<script setup lang="ts">
defineOptions({
  name: 'CyclabilityOverviewSarajevo',
})

const { t } = useI18n()
const { resetDateRange } = useCyclabilityCitiesData()

onBeforeUnmount(() => {
  resetDateRange()
})
</script>

<template>
  <BoardSubTitle icon="list">
    {{ t('cyclability.bike_usage') }}
  </BoardSubTitle>

  <BoardGrid class="mt-4 grid-cols-1 lg:grid-cols-6">
    <CityCyclabilityPassagesMetric class="lg:col-span-3 2xl:col-span-4" />
    <CityCyclabilityBikesPerformance class="lg:col-span-3 2xl:col-span-2" />
    <CityCyclabilityPassagesHistoryMetric class="lg:col-span-6" />
    <CityCyclabilitySharedBikesPerfMetric class="lg:col-span-6" />
  </BoardGrid>

  <BoardSubTitle icon="list">
    {{ t('cyclability.additional_informations') }}
  </BoardSubTitle>

  <BoardGrid class="mt-4 grid-cols-1 lg:grid-cols-6">
    <CityCyclabilitySarajevoCyclistAnalysisMetric class="lg:col-span-3" />
    <CityCyclabilitySarajevoNetworkOverview class="lg:col-span-3" />
  </BoardGrid>
</template>
