<script setup>
const props = defineProps({
  min: { type: Number, default: 0 },
  max: { type: Number, default: 100 },
  step: { type: Number, default: 1 },
  modelValue: {
    type: [Number, Array],
    default: 0,
  },
  size: {
    type: String,
    default: 'md',
    validation: value => ['md', 'lg'].includes(value),
  },
  light: Boolean,
})

const emit = defineEmits(['update:modelValue'])
const data = computed({
  set(value) {
    emit('update:modelValue', value)
  },
  get() {
    return props.modelValue
  },
})

const attrs = useAttrs()

const label = inject(PROVIDE_UI_FIELD_LABEL, '')
const keepMax = ref(true)
const keepMin = ref(true)
const isDoubleRange = computedEager(() => Array.isArray(props.modelValue))
const percentValues = computed(() => {
  const [lower, upper] = props.modelValue
  const percents = [
    ((lower - props.min) / (props.max - props.min)) * 100,
    ((upper - props.min) / (props.max - props.min)) * 100,
  ]

  return [
    percents[0] < 0 ? 0 : percents[0],
    percents[1] > 100 ? 100 : percents[1],
  ]
})

watch(
  [() => props.min, () => props.max],
  ([min, max]) => {
    if (get(isDoubleRange)) {
      const [lower, upper] = get(data)

      if ((lower > min && get(keepMin)) || lower < min) {
        data.value[0] = min
      }

      if ((upper < max && get(keepMax)) || upper > max) {
        data.value[1] = max
      }

      if (lower >= max) {
        data.value[0] = max - props.step
      }

      if (upper <= min) {
        data.value[1] = min + props.step
      }
    }
  },
  { immediate: true },
)

function updateMax(upper) {
  const { max, min, step } = props
  const lower = data.value[0]

  set(keepMax, false)
  data.value[1] = upper

  if (upper <= lower + step) {
    data.value[0] = upper - step

    if (upper - step <= min) {
      data.value[1] = min + step
      data.value[0] = min
      set(keepMin, true)
    }
  }

  if (upper >= max - step) {
    data.value[1] = max
    set(keepMax, true)
  }
}

function updateMin(lower) {
  const { max, min, step } = props
  const upper = data.value[1]

  set(keepMin, false)
  data.value[0] = lower

  if (lower >= upper - step) {
    data.value[1] = lower + step

    if (lower + step >= max) {
      data.value[0] = max - step
      data.value[1] = max
      set(keepMax, true)
    }
  }

  if (lower <= min + step) {
    data.value[0] = min
    set(keepMin, true)
  }
}
</script>

<template>
  <div
    class="d-input-range"
    :class="[size, { light }]"
  >
    <div
      v-if="isDoubleRange"
      class="d-input-double-range relative h-6 w-full"
    >
      <div
        class="bar pointer-events-none absolute top-2 z-1 h-2 rounded-lg bg-blue-500"
        :style="{
          left: `${percentValues[0]}%`,
          width: `${percentValues[1] - percentValues[0]}%`,
        }"
      />
      <input
        :value="data[0]"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        :aria-label="label"
        v-bind="attrs"
        @input="$event => updateMin(Number($event.target.value))"
      >
      <input
        :value="data[1]"
        class="bg-transparent!"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        :aria-label="label"
        v-bind="attrs"
        @input="$event => updateMax(Number($event.target.value))"
      >
    </div>

    <input
      v-else
      :value="data"
      type="range"
      :min="min"
      :max="max"
      :step="step"
      :aria-label="label"
      v-bind="attrs"
      @input="$event => (data = Number($event.target.value))"
    >

    <p class="flex justify-between text-xs">
      <span>{{ formatNumber(min, { notation: 'compact' }) }}</span>
      <span>{{ formatNumber(max, { notation: 'compact' }) }}</span>
    </p>
  </div>
</template>

<style>
@reference '@/assets/style/index.css';

.d-input-range {
  input[type='range'] {
    @apply w-full rounded-lg appearance-none cursor-pointer outline-hidden p-0 text-inherit box-border;

    /* Chrome */
    &::-webkit-slider-runnable-track {
      @apply h-full border-none rounded-full bg-transparent;
    }
    &::-webkit-slider-thumb {
      @apply appearance-none border-none rounded-full cursor-pointer;
    }

    /* Firefox */
    &::-moz-range-track {
      @apply h-full border-none rounded-full bg-transparent;
    }

    &::-moz-range-thumb {
      @apply appearance-none border-none rounded-full cursor-pointer;
    }

    &::-moz-range-progress {
      @apply h-0 bg-transparent;
    }

    /* Edge */
    &::-ms-track {
      @apply h-full border-none rounded-full text-transparent bg-transparent;
    }

    &::-ms-thumb {
      @apply appearance-none border-none rounded-full cursor-pointer;
    }

    &::-ms-tooltip {
      display: none;
    }

    &::-ms-fill-lower {
      background: transparent;
    }

    &::-ms-fill-upper {
      background: transparent;
    }
  }

  &:not(.light) input[type='range'] {
    @apply bg-gray-200 text-blue-500;

    &::-webkit-slider-thumb {
      @apply bg-blue-500;
    }
    &::-moz-range-thumb {
      @apply bg-blue-500;
    }
    &::-ms-thumb {
      @apply bg-blue-500;
    }
  }

  &.light input[type='range'] {
    @apply bg-black/20 text-white;

    &::-webkit-slider-thumb {
      @apply bg-white;
    }
    &::-moz-range-thumb {
      @apply bg-white;
    }
    &::-ms-thumb {
      @apply bg-white;
    }
  }

  &.md input[type='range'] {
    @apply h-2;

    &::-webkit-slider-thumb {
      @apply w-4 h-4 -mt-1;
    }
    &::-moz-range-thumb {
      @apply w-4 h-4 -mt-1;
    }
    &::-ms-thumb {
      @apply w-4 h-4 -mt-1;
    }
  }

  &.lg input[type='range'] {
    @apply h-4;

    &::-webkit-slider-thumb {
      @apply w-6 h-6 -mt-1;
    }
    &::-moz-range-thumb {
      @apply w-4 h-4 -mt-1;
    }
    &::-ms-thumb {
      @apply w-4 h-4 -mt-1;
    }
  }
}

.d-input-double-range input[type='range'] {
  @apply absolute w-full top-1/3 pointer-events-none;

  &::-webkit-slider-thumb {
    @apply z-hop pointer-events-auto;
  }
  &::-moz-range-thumb {
    @apply z-hop pointer-events-auto;
  }
  &::-ms-thumb {
    @apply z-hop pointer-events-auto;
  }
}
</style>
