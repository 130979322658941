<script setup>
import { nanoid } from 'nanoid'

const props = defineProps({
  modelValue: {
    type: [Array, Boolean],
    default: null,
  },
  size: {
    type: String,
    default: 'md',
    validation: value => ['sm', 'md', 'lg'].includes(value),
  },
  disabled: Boolean,
})

const emit = defineEmits(['update:modelValue'])
const uid = `d-switch-${nanoid()}`
const data = useVModel(props, 'modelValue', emit)
const attrs = useAttrs()

const label = inject(PROVIDE_UI_FIELD_LABEL, '')
</script>

<template>
  <div class="relative mr-3 inline-flex items-center">
    <input
      :id="uid"
      v-model="data"
      type="checkbox"
      :aria-label="label"
      :disabled="disabled"
      v-bind="attrs"
      class="h-4 w-4 cursor-pointer rounded-sm border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
      :class="{
        'cursor-not-allowed bg-gray-200': disabled,
      }"
    >
    <label
      v-if="$slots.default"
      :for="uid"
      class="ml-2 text-sm font-medium text-gray-900"
    >
      <slot />
    </label>
  </div>
</template>
