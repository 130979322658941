<script setup>
defineProps({
  variant: {
    type: String,
    default: 'default',
    validation: value => ['default', 'secondary'].includes(value),
  },
  warning: Boolean,
})
const emit = defineEmits(['remove'])
</script>

<template>
  <div
    class="mr-1 inline-flex rounded-full bg-blue-500 py-0.5 pl-2 pr-0.5 text-sm font-bold text-white items-center"
    :class="{
      'bg-teal-800!': !warning && variant === 'secondary',
      'bg-amber-500!': warning,
    }"
  >
    <DIcon
      v-if="warning"
      path="warning"
      size="sm"
      class="-my-1"
    />
    <slot />
    <span
      class="ml-2 inline-block h-5 w-5 cursor-pointer rounded-full px-1 text-center align-middle text-lg font-bold hover:bg-white/30 hover:text-white transition-all"
      @click="emit('remove')"
    >
      -
    </span>
  </div>
</template>
