<script setup lang="ts">
import type { AnyVehicleTypes } from '@/types/vehicleTypes.types'

// Define type for valid vehicle sizes
type VehicleSize = 'xs' | 'sm' | 'md' | 'lg'

// Define props interface
interface VehicleIconProps {
  type: AnyVehicleTypes
  disabled?: boolean
  size?: VehicleSize
  title?: string
}

defineOptions({
  name: 'VehicleIcon',
})

const props = withDefaults(defineProps<VehicleIconProps>(), {
  disabled: false,
  size: 'sm',
})

const { t } = useI18n()
const typeStr = computed(() => getVehicleType(props.type))
const classes = computed(() => getVehicleTypeClasses(typeStr.value))
</script>

<template>
  <DTooltip class="inline-flex">
    <template #content>
      <p class="w-24">
        {{ title ?? capitalize(t(`${typeStr}s`)) }}
      </p>
    </template>
    <DIcon
      :path="typeStr"
      :size="size"
      class="mt-0! flex-none rounded-full border"
      :class="[classes, { 'opacity-20': disabled }]"
    />
  </DTooltip>
</template>
