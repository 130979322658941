<script setup lang="ts">
import { useElementSize, useScroll, useWindowScroll } from '@vueuse/core'

interface Props {
  items: any[]
  itemHeight?: number
  defaultItemHeight?: number
  buffer?: number
  useGlobalScroll?: boolean
  scrollContainer?: HTMLElement
  gap?: number
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  buffer: 3,
  skeletonCount: 10,
  defaultItemHeight: 50,
  gap: 0,
})

const localContainerRef = ref<HTMLElement>()
const container = computed(() => {
  if (props.scrollContainer) {
    return props.scrollContainer
  }
  return localContainerRef.value
})

const { height: containerHeight } = useElementSize(container)
const { y: localScrollTop } = useScroll(container)
const { y: globalScrollTop } = useWindowScroll()

// Calculate container position relative to top of page
const containerOffset = computed(() => {
  if (!container.value) {
    return 0
  }
  return container.value.getBoundingClientRect().top + window.scrollY
})

// Use global or local scroll based on prop
const effectiveScrollTop = computed(() => {
  if (props.useGlobalScroll) {
    // Adjust global scroll based on container position
    const adjustedScroll = globalScrollTop.value - containerOffset.value
    return Math.max(0, adjustedScroll)
  }
  return localScrollTop.value
})

// Add Map to store element heights
const itemHeights = ref(new Map<number, number>())
const isInitialized = ref(false)
const pendingMeasurements = ref(new Set<number>())

// Function to update element height
function updateItemHeight(index: number, height: number) {
  if (height === 0) {
    return
  }

  const oldHeight = itemHeights.value.get(index)
  if (oldHeight !== height) {
    itemHeights.value.set(index, height)
    pendingMeasurements.value.delete(index)
  }
}

// Add initialization function
function initializeHeights() {
  if (isInitialized.value) {
    return
  }

  // Initialize all items with estimated heights
  props.items.forEach((_, index) => {
    if (!itemHeights.value.has(index)) {
      itemHeights.value.set(index, props.defaultItemHeight)
      pendingMeasurements.value.add(index)
    }
  })

  isInitialized.value = true
}

// Calculate total list height
const totalHeight = computed(() => {
  if (props.itemHeight) {
    return props.items.length * props.itemHeight + (props.items.length - 1) * props.gap
  }

  return props.items.reduce((total, _, index, array) => {
    const height = itemHeights.value.get(index) ?? props.defaultItemHeight
    const isLast = index === array.length - 1
    return total + height + (isLast ? 0 : props.gap)
  }, 0)
})

// Calculate starting position of an element
function getItemOffset(index: number) {
  if (props.itemHeight) {
    return index * (props.itemHeight + props.gap)
  }

  let offset = 0
  for (let i = 0; i < index; i++) {
    offset += (itemHeights.value.get(i) ?? props.defaultItemHeight) + props.gap
  }
  return offset
}

// Update visible elements calculation
const visibleItems = computed(() => {
  if (!containerHeight.value) {
    return []
  }

  let start = 0
  let currentOffset = 0

  // Initialize heights if not done
  initializeHeights()

  // Find starting index
  while (currentOffset < effectiveScrollTop.value && start < props.items.length) {
    currentOffset += (itemHeights.value.get(start) ?? props.defaultItemHeight) + props.gap
    start++
  }

  start = Math.max(0, start - 1)

  // Find visible elements
  const visibleIndices = []
  let currentHeight = 0
  let index = start

  while (currentHeight < containerHeight.value + 200 && index < props.items.length) {
    visibleIndices.push(index)
    currentHeight += (itemHeights.value.get(index) ?? props.defaultItemHeight) + props.gap
    index++
  }

  // Add buffer
  const startWithBuffer = Math.max(0, start - (props.buffer ?? 3))
  const endWithBuffer = Math.min(props.items.length, index + (props.buffer ?? 3))

  return props.items
    .slice(startWithBuffer, endWithBuffer)
    .map((item, idx) => ({
      item,
      index: startWithBuffer + idx,
    }))
})

const containerStyle = computed(() => ({
  height: '100%',
  overflow: props.useGlobalScroll || props.scrollContainer ? 'visible' : 'auto',
  position: 'relative' as const,
}))

function forceUpdateHeights() {
  // // Reset stored heights
  // itemHeights.value = new Map<number, number>()
  // // Force update on next tick
  // nextTick(() => {
  //   // Recalculate heights for visible elements
  //   visibleItems.value.forEach(({ index }) => {
  //     const el = document.querySelector(`[data-virtual-index="${index}"]`) as HTMLElement
  //     if (el) {
  //       updateItemHeight(index, el.offsetHeight)
  //     }
  //   })
  // })

  isInitialized.value = false
  itemHeights.value = new Map<number, number>()
  pendingMeasurements.value = new Set<number>()

  nextTick(() => {
    initializeHeights()
  })
}

// Add watch to handle items changes
watch([() => props.items.length, () => props.defaultItemHeight], () => {
  // Reset initialization when items change
  isInitialized.value = false
  nextTick(() => {
    initializeHeights()
  })
})

// Expose function to parent component
defineExpose({
  forceUpdateHeights,
})
</script>

<template>
  <div
    ref="localContainerRef"
    :style="containerStyle"
  >
    <div
      :style="{
        height: `${totalHeight}px`,
        position: 'relative',
      }"
    >
      <div
        v-for="{ item, index } in visibleItems"
        :key="index"
        :style="{
          position: 'absolute',
          top: `${getItemOffset(index)}px`,
          width: '100%',
          height: props.itemHeight ? `${props.itemHeight}px` : 'auto',
        }"
      >
        <div :ref="(el) => el && updateItemHeight(index, (el as HTMLElement).offsetHeight)">
          <slot
            :item="item"
            :index="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>
