<script setup lang="ts">
import type { CyclabilityData } from '@/composables/cyclability/cyclabilityData'
import type { RoadMetric } from '@/composables/cyclability/cyclabilityRoads'
import type { LngLatLike } from 'maplibre-gl'

defineOptions({
  name: 'CyclabilityMapSegments',
})

withDefaults(defineProps<CyclabilityMapSegmentsProps>(), {
  zoom: 7,
  center: () => [-0.09, 51.505],
  data: () => ({}),
})

const emit = defineEmits(['onZoom'])

interface CyclabilityMapSegmentsProps {
  zoom?: number
  center?: LngLatLike
  data: CyclabilityData
  legendTitle?: string
  isLoading?: boolean
}

const { selectedArea } = useSelectedAreaFromUrl()
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
    @zoomend="({ zoom }) => emit('onZoom', zoom)"
  >
    <MapLibrePopup
      :offset="[0, -5]"
      :layer-ids="['city-segments', 'cells-grid-points', 'city-network', 'cells-grid']"
    >
      <template #element="{ feature }">
        <div
          v-if="feature"
          class="w-28 whitespace-normal text-xs"
        >
          <template v-if="(feature.layer?.id === 'cells-grid' || feature.layer?.id === 'cells-grid-points') && data.areas?.metric">
            <p>
              <span>{{ data.areas.metricInfo.name }}</span><br>
              <span class="font-bold">{{ formatNumber(feature.properties?.[data.areas.metric]) }}</span>
            </p>
          </template>

          <template v-else-if="feature.layer?.id === 'city-network' && data.network?.metric">
            <p v-if="data.network.metric === 'road'">
              <span>{{ data.network.metricInfo.name }}</span><br>
              <span class="font-bold">{{ data.network.labels?.[feature.properties?.fluctuo_classification_id - 1] }}</span>
            </p>
            <p v-else>
              <span>{{ data.network.metricInfo.name }}</span><br>
              <span class="font-bold">{{ formatNumber(feature.properties?.[data.network.metric]) }}</span>
            </p>
          </template>

          <template v-else-if="data.roads?.metric">
            <p v-if="getRoadMetrics.cycleRap().includes(data.roads.metric as RoadMetric)">
              <span>{{ data.roads.metricInfo.name }}</span><br>
              <span class="font-bold">{{ data.roads.labels?.[feature.properties?.[data.roads.metric] - 1] }}</span>
            </p>
            <p v-else-if="data.roads.metric === 'pothole'">
              <span>{{ data.roads.metricInfo.name }}</span><br>
              <span class="font-bold">{{ data.roads.labels?.[feature.properties?.pothole] }}</span>
            </p>
            <p v-else>
              <span>{{ data.roads.metricInfo.name }}</span><br>
              <span class="font-bold">{{ formatNumber(feature.properties?.[data.roads.metric]) }}</span><br>
              <span
                v-if="data.roads.outline"
                class="text-xs text-gray-500"
              >
                <span>{{ data.roads.outline.metricInfo.name }} :</span><br>
                <span class="font-bold">{{ data.roads.outline.labels?.[feature.properties?.[data.roads.outline.metric] - 1] }}</span>
              </span>
            </p>
          </template>
        </div>
      </template>
    </MapLibrePopup>

    <MapLibreLayerKeepOrder>
      <CitySegments
        v-if="data?.roads"
        layer-id="city-segments"
        v-bind="data.roads"
        :selected="selectedArea"
      />

      <CityNetwork
        v-if="data?.network"
        layer-id="city-network"
        v-bind="data.network"
        :is-loading="isLoading"
      />

      <CellsGrid
        v-if="data?.areas"
        layer-id="cells-grid"
        poi-layer-before-id="city-network"
        v-bind="data.areas"
      />
    </MapLibreLayerKeepOrder>

    <MapSelectArea
      :layers-ids="['city-segments']"
      :show-button="false"
    />

    <CyclabilityMapLegends :data="data" />
  </MapLibre>

  <CyclabilitySidebarSegment :data="data" />
</template>
