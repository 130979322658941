<script setup lang="ts">
import Barcelona from './cities/Barcelona.vue'
import Sarajevo from './cities/Sarajevo.vue'
import Skopje from './cities/Skopje.vue'

defineOptions({
  name: 'CyclabilityOverview',
})

const { t } = useI18n()

const availableScreenCities: Record<string, Component> = {
  barcelona: Barcelona,
  sarajevo: Sarajevo,
  skopje: Skopje,
}

const cityStore = useCityStore()
const { slug, city, isLoading } = storeToRefs(cityStore)
</script>

<template>
  <div class="overview">
    <DCard
      no-padding
      class="rounded-l-none border-solid border-l-4 border-grey-900 mt-16"
    >
      <div class="flex flex-col md:flex-row justify-between py-4 px-4 md:px-6">
        <div>
          <p class="font-bold text-grey-500">
            Welcome to
          </p>
          <img
            src="@/assets/svg/cyclability/logo.svg"
            alt="CityDive Velovision"
            width="100"
          >
        </div>
        <div class="text-right text-xs">
          <p class="text-gray-400">
            Developed by <span class="font-bold">Factual</span>, <span class="font-bold">Fluctuo</span><br>
            and <span class="font-bold">Social Tech Projects</span>
          </p>

          <p class="text-gray-400 mt-4">
            With the support of<br>
            <span class="font-bold">EIT Urban Mobility</span>
          </p>
        </div>
      </div>
    </DCard>

    <BoardTitle
      v-if="!isLoading"
      hide-on-mobile
    >
      {{ t(`cyclability.city_cycling_title.${city?.name}`) }}
    </BoardTitle>

    <component
      :is="availableScreenCities[slug]"
      v-if="slug && availableScreenCities[slug]"
    />

    <hr class="my-8 border-grey-700">

    <div class="flex flex-col lg:flex-row lg:justify-between gap-6">
      <!-- Section Developed by -->
      <div>
        <p class="text-gray-600 text-sm mb-4">
          Developed by
        </p>
        <div class="flex flex-wrap lg:flex-row flex-col items-center gap-8">
          <img
            src="/img/factual-logo.png"
            alt="Factual"
            class="h-8"
          >
          <img
            src="/img/fluctuo-logo.png"
            alt="Fluctuo"
            class="h-8"
          >
          <img
            src="/img/social-tech-projects-logo.png"
            alt="Social Tech Projects"
            class="h-12"
          >
        </div>
      </div>

      <!-- Section With the support of -->
      <div>
        <p class="text-gray-600 text-sm mb-4">
          With the support of
        </p>
        <div class="flex flex-wrap lg:flex-row flex-col items-center gap-8">
          <img
            src="/img/eit-logo.png"
            alt="EIT Urban Mobility"
            class="h-10"
          >
          <div class="flex flex-row lg:flex-row-reverse items-center gap-2">
            <p class="text-[#0d4ea3] text-sm font-eit text-right lg:text-left">
              Co-funded by the<br> European Union
            </p>
            <img
              src="/img/eu-1-logo.png"
              alt="European Union"
              class="h-10"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
