<script setup lang="ts">
defineOptions({
  name: 'CityCyclabilityPassagesHistoryMetric',
})

defineProps<{
  subtitle?: string
}>()

const { t } = useI18n()
const { writeCache } = useWriteCacheForMetric('cityCyclabilityPassagesHistory')
const { dateRangeCounter, dataInRange, city } = useCyclabilityCitiesDataFromCounter()
const { translateRange } = useDateTranslation()

function updateCachedData() {
  if (!city.value) {
    return
  }

  writeCache({
    city: city.value,
    dateRange: dateRangeCounter.value,
  }, {
    series: [
      {
        name: 'all',
        label: 'All',
        color: '#33488a',
        data: dataInRange.value?.map(item => [formatDate(item.date), item.counter]) || [],
      },
    ],
  })
}

watch([dataInRange], () => {
  updateCachedData()
}, { immediate: true })
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityPassagesHistory"
    :used-filters="['dateRange']"
    :variables="{
      dateRange: dateRangeCounter,
    }"
  >
    <template #title>
      {{ t('cyclability.cycling_passages_history.title') }}
    </template>

    <template #range-subtitle>
      {{ t('dates.daterange_formated_human', translateRange(dateRangeCounter)) }}{{ subtitle ? ` - ${subtitle}` : '' }}
    </template>

    <template #default="{ data }">
      <BarMetric :data="data" />
    </template>
  </MetricCard>
</template>
