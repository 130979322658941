<script setup lang="ts">
import type { RoadFeatureProperties, RoadMetric } from '@/composables/cyclability/cyclabilityRoads'
import { tv } from 'tailwind-variants'

interface SegmentProperties extends RoadFeatureProperties {
  id: string
  roadName: string
  path: string | null
  [key: string]: any
}

interface SegmentFeature extends GeoJSON.Feature<GeoJSON.LineString> {
  properties: SegmentProperties
}

interface Segment extends SegmentProperties {
  index: number
  chunkId: number
  indexInChunk: number
  groupIndex?: number
}

interface SegmentGroup {
  id: string
  index: number
  name?: string
  globalProps: SegmentProperties
  segments: Segment[]
}

defineOptions({
  name: 'CyclabilitySegmentsAnalysis',
})

const { t } = useI18n()

const { data, onData, loading } = useCyclabilityData({
  excludeData: ['network', 'areas'],
})

const scrollContainer = inject('sidebarContainerElement')
const MAX_SEGMENTS_PER_GROUP = 10

const navigationStore = useNavigationStore()
const { selectedArea } = useSelectedAreaFromUrl()
const cityStore = useCityStore()
const { slug } = storeToRefs(cityStore)
const { pathsMetric, availablePathsMetrics } = useCyclabilityRoads(slug)
const { translateRoads } = useCyclabilityTranslation(slug)
const { getMetricValue } = useCyclabilityLabels()
const { isSmall } = useResponsiveSidebarContainer()

const compareTo = ref<RoadMetric | null>(null)
const pathOptions = shallowRef<{ value: string, label: string }[]>([])
const pathSelected = ref<string | null>(null)
const groupedSegments = shallowRef<SegmentGroup[]>([])
const groupOpened = ref<string | null>(null)
const virtualListRef = ref()

const showCompareTo = computed(() => !!compareTo.value && compareTo.value !== pathsMetric.value)

const ui = tv({
  slots: {
    colsLabel: 'text-right cd-ellipsis',
  },
  variants: {
    direction: {
      column: {
        colsLabel: 'w-1/2 flex-1',
      },
      row: {
        colsLabel: 'min-w-48 max-w-48',
      },
    },
  },
  defaultVariants: {
    direction: 'row',
  },
})

const classes = computed(() => ui({
  direction: isSmall.value ? 'column' : 'row',
}))

// Function to handle height changes
function handleGroupToggle(itemIndex: string) {
  groupOpened.value = groupOpened.value === itemIndex ? null : itemIndex
  // Wait for DOM update
  nextTick(() => {
    // Force height updates
    if (virtualListRef.value) {
      virtualListRef.value.forceUpdateHeights()
    }
  })
}

function getGlobalMetricValue(item: SegmentProperties, metric: RoadMetric | null): number {
  if (!metric) {
    return 0
  }

  return (item[`${metric}Value`] as number) || 0
}

onData((data) => {
  if (!data?.roads?.geojson?.features) {
    return {}
  }

  // Group segments by roadName and handle small groups

  pathOptions.value = []

  const segmentGroups = (data.roads.geojson.features as SegmentFeature[]).reduce<Segment[][]>((groups, feature, index) => {
    if (!feature.properties?.name) {
      return groups
    }

    const segment = {
      ...feature.properties,
      index,
    } as Segment

    // Find existing group with same roadName
    const existingGroupIndex = groups.findIndex(group => group[0]?.roadName === segment.roadName)

    if (existingGroupIndex >= 0) {
      // Add to existing group
      segment.groupIndex = groups[existingGroupIndex].length
      groups[existingGroupIndex].push(segment)
    } else {
      // Create new group
      segment.groupIndex = 0
      groups.push([segment])

      pathOptions.value.push({
        value: feature.properties.roadName,
        label: feature.properties.roadName,
      })
    }

    return groups
  }, [])

  // Split groups into smaller chunks of 10 segments
  const mergedGroups = segmentGroups.reduce<Segment[][]>((acc, group) => {
    // If group has less than 10 segments, keep it as is
    if (group.length <= MAX_SEGMENTS_PER_GROUP) {
      acc.push(group)
      return acc
    }

    // Split into chunks of 10
    const chunks: Segment[][] = []
    for (let i = 0; i < group.length; i += MAX_SEGMENTS_PER_GROUP) {
      const chunk = group.slice(i, i + MAX_SEGMENTS_PER_GROUP)
      chunks.push(chunk)
    }

    // Handle last chunk if it has less than 3 segments
    if (chunks.length > 1 && chunks[chunks.length - 1].length < 3) {
      const lastChunk = chunks.pop()!
      chunks[chunks.length - 1].push(...lastChunk)
    }

    // Update labels for each segment in chunks
    chunks.forEach((chunk, chunkIndex) => {
      chunk.forEach((segment, segmentIndex) => {
        segment.index = segmentIndex
        segment.chunkId = chunkIndex
        segment.indexInChunk = chunkIndex * MAX_SEGMENTS_PER_GROUP + segmentIndex
      })
    })

    return [...acc, ...chunks]
  }, [])

  // Calculate averages for each group
  const groupedWithAverages = mergedGroups.map((segments, index) => {
    const group = {
      globalProps: {} as SegmentProperties,
      segments,
      id: `${index}-${segments[0].chunkId}`,
      index,
    } as SegmentGroup

    for (const segment of segments) {
      if (!group.globalProps) {
        group.globalProps = {
          id: `global-${index}`,
          roadName: segment.roadName,
        } as SegmentProperties
      }

      for (const [key, value] of Object.entries(segment)) {
        if (!['index', 'chunkId', 'indexInChunk'].includes(key)) {
          if (typeof value === 'number') {
            const currentValue = group.globalProps[key] ?? 0
            const segmentIndex = segments.indexOf(segment)

            group.globalProps[key] = ((currentValue * segmentIndex) + value) / (segmentIndex + 1)
          } else if (!(key in group.globalProps)) {
            // Keep first non-numeric value
            group.globalProps[key] = value
          }
        }
      }
    }

    // for each globalProps
    for (const key of Object.keys(group.globalProps)) {
      if (getRoadMetrics.cycleRap().includes(key as RoadMetric)) {
        group.globalProps[key] = Math.round(group.globalProps[key])
      }
    }

    return group
  })

  groupedSegments.value = Object.values(groupedWithAverages || {}).sort((a, b) => {
    const aValue = (getGlobalMetricValue(a.globalProps, pathsMetric.value) || getMetricValue(a.globalProps, pathsMetric.value)) ?? 0
    const bValue = (getGlobalMetricValue(b.globalProps, pathsMetric.value) || getMetricValue(b.globalProps, pathsMetric.value)) ?? 0
    return bValue - aValue
  })
})

function onSelectSegment(segment: string) {
  selectedArea.value = [segment]
}
</script>

<template>
  <div class="h-full">
    <Teleport
      v-if="navigationStore.filtersIsMounted"
      to="#teleport-sidebar"
    >
      <DSelectPicker
        v-model="pathSelected"
        icon="lines"
        :options="[
          { value: null, label: t('All paths') },
          ...pathOptions,
        ]"
        :label="t('Path selected')"
        :is-loading="loading"
      />

      <DSelectPicker
        v-model="pathsMetric"
        icon="target"
        :options="availablePathsMetrics.map(({ name, label }) => ({ value: name, label: label || name, disabled: compareTo && compareTo === name }))"
        :label="t('Main metric')"
        :is-loading="loading"
      />

      <DSelectPicker
        v-model="compareTo"
        icon="target"
        :options="[
          { value: null, label: t('None') },
          ...availablePathsMetrics.map(({ name, label }) => ({ value: name, label: label || name, disabled: pathsMetric === name })),
        ]"
        :label="t('Additional metric')"
        :is-loading="loading"
      />
    </Teleport>

    <BoardTitle>
      {{ t('cyclability.menu.risk_profile_by_segments') }}
    </BoardTitle>

    <BoardSubTitle>
      {{ t('Segments ranking') }}
      <template #subtitle>
        {{ t('Currently sorted by') }}: {{ data.roads?.metricInfo.name }}
      </template>
    </BoardSubTitle>

    <div
      v-if="!isSmall"
      class="flex justify-end text-sm mb-2 min-w-0"
    >
      <p
        v-if="showCompareTo"
        :class="classes.colsLabel()"
      >
        {{ translateRoads(compareTo || '') }}
      </p>
      <p :class="classes.colsLabel()">
        {{ data.roads?.metricInfo.name }}
      </p>
      <div class="w-14" />
    </div>

    <div v-if="loading">
      <p>Loading...</p>
    </div>

    <div
      v-else-if="data?.roads?.geojson"
      class="h-full"
    >
      <DVirtualList
        ref="virtualListRef"
        :items="groupedSegments.filter(item => pathSelected !== null ? item.globalProps.roadName === pathSelected : true)"
        :default-item-height="isSmall ? 136 : 72"
        :gap="8"
        :buffer="2"
        :scroll-container="scrollContainer as HTMLElement"
      >
        <template #default="{ item }">
          <DCard
            no-padding
            class="hover:cursor-pointer"
          >
            <div class="p-2 pl-4 bg-white flex">
              <CyclabilitySegmentRowCard
                class="flex-1"
                :item="item.globalProps"
                :title="`${t('Path:')} ${item.index}`"
                :name="item.globalProps.roadName"
                :paths-metric="pathsMetric"
                :compare-to="compareTo"
                :show-compare-to="!!showCompareTo"
                :class-breaks="data.roads?.classBreaks"
                @click="() => handleGroupToggle(item.id)"
              />

              <div
                class="flex-center hover:cursor-pointer hover:text-blue-500"
                @click="() => handleGroupToggle(item.id)"
              >
                <DIcon
                  :path="groupOpened === item.id ? 'chevron-up' : 'chevron-down'"
                  size="lg"
                />
              </div>
            </div>

            <div
              v-if="groupOpened === item.id"
              class="flex flex-col gap-2 bg-grey-100 p-4 shadow-inner"
            >
              <div
                v-for="subItem in item.segments"
                :key="subItem.id"
                class="flex flex-row space-x-4 items-center"
              >
                <div class="h-4 w-1 bg-black/10" />
                <CyclabilitySegmentRowCard
                  :item="subItem"
                  :name="subItem.roadName"
                  :sub-name="`${t('Segment:')} ${item.index} - ${subItem.indexInChunk}`"
                  :paths-metric="pathsMetric"
                  :compare-to="compareTo"
                  :show-compare-to="!!showCompareTo"
                  :class-breaks="data.roads?.classBreaks"
                  is-link
                  class="hover:shadow-md transition-shadow flex-1"
                  @click="() => onSelectSegment(subItem.name)"
                />
              </div>
            </div>
          </DCard>
        </template>
      </DVirtualList>
    </div>

    <CyclabilitySidebarSegment
      class="absolute! z-20 shadow-lg"
      :data="data"
      show-link-to-segment
    />
  </div>
</template>
