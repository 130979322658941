<script setup>
defineOptions({
  name: 'FilterByContainer',
})

const props = defineProps({
  name: {
    type: String,
    default: null,
  },
  highlight: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()

const { isHighlighted } = useFilterHighlight(
  props.highlight || camelize(props.name || 'Filter by'),
)
</script>

<template>
  <div
    class="bg-grey-50 hover:bg-blue-100 text-slate-500 rounded-md pt-1 pb-2 px-2 transition-colors duration-300"
    :class="{
      'bg-yellow-100!': isHighlighted,
    }"
  >
    <p class="text-grey-300 text-xs">
      <DIcon
        path="filter"
        size="xs"
      />{{ name || t('Filter by') }}
    </p>

    <slot />
  </div>
</template>
