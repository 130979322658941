export function usePlaceHoldersClasses() {
  const props = inject('parent-props')

  const classes = computed(() => ({
    'placeholders-animated relative overflow-hidden': props.animated,
    'rounded-sm': props.rounded,
    'mx-auto': props.centered,
  }))

  return {
    classes,
  }
}
