<script setup lang="ts">
import type { Layer } from '@/components/MapLibre/MapLibreSource/MapLibreSourceGeojson.vue'
import type { ClassBreaks } from '@/composables/classBreaks'
import type { RoadMetric } from '@/composables/cyclability/cyclabilityRoads'
import type { CyclabilityMapLayerProps, CyclabilityMetricInfo } from '@/types/cyclability.types'
import type { Map } from 'maplibre-gl'
import bbox from '@turf/bbox'

export interface CyclabilityMapSegmentsProps extends CyclabilityMapLayerProps {
  geojson: GeoJSON.FeatureCollection<GeoJSON.LineString>
  metric: RoadMetric
  selected?: string[]
  outline?: {
    classBreaks: ClassBreaks
    labels: string[]
    metric: string
    metricInfo: CyclabilityMetricInfo
  }
}

defineOptions({
  name: 'CitySegments',
})

const props = withDefaults(defineProps<CyclabilityMapSegmentsProps>(), {
  geojson: () => newFeatureCollection(),
  metric: 'score',
  selected: () => [],
  layerId: 'city-segments',
})

const emit = defineEmits(['ready'])

const { geojson, metric, classBreaks, outline } = toRefs(props)

const layers = ref<Layer[]>([])

function getLineWidthExpression(baseWidth: number) {
  return [
    'interpolate',
    ['linear'],
    ['zoom'],
    10,
    baseWidth + 4, // zoom level 10 or less: width 16
    14,
    baseWidth, // zoom level 14: width 12
    17,
    baseWidth - 4, // zoom level 17 or more: width 8
  ]
}

watch([metric, outline, classBreaks], ([metric, outline, classBreaks]) => {
  const newLayersDefinition = []
  const geometriesFilter = ['==', ['typeof', ['get', metric]], 'number']

  newLayersDefinition.push({
    id: `${props.layerId}-border`,
    type: 'line',
    paint: {
      'line-color': 'black',
      'line-opacity': 0.66,
      'line-width': getLineWidthExpression(16),
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
      'line-miter-limit': 2,
    },
    filter: geometriesFilter,
  } as Layer)

  if (outline) {
    newLayersDefinition[0].paint = {
      'line-color': getExpressionColorFromBreaks(
        outline.classBreaks,
        outline.metric,
        'case',
      ),
      'line-opacity': 1,
      'line-width': getLineWidthExpression(18),
    }
  }

  if (classBreaks) {
    const colorBreaks = getExpressionColorFromBreaks(classBreaks, metric, 'case')

    newLayersDefinition.push({
      id: props.layerId,
      beforeId: `${props.layerId}-border`,
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          PALETTE_COLORS[0],
          colorBreaks,
        ],
        'line-width': getLineWidthExpression(12),
      },
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-miter-limit': 2,
      },
      filter: geometriesFilter,
    })
  }

  layers.value = newLayersDefinition
}, { immediate: true })

const map = inject<Ref<Map | null>>('map', ref(null))

onMounted(() => {
  if (props.selected.length > 0) {
    // TODOBETTER: This is a hack to fit the map to the selected feature
    nextTick(() => {
      const source = map.value?.getSource(props.layerId) as maplibregl.GeoJSONSource
      const data = source?.serialize().data as GeoJSON.FeatureCollection<GeoJSON.LineString>

      // Find selected feature
      const selectedFeature = data.features.find(f => f.properties?.name === props.selected[0])

      if (selectedFeature && map.value) {
        // Get bounds of the feature
        const bounds = bbox(selectedFeature)

        // Fit map to feature bounds with padding
        map.value.fitBounds([
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]],
        ], {
          padding: 50,
          duration: 1000,
        })
      }
    })
  }
})

onBeforeUnmount(() => {
  layers.value = []
})
</script>

<template>
  <MapLibreSourceGeojson
    :id="layerId"
    :layer-props="layers"
    :data="geojson"
    :geojson-options="{
      promoteId: 'name',
    }"
    :selected-features="selected || []"
    @ready="() => emit('ready')"
  />
</template>
