<script setup>
const { t } = useI18n()
const router = useRouter()
const { getPref, setPref } = usePreferences()
const { error, loading, freemiumPlan } = useFreemiumPlan()
const { onFreemium } = useFreemiumAddSubscription(freemiumPlan)

const steps = [
  {
    step: 1,
    title: t('Welcome to City Dive!'),
  },
  {
    step: 2,
    title: t('What’s in your plan'),
  },
  {
    step: 3,
    title: t('What you can access'),
  },
  {
    step: 4,
    title: t('Upgrade anytime'),
  },
]

const step = ref(getPref('onboardingStep') || 1)
const stepData = computed(() => steps[get(step) - 1])

function nextStep() {
  const stepRef = get(step)

  if (stepRef < steps.length) {
    set(step, stepRef + 1)
    setPref('onboardingStep', get(step))
  }
}
</script>

<template>
  <DModal
    size="lg"
    :closable="false"
    primary
  >
    <template #title>
      {{ stepData.title }}
      <span class="text-xl font-normal text-white/50">
        {{ stepData.step }}/4
      </span>
    </template>

    <template #default="{ close }">
      <div class="flex min-h-[254px] flex-col">
        <div
          v-if="!loading && freemiumPlan"
          class="flex-center flex flex-1"
        >
          <FreemiumOnboardingStep1 v-if="step === 1" />
          <FreemiumOnboardingStep2
            v-if="step === 2"
            :freemium-plan="freemiumPlan"
          />
          <FreemiumOnboardingStep3 v-if="step === 3" />
          <FreemiumOnboardingStep4
            v-if="step === 4"
            :freemium-plan="freemiumPlan"
            @explore="onFreemium"
            @upgrade="router.push({ name: 'SubscribePlans' })"
          />
        </div>
        <div
          v-else-if="!error"
          class="flex-center flex flex-1"
        >
          <DLoader />
        </div>
        <div
          v-else
          class="flex-center flex flex-1"
        >
          <DAlert
            variant="alert"
            :title="t('Something went wrong. Please try again later.')"
            :subtitle="error.message"
          />
        </div>

        <div class="mt-4 flex justify-end text-center sm:text-right">
          <DLink
            v-if="step === 1"
            class="font-normal text-grey-500 underline"
            :to="{ name: 'SubscribePlans' }"
            @click="close"
          >
            {{ t('Explore premium plans') }}
          </DLink>
          <div
            v-if="step === 1"
            class="flex-1"
          />
          <DButton
            v-if="step < steps.length"
            class="bg-grey-500!"
            @click="nextStep"
          >
            {{ t('Next') }}
          </DButton>
        </div>
      </div>
    </template>
  </DModal>
</template>
