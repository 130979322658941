<script setup lang="ts">
defineOptions({
  name: 'CyclabilityOverviewSkopje',
})

const { t } = useI18n()
</script>

<template>
  <BoardSubTitle icon="warning">
    {{ t('We are actively working to provide you with the data for Skopje') }}
  </BoardSubTitle>

  <BoardGrid class="mt-4 flex-center h-[500px]">
    <AnimatedLoading />
  </BoardGrid>
</template>
