<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { tv } from 'tailwind-variants'

defineOptions({
  name: 'DCard',
})

const props = defineProps<{
  noPadding?: boolean
  inline?: boolean
  noElevation?: boolean
  class?: HTMLAttributes['class']
  classContainer?: HTMLAttributes['class']
}>()

const emit = defineEmits(['click'])

const ui = tv({
  slots: {
    root: 'w-full bg-white md:w-auto rounded-md overflow-auto flex flex-col',
    container: 'overflow-hidden',
  },
  variants: {
    noPadding: {
      true: {
        container: 'p-0',
      },
      false: {
        container: 'p-8',
      },
    },
    inline: {
      true: {
        container: 'flex-row',
      },
      false: {
        container: 'flex-col',
      },
    },
    noElevation: {
      false: {
        root: 'shadow-3xl',
      },
    },
  },
  defaultVariants: {
    noPadding: false,
    inline: false,
    noElevation: false,
  },
})

const classes = computed(() => ui({
  noPadding: props.noPadding,
  inline: props.inline,
  noElevation: props.noElevation,
}))
</script>

<template>
  <div
    :class="[props.class, classes.root()]"
    v-bind="$attrs"
    @click="emit('click')"
  >
    <div
      v-if="$slots.title || $slots.right || $slots.actions"
      class="flex h-16 items-center border-b px-4 md:px-6"
    >
      <div class="mr-4 flex-1 text-base font-bold tracking-tight text-grey-500">
        <slot name="title" />
      </div>

      <div class="flex items-center space-x-2 divide-x divide-grey-100">
        <slot name="right" />
      </div>

      <div class="flex items-center space-x-2">
        <slot name="actions" />
      </div>
    </div>

    <div :class="[classes.container(), props.classContainer]">
      <slot />
    </div>
  </div>
</template>
