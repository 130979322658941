<script setup lang="ts">
import type { DateRange } from '@/stores/filters'
import { endOfDay, startOfDay, subDays } from 'date-fns'

interface Props {
  modelValue: DateRange | null | undefined
  row?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  row: false,
})

const emit = defineEmits<{
  'update:modelValue': [value: DateRange | null | undefined]
}>()

const { t } = useI18n()
const dates = useVModel(props, 'modelValue', emit)

const startFormated = computed({
  get: () => formatDate(dates.value?.start || new Date(), DATE_FORMAT),
  set: (value: string) => onChangeDate(value, 'start'),
})

const endFormated = computed({
  get: () => formatDate(dates.value?.end || new Date(), DATE_FORMAT),
  set: (value: string) => onChangeDate(value, 'end'),
})

const min = computed(() => {
  return {
    start: MIN_DATE,
    end: formatDate(dates.value?.start || new Date(), DATE_FORMAT),
  }
})

const max = computed(() => {
  return {
    start: formatDate(dates.value?.end || new Date(), DATE_FORMAT),
    end: formatDate(subDays(now, 1), DATE_FORMAT),
  }
})

function onChangeDate(value: string, type: 'start' | 'end'): void {
  const parsed = new Date(value)

  if (Number.isNaN(parsed.getTime())) {
    return
  }

  if (dates.value) {
    dates.value = {
      ...dates.value,
      [type]: type === 'start' ? startOfDay(parsed) : endOfDay(parsed),
    }
  } else {
    dates.value = {
      start: type === 'start' ? startOfDay(parsed) : new Date(),
      end: type === 'end' ? endOfDay(parsed) : new Date(),
    }
  }
}

const args = computed(() => ({
  row: props.row,
  inline: !props.row,
}))
</script>

<template>
  <div :class="{ 'space-y-2': row, 'space-x-2': row! }">
    <DField v-bind="args">
      <template #label>
        <span :class="{ 'flex w-8': row }">{{ t('From') }}</span>
      </template>
      <DInputText
        v-model="startFormated"
        :size="row ? 'xs' : 'sm'"
        inline
        type="date"
        :min="min.start"
        :max="max.start"
      >
        <template
          v-if="!row"
          #left
        >
          <DIcon
            path="edit"
            size="sm"
          />
        </template>
      </DInputText>
    </DField>

    <DField v-bind="args">
      <template #label>
        <span :class="{ 'flex w-8': row }">{{ t('To') }}</span>
      </template>
      <DInputText
        v-model="endFormated"
        :size="row ? 'xs' : 'sm'"
        inline
        type="date"
        :min="min.end"
        :max="max.end"
      >
        <template
          v-if="!row"
          #left
        >
          <DIcon
            path="edit"
            size="sm"
          />
        </template>
      </DInputText>
    </DField>
  </div>
</template>
