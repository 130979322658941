import type { ClassBreaks } from '@/composables/classBreaks'

export interface CyclabilityMetricInfo {
  name: string
  description: string
  unit?: string
}

export interface CyclabilityMapLayerProps {
  layerId?: string
  metric: string
  metricInfo: CyclabilityMetricInfo
  classBreaks: ClassBreaks
  labels?: string[]
  isLoading?: boolean
}

export enum CyclabilityNetworkRoadTypes {
  Independant_cycle_and_pedestrian_track = 1,
  Physically_separated_cycle_track = 2,
  Dedicated_track = 3,
  Dedicated_lanes = 4,
  Pedestrian_track_with_cycling_allowed = 5,
  Pedestrian_street = 6,
  Living_street = 7,
  Limited_access_roads = 8,
  Shared_bus_lanes = 9,
  Medium_speed_roads = 10,
  Medium_and_high_speed_road = 12,
  High_speed_roads = 11,
  // In_construction = 13,
  // Others = 14,
}

export type CyclabilityNetworkRoadType = keyof typeof CyclabilityNetworkRoadTypes
