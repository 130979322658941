<script setup lang="ts">
import createExportTokenMutation from '@/graphql/mutations/createExportToken.gql'
import deleteExportTokenMutation from '@/graphql/mutations/deleteExportToken.gql'

const props = defineProps({
  hasExport: Boolean,
  organizationId: {
    type: Number,
    default: null,
  },
  token: {
    type: Object,
    default: () => {},
  },
})

const emit = defineEmits(['createdToken'])

const updateError = ref<string | null>(null)

const { t } = useI18n()
const loading = useMutationLoading()

const { copy, copied, isSupported } = useClipboard({
  source: computed((): string => props.token.value || ''),
})

const {
  mutate: createToken,
  onDone: onTokenCreated,
  onError,
} = useMutation(createExportTokenMutation, {
  variables: {
    organizationId: props.organizationId,
  },
})

const { mutate: regenerateToken, onDone: onTokenRegenerated } = useMutation(
  deleteExportTokenMutation,
)

onTokenCreated(() => emit('createdToken'))
onTokenRegenerated(() => createToken())
onError((err) => {
  updateError.value = err.message
})

function confirmRegenerate() {
  // eslint-disable-next-line no-alert
  return confirm(
    t(
      'Do you really want to refresh the token? The current token will expire.',
    ),
  )
}
</script>

<template>
  <div class="token">
    <template v-if="hasExport">
      <div
        v-if="loading"
        class="text-center"
      >
        <DLoader /> {{ t('Loading...') }}
      </div>

      <template v-else>
        <div class="mb-4">
          {{ t('a_token_is_needed_to_use_export_api') }}
        </div>

        <DAlert
          v-if="updateError"
          closable
          class="mt-2 mb-2"
          variant="alert"
        >
          {{ updateError }}
        </DAlert>

        <div
          v-if="token"
          class="items-start justify-center md:flex md:space-x-4"
        >
          <div class="md:w-96">
            <div
              v-if="token.value"
              class="bg-grey-100 relative py-2 px-4 shadow-sm border-1 border-blue-500 flex items-center"
            >
              <Transition name="fade">
                <span
                  v-show="copied"
                  class="absolute z-hop bg-green-500 text-white text-xs; font-bold rounded-xs m-2 py-2 px-4 top-0 left-0"
                >
                  {{ t('Copied!') }}
                </span>
              </Transition>

              <code class="flex-1 text-grey-500 overflow-auto">
                {{ token.value }}
              </code>

              <DIcon
                v-if="isSupported"
                name="copy"
                class="text-blue-500 hover:cursor-pointer"
                :title="copied ? t('Copied!') : t('Copy')"
                @click="copy()"
              />
            </div>
            <div class="small text-grey-300">
              {{ t('created_date', { date: ago(token.createdAt) }) }}
            </div>
          </div>

          <DButton
            class="mt-2"
            variant="gradient-primary"
            @click="confirmRegenerate() && regenerateToken({ id: token.id })"
          >
            {{ t('Refresh') }}
          </DButton>
        </div>

        <div
          v-else
          class="text-center"
        >
          <DButton
            class="mt-2"
            variant="gradient-primary"
            @click="createToken"
          >
            {{ t('Generate the token API') }}
          </DButton>
        </div>
      </template>
    </template>

    <template v-else>
      <div class="mb-4">
        <DAlert variant="warning">
          {{ t('subscription_data_export_is_required') }}
        </DAlert>
      </div>

      <div class="text-center">
        <DButton
          class="mb-3"
          variant="warning"
          :to="{ name: 'SettingsSubscription' }"
        >
          {{ t('Manage my plan') }}
        </DButton>
      </div>
    </template>
  </div>
</template>
