<script setup lang="ts">
import { REGIONS } from '@/utils/constants'

interface WorldmapMetricProps {
  data: {
    countryCode: string
    cities: number
    geojson: GeoJSON.Polygon
  }[]
  options: {
    color?: string
  }
  region: 'EU' | 'NA' | 'APAC'
}

const props = withDefaults(defineProps<WorldmapMetricProps>(), {
  data: () => [],
  options: () => ({}),
  region: RegionsCodes.EU,
})

const { t } = useI18n()
const { translateCountry } = useLabelTranslation()

const regionData = computed(() => {
  const { region } = props
  return REGIONS.find(r => r.code === region)
})

const geojson = computed(() => {
  return newFeatureCollection(
    props.data?.map((country) => {
      return {
        type: 'Feature',
        properties: {
          ...country,
        },
        geometry: {
          ...country.geojson,
        },
      }
    }) || [],
  )
})
</script>

<template>
  <MapLibre
    :zoom="2"
    :center="regionData?.center"
    :min-zoom="2"
    :max-zoom="2"
    :scale-control="false"
  >
    <MapLibrePopup
      :offset="[0, -5]"
      layer-ids="countries"
    >
      <template #element="{ feature }">
        <span class="text-bold">{{
          translateCountry(feature?.properties?.countryCode)
        }}</span>
        {{
          t('with {count} cities', {
            count: feature?.properties?.cities,
          }, feature?.properties?.cities)
        }}
      </template>

      <MapLibreSourceGeojson
        id="countries"
        :layer-props="{
          type: 'fill',
          paint: {
            'fill-color': options.color || '#20757C',
            'fill-outline-color': '#fff',
          },
        }"
        :data="geojson"
        :geojson-options="{
          promoteId: 'countryCode',
        }"
      />
    </MapLibrePopup>
  </MapLibre>
</template>
