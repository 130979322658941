<script setup>
import homeCitiesQuery from '@/graphql/queries/homeCities.gql'

const { t } = useI18n()
const router = useRouter()
const { isLogged } = storeToRefs(useUserStore())

const { relativeRanges } = useDateRangeFilter()

const { result, loading } = useQuery(homeCitiesQuery, {
  dateRange: relativeRanges.last7days.period,
})

const cities = computed(() => get(result)?.homeCities.data || [])

function selectCity(city) {
  if (city) {
    router.push({
      name: 'HomeCity',
      params: { citySlug: city },
    })
  } else {
    router.push({
      name: 'Home',
    })
  }
}
</script>

<template>
  <div class="relative flex overflow-hidden">
    <div class="flex-1">
      <HomepageMap
        :cities="cities"
        :loading="loading"
        @select-city="selectCity"
      >
        <MapLibreControl
          position="top-left"
          no-style
          class="ml-4! mt-6!"
        >
          <NavigationSearchInput class="md:w-72 z-menu" />
        </MapLibreControl>
      </HomepageMap>
    </div>

    <DPanel no-padding>
      <HomepageTopBar />

      <div class="min-h-[200px] gradient relative flex flex-center mb-6">
        <img
          src="/img/logo.svg"
          class="w-1/2"
          alt="City Dive"
        >
        <div class="absolute -bottom-6 m-x-auto text-white flex gap-2">
          <DIcon
            path="bike"
            size="lg"
            class="bg-bike border-4 p-1.5! rounded-full border-grey-50"
          />
          <DIcon
            path="scooter"
            size="lg"
            class="bg-scooter border-4 p-1.5! rounded-full border-grey-50"
          />
          <DIcon
            path="moped"
            size="lg"
            class="bg-moped border-4 p-1.5! rounded-full border-grey-50"
          />
          <DIcon
            path="car"
            size="lg"
            class="bg-car border-4 p-1.5! rounded-full border-grey-50"
          />
        </div>
      </div>

      <div class="px-10 py-6">
        <h1
          class="gradient-text text-center font-title text-4xl font-bold text-white shadow-black drop-shadow-lg"
        >
          {{ t('Analyze shared mobility across the world') }}
        </h1>

        <HomepageKeyNumbers class="text-blue-500" />

        <img
          src="/img/homepage_screen_capture@2x.webp"
          class="w-full p-4"
          :alt="t('alt_text_homepage_screen_capture')"
        >

        <p class="mx-auto text-center">
          <DButton
            v-if="!isLogged"
            variant="fill-primary"
            :to="{ name: 'Register' }"
            size="lg"
          >
            {{ t('Get started for free') }}
          </DButton>
          <DButton
            v-else
            variant="fill-primary"
            :to="{ name: 'Overview' }"
            size="lg"
          >
            {{ t('Dive in') }}
          </DButton>
        </p>
      </div>
    </DPanel>

    <RouterView
      v-slot="{ Component }"
      :cities="cities"
      :loading="loading"
    >
      <Transition name="slideRight">
        <component :is="Component" />
      </Transition>
    </RouterView>

    <FreemiumOnboarding />
  </div>
</template>
