<script setup lang="ts">
import type { ClassBreaks } from '@/composables/classBreaks'

defineOptions({
  name: 'LabeledValue',
})

const props = defineProps<{
  label?: string
  subLabel?: string
  value?: number | string
  breakValue?: number
  classBreaks?: ClassBreaks
  title?: string
  indice?: string | number
  indiceTitle?: string
  inline?: boolean
  column?: boolean
  inverse?: boolean
  upperBoundIncluded?: boolean
}>()

function formatIfNumber(value: number | string | undefined) {
  return typeof value === 'number' ? formatNumber(value) : value
}

function getValueOrDefault(value: number | string | undefined) {
  return typeof value === 'number' ? value : '-'
}

const primaryValue = computed(() => {
  return formatIfNumber(props.inverse ? props.indice : getValueOrDefault(props.value))
})

const secondaryValue = computed(() => {
  return formatIfNumber(props.inverse ? getValueOrDefault(props.value) : props.indice)
})

const color = computed(() => {
  const value = props.breakValue || (typeof props.value === 'number' ? props.value : undefined)
  if (props.classBreaks && value !== undefined) {
    return getColorFromClassBreak(value, props.classBreaks, undefined, props.upperBoundIncluded)
  }

  return undefined
})

const primaryClassTw = computed(() => {
  return {
    'text-right font-bold text-lg': props.inline,
    'text-base font-normal': props.column,
    'text-3xl inline-block font-black': !props.inline && !props.column,
  }
})

const secondaryClassTw = computed(() => {
  return {
    'text-right': props.inline,
    'text-ms': props.inline || props.column,
    'text-xl text-nowrap inline-block ml-2': !props.inline && !props.column,
  }
})
</script>

<template>
  <div
    :class="{
      'flex flex-row justify-between': inline,
      'flex flex-col': column,
    }"
  >
    <p :class="subLabel ? 'font-bold text-grey-400 text-xs' : 'font-bold'">
      {{ label || subLabel }}
    </p>
    <p
      v-if="subLabel && label"
      class="font-bold"
    >
      {{ subLabel }}
    </p>

    <div
      :style="{ color }"
      :class="{
        'flex flex-col': inline || column,
      }"
    >
      <DTooltip
        v-if="inverse ? indiceTitle : title"
        :title="inverse ? indiceTitle : title"
        class="font-black"
        :class="primaryClassTw"
      >
        {{ primaryValue }}
      </DTooltip>
      <p
        v-else
        :class="primaryClassTw"
      >
        {{ primaryValue }}
      </p>

      <p
        v-if="secondaryValue"
        class="font-black"
        :class="secondaryClassTw"
      >
        {{ inline || column ? '' : '(' }}<DTooltip
          is="span"
          v-if="inverse ? title : indiceTitle"
          :title="inverse ? title : indiceTitle"
        >
          {{ secondaryValue }}
        </DTooltip><template v-else>
          {{ secondaryValue }}
        </template>{{ inline || column ? '' : ')' }}
      </p>
    </div>
  </div>
</template>
