import { resetCache } from '@/graphql'
import meQuery from '@/graphql/queries/me.gql'
import settingsQuery from '@/graphql/queries/settings.gql'
import { useGlobalQueryLoading } from '@vue/apollo-composable'

import { StorageSerializers, useStorage } from '@vueuse/core'
import { jwtDecode } from 'jwt-decode'
import { defineStore } from 'pinia'

interface BoardPreferences {
  global: Record<string, any>
  providers: Record<string, any>
  cities: Record<string, any>
}

interface OrgaPreferences {
  onboardingStep: number
  alerts: string[]
  filters: Record<string, any>
  boards: BoardPreferences
}

interface UserSettings {
  favorites: {
    cities: string[]
    providers: string[]
  }
}

interface OrganisationUser extends User {
  userId?: string
  role: string
  name?: string
  email: string
  createdAt: Date
}

interface SubscriptionPlan {
  id: number
  name: string
  quantity: number
  support: string
  isOption: boolean
  isPack: boolean
  requirePack: boolean
  seats: number
  data: null | object
  metadata: null | object
}

interface Subscription {
  end?: Date | null
  plans: SubscriptionPlan[]
  citydive: {
    regions?: RegionsCodes[]
    cities: object[]
    dataExport?: boolean
    support?: string
    seats?: number
    freemium?: boolean
    appMetrics?: boolean
    screen?: string[]
  }
}

interface Organization {
  id: number
  name: string
  company?: boolean
  address1?: string
  address2?: null
  zipCode?: string
  city?: string
  country?: string
  state?: string
  vatNumber?: string
  vatRate?: number
  disabled?: boolean
  disabledAt?: number | null
  disabledReason?: string | null
  paymentInformation?: object | null
  subscriptions?: Subscription[]
  drafts?: object[]
  users: OrganisationUser[]
}

interface User {
  id?: string
  email: string
  lang?: string
  token?: string | null
  roles?: string[]
  organizationId?: number
  settings?: UserSettings
  organizations?: Organization[]
  emailVerified?: boolean
}

type UserPreferences = Record<number, OrgaPreferences>

export const useUserStore = defineStore('user', () => {
  const globalLoading = useGlobalQueryLoading()

  const fetchUserResult = createEventHook()
  const fetchUserError = createEventHook()
  const getOrganizationId = createEventHook()
  const userLogout = createEventHook()

  const router = useRouter()
  const loginModalsStore = useLoginModalsStore()
  const { updateLocale } = useLocaleSetting()

  const {
    load,
    refetch,
    onResult: onUserResult,
  } = useLazyQuery(meQuery, undefined, {
    clientId: CAPTAIN,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    returnPartialData: false,
  })

  const {
    load: loadSettings,
    refetch: refetchSettings,
    onResult: onSettingsResult,
  } = useLazyQuery(settingsQuery, undefined, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    returnPartialData: false,
  })

  const loading = ref(false)
  const errors = ref(null)

  const user = ref<User | null>(null)
  const settings = ref<UserSettings>({
    favorites: {
      cities: [],
      providers: [],
    },
  })

  const token = useStorage('token', null, localStorage, {
    serializer: StorageSerializers.string,
  })
  const organizationId = useStorage<number>('organizationId', null, localStorage, {
    serializer: StorageSerializers.number,
  })
  const preferences = useStorage(
    'prefs',
    {},
    localStorage,
    {
      mergeDefaults: (storageValue, defaults) =>
        deepMerge(defaults, storageValue),
    },
  )

  const isNeedToLoadUser = computedEager(() =>
    Boolean(get(token) && !get(user) && !get(errors)),
  )
  const isLogged = computedEager(() =>
    Boolean(get(token) && get(user) && !get(loading)),
  )
  const isAdmin = computedEager(() => {
    return get(user)?.roles?.includes('admin') || false
  })

  // Organizations
  const organizations = computed(() => get(user)?.organizations || [])
  const organization = computed(
    () =>
      get(organizations).find(
        organization => organization.id === get(organizationId),
      ) || null,
  )
  const role = computed(
    () =>
      get(organization)?.users?.find(u => u.userId === get(user)?.id)?.role
      || null,
  )
  const draft = computed(() => get(organization)?.drafts?.[0])

  // Subscription
  const subscription = computed(
    () => (get(organization)?.subscriptions || [])[0] || null,
  )
  const isNewUser = computed(() =>
    Boolean(get(user)?.emailVerified && !get(subscription) && !get(draft)),
  )
  const isFreemium = computed(
    () => subscription.value?.citydive?.freemium || false,
  )
  const unlockedRegions = computed(
    () => subscription.value?.citydive?.regions || [],
  )
  const unlockedCities = computed(
    () => subscription.value?.citydive?.cities || [],
  )
  const hasDataAccess = computed(() => {
    if (!get(subscription)) {
      return false
    }

    const roleRef = get(role)
    if (roleRef && !['owner', 'member'].includes(roleRef)) {
      return false
    }

    return true
  })
  const hasExport = computed(() => {
    const citydive = get(subscription)?.citydive
    return citydive?.dataExport || false
  })

  const screenCities = computed(() => {
    const citydive = get(subscription)?.citydive
    return citydive?.screen || []
  })

  // Preferences
  const orgaPreferences = computed(() => {
    const prefsRef = get<UserPreferences>(preferences)
    const orgIdRef = get(organizationId)

    if (!prefsRef[orgIdRef]) {
      prefsRef[orgIdRef] = DEFAULT_ORGA_PREFERENCES
      set(preferences, prefsRef)
    }

    return prefsRef[orgIdRef]
  })

  function $reset() {
    set(user, null)
    set(settings, {
      favorites: {
        cities: [],
        providers: [],
      },
    })
    set(errors, null)
    set(loading, false)
    set(organizationId, null)
    set(token, null)
  }

  // Actions
  async function logout() {
    if (get(globalLoading)) {
      await until(globalLoading).toBe(false, { timeout: WAIT_TIMEOUT })
    }

    $reset()
    await resetCache()

    await nextTick()

    userLogout.trigger()
    return router.push({ name: 'Home' })
  }

  async function login(newToken?: string) {
    if (newToken && newToken !== get(token)) {
      $reset()
      set(token, newToken)
      await nextTick()
      await loadUser()
    }
  }

  async function loadUser() {
    if (!get(token)) {
      if (LOGIN_DISABLED) {
        set(token, 'fake-token')
      } else {
        return
      }
    }

    if (LOGIN_DISABLED) {
      // login disabled, we use a fake user

      set(user, GEORGE_LOUTRE)
      set(organizationId, GEORGE_LOUTRE.organizations[0].id)
      set(settings, {
        favorites: {
          cities: ['paris'],
          providers: ['tier', 'voi'],
        },
      })

      return
    }

    set(loading, true)
    try {
      await loadOrRefetchUser()
      await loadOrRefetchSettings()
    } catch (error) {
      set(loading, false)
      await logout()
      console.error(error)
      fetchUserError.trigger(error)
    }
  }

  async function loadOrRefetchUser() {
    return load() || refetch()
  }

  onUserResult(async ({ partial, data, errors: newErrors }) => {
    if (partial) {
      return
    }

    const newUser = data?.me || null

    if (newUser) {
      // user exists

      if (newUser.organizations?.length) {
        // user have organizations

        const tokenRef = get(token)
        let orgId: number | null = null

        // retrieve organizationId from token
        if (tokenRef) {
          // the user is already connected with an organization
          const payload: { organizationId: number } = jwtDecode(tokenRef)

          if (payload?.organizationId) {
            orgId = Number(payload.organizationId)
          }
        } else {
          // the user is not connected
          console.error('NO_TOKEN')
        }

        // we already have an organizationId and we check if the organizationId is still valid
        // if no we set the first organization of the list
        if (orgId && !newUser.organizations.find((o: Organization) => o.id === orgId)) {
          orgId = null
        }

        if (!orgId) {
          const orgsWithPlan = newUser.organizations.filter(
            (o: Organization) => o.subscriptions && o.subscriptions.length,
          )

          if (orgsWithPlan.length === 0) {
            console.warn('NO_SUBSCRIPTION')
            router.push({ name: 'SettingsSubscription' })
          } else if (orgsWithPlan.length > 1) {
            console.warn('MULTIPLES_ORGAS_WITH_CD')
            loginModalsStore.openChangeOrganization()
          } else {
            await fetchOrganizationToken(orgsWithPlan[0].id)
          }
        } else {
          // we have an organization id already
          set(organizationId, orgId)
          await nextTick()

          getOrganizationId.trigger(orgId)
        }
      } else {
        console.warn('NO_ORGANIZATION')
      }

      // we can set the user
      set(user, newUser)

      // update the locale from the user
      updateLocale(newUser.lang === 'en' ? 'en-GB' : newUser.lang)

      // trigger the event
      fetchUserResult.trigger(newUser)
    } else {
      // the user does not exist
      console.warn('NO_USER')
      await logout()
      loginModalsStore.openSignin()
      fetchUserResult.trigger(null)
    }

    if (newErrors) {
      newErrors.forEach(e => console.error('user:', e.message))
      set(errors, newErrors)
    }

    set(loading, false)
  })

  async function loadOrRefetchSettings() {
    const userRef = get(user)

    if (userRef && userRef.emailVerified) {
      return loadSettings() || refetchSettings()
    } else {
      console.warn(
        'user not logged in or email not verified, skipping settings',
      )
    }
  }

  onSettingsResult(async ({ partial, data, errors }) => {
    if (partial) {
      return
    }

    const { favorites } = data?.settings || {
      favorites: {},
    }

    set(settings, {
      favorites: {
        ...favorites,
        cities: [...(favorites.cities || [])],
        providers: [...(favorites.providers || [])],
      },
    })

    if (errors) {
      errors.forEach(e => console.error('settings:', e.message))
    }
  })

  async function fetchOrganizationToken(newOrganizationId: number) {
    try {
      const resp = await axios.get(
        `${import.meta.env.VITE_CAPTAIN_API}/v1/auth/organizationJwt`,
        {
          params: { organizationId: newOrganizationId },
          headers: { Authorization: `Bearer ${get(token)}` },
        },
      )

      if (resp.data?.token) {
        set(token, resp.data?.token)
        set(organizationId, newOrganizationId)
        await nextTick()
        getOrganizationId.trigger(newOrganizationId)
      } else {
        // the user does not have access to this organization
        set(organizationId, null)
        await nextTick()
        getOrganizationId.trigger(null)
        loginModalsStore.openChangeOrganization()
      }
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  async function switchOrganization(newOrganizationId: number) {
    const orgIdRef = get(organizationId)

    if (orgIdRef !== newOrganizationId) {
      await fetchOrganizationToken(newOrganizationId)
    }
  }

  function goToUserMain() {
    const userRef = get(user)

    // TOFIX: bog for user with only one orga
    if (userRef && get(organization)) {
      if (!userRef.emailVerified) {
        router.push({ name: 'LoginRequireConfirmedEmail' })
      } else if (loginModalsStore.redirect !== null) {
        router.push(loginModalsStore.redirect)
        loginModalsStore.redirect = null
      } else if (get(subscription)) {
        router.push({ name: 'Overview' })
      } else {
        router.push({ name: 'Home' })
      }
    }
  }

  // waiting for user to be loaded
  async function waitingUser() {
    if (get(loading)) {
      await until(loading).toBe(false, { timeout: WAIT_TIMEOUT })
      await nextTick()
    }

    if (get(isNeedToLoadUser)) {
      await until(isNeedToLoadUser).toBe(false, { timeout: WAIT_TIMEOUT })
      await nextTick()
    }
  }

  if (get(token)) {
    loadUser()
  }

  return {
    loading,
    user,
    token,
    organizationId,
    organizations,
    organization,
    draft,
    role,
    subscription,
    settings,
    preferences,
    orgaPreferences,
    isNeedToLoadUser,
    isLogged,
    isAdmin,
    isNewUser,
    isFreemium,
    unlockedRegions,
    unlockedCities,
    hasDataAccess,
    hasExport,
    screenCities,
    $reset,
    login,
    logout,
    switchOrganization,
    loadUser,
    waitingUser,
    goToUserMain,
    onUserLoaded: fetchUserResult.on,
    onUserError: fetchUserError.on,
    onOrganizationLoaded: getOrganizationId.on,
    onUserLogout: userLogout.on,
  }
})

// make sure to pass the right store definition, `useUserStore` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
