<script setup lang="ts">
defineOptions({
  name: 'CityCyclabilityCyclistAnalysisMetric',
})

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())

const { writeCache } = useWriteCacheForMetric('cityCyclabilityCyclistAnalysis')

onMounted(() => {
  if (!city.value) {
    return
  }

  writeCache({
    city: city.value?.name,
  }, {
    gender: [
      {
        percent: 19,
        label: 'Woman',
        color: '#adbff3',
      },
      {
        percent: 81,
        label: 'Man',
        color: '#32498a',
      },
    ],
    age: [
      {
        percent: 15,
        label: '<25',
        color: '#adbff3',
      },
      {
        percent: 53,
        label: '25-40',
        color: '#98aff0',
      },
      {
        percent: 31,
        label: '41-65',
        color: '#7695eb',
      },
      {
        percent: 1,
        label: '>65',
        color: '#547ae6',
      },
    ],
    occupation: [
      {
        percent: 79,
        label: 'Employed',
        color: '#7ae8ee', // rgb(122 233 238)
      },
      {
        percent: 14,
        label: 'Student',
        color: '#4fe1e8', // rgb(79 225 232)
      },
      {
        percent: 3,
        label: 'Other',
        color: '#1caeb5', // rgb(28 174 181)
      },
      {
        percent: 1,
        label: 'Retired',
        color: '#148388', // rgb(20 131 136)
      },
      {
        percent: 3,
        label: 'Unemployed',
        color: '#0c575a', // rgb(12 87 90)
      },
    ],
  })
})
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityCyclistAnalysis"
    :used-filters="[]"
  >
    <template #title>
      {{ t('cyclability.cyclist_analysis.title') }}
    </template>

    <template #subtitle>
      {{ t('2022') }}
    </template>

    <template #default="{ data }">
      <div class="flex gap-4">
        <PercentBarMetric
          class="flex-1"
          :data="data.gender"
          :label="t('cyclability.cyclist_analysis.gender')"
        />

        <PercentBarMetric
          class="flex-1"
          :data="data.age"
          :label="t('cyclability.cyclist_analysis.age')"
        />
      </div>
      <div class="flex gap-4 mt-4">
        <PercentBarMetric
          class="flex-1"
          :data="data.occupation"
          :label="t('cyclability.cyclist_analysis.occupation')"
        />
      </div>
    </template>
  </MetricCard>
</template>
