<script setup>
const props = defineProps({
  icon: { type: String, default: null },
  text: { type: String, default: null },
  to: { type: [String, Object], default: null },
  action: { type: Function, default: null },
  authNeeded: Boolean,
  notification: { type: String, default: null },
})

const loginModalsStore = useLoginModalsStore()
const { goToUserMain } = useUserStore()
const { isLogged, subscription, draft, isNewUser } = storeToRefs(useUserStore())

// In case if is not a new user but the user is not subscribed
const redirectToPlans = computed(
  () => props.authNeeded && !get(subscription) && !get(draft),
)
const to = computed(
  () => (get(redirectToPlans) && { name: 'SubscribePlans' }) || props.to,
)
</script>

<template>
  <span
    class="relative"
    role="menuitem"
  >
    <span
      v-if="(authNeeded && (!isLogged || isNewUser)) || action"
      :title="text"
      class="group flex cursor-pointer items-center opacity-60 hover:opacity-100 md:block"
      @click="
        () => {
          if (action) {
            action()
          }
          else {
            if (isNewUser) {
              goToUserMain()
            }
            else {
              loginModalsStore.openSignin()
            }
          }
        }
      "
    >
      <DIcon
        :src="icon"
        size="lg"
        class="rounded-xl group-hover:bg-black/20 md:group-hover:bg-black/30"
      />
      <span class="ml-2 text-lg uppercase text-white md:hidden">{{
        text
      }}</span>
    </span>

    <DLink
      v-else
      :to="to"
      :title="text"
      class="group flex cursor-pointer items-center opacity-60 hover:opacity-100 md:block"
    >
      <DIcon
        :src="icon"
        size="lg"
        class="rounded-xl group-hover:bg-black/20 md:group-hover:bg-black/30"
      />
      <span class="ml-2 text-lg uppercase text-white md:hidden">{{
        text
      }}</span>
    </DLink>
  </span>
</template>

<style scoped>
@reference '@/assets/style/index.css';

.active {
  @apply opacity-100;

  .d-icon {
    @apply bg-black/20 md:bg-black/30;
  }
}
</style>
