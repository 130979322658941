export function useMobile() {
  const breakpoints = useBreakpoints(breakpointsTailwind)

  return {
    isMobile: breakpoints.smaller('md'),
  }
}

export function useOpenAndHoverOnMobile(element: Ref<HTMLElement>, options = {
  openOnHover: true,
}) {
  const { isMobile } = useMobile()
  const isOpenTmp = ref(false)

  const isHovering = options.openOnHover ? useElementHover(element) : undefined

  onClickOutside(element, () => {
    if (isMobile.value) {
      isOpenTmp.value = false
    }
  })

  const isOpen = computed({
    get: () => isMobile.value ? isOpenTmp.value : isHovering?.value || true,
    set: (value: boolean) => {
      if (isMobile.value) {
        isOpenTmp.value = value
      }
    },
  })

  return {
    isOpen,
  }
}

export function useResponsiveSidebarContainer() {
  const breakpoints = useBreakpoints(breakpointsTailwind)

  const isSmall = computed(() => breakpoints.smaller('sm').value || (breakpoints.greaterOrEqual('md').value && breakpoints.smaller('lg').value))
  const isMedium = computed(() => breakpoints.between('sm', 'md').value || breakpoints.greaterOrEqual('lg').value)

  function twClass(classNameSmall: string, classNameMedium: string, toString?: boolean) {
    return toString
      ? `${classNameSmall} sm:${classNameMedium} md:${classNameSmall} lg:${classNameMedium}`
      : {
          [classNameSmall]: isSmall.value,
          [classNameMedium]: !isSmall.value,
        }
  }

  return {
    twClass,
    isSmall,
    isMedium,
  }
}
