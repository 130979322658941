<script setup lang="ts">
import type { CyclabilityData } from '@/composables/cyclability/cities/cyclabilityCitiesData'

defineOptions({
  name: 'CityCyclabilitySharedBikesPerfMetric',
})

const { t } = useI18n()
const { writeCache } = useWriteCacheForMetric('cityCyclabilitySharedBikesPerf')
const { dataInRange, dateRange, city } = useCyclabilityCitiesData()

function updateCachedData(data: CyclabilityData[]) {
  if (!city.value) {
    return
  }

  const ffSeries: [string, number][] = []
  const sbSeries: [string, number][] = []
  const series: { name: string, label: string, color: string, data: [string, number][] }[] = []

  if (data?.length > 0) {
    for (const [index, item] of data.entries()) {
      ffSeries[index] = [formatDate(item.date), item.freeFloatingTrips]
      sbSeries[index] = [formatDate(item.date), item.stationBasedTrips]
    }

    if (sbSeries.filter(item => item[1] !== undefined).length > 0) {
      series.push({
        name: 'station',
        label: 'Station-based bikes',
        color: '#bbc9f5',
        data: sbSeries,
      })
    }

    if (ffSeries.filter(item => item[1] !== undefined).length > 0) {
      series.push({
        name: 'free-floating',
        label: 'Free-floating bikes',
        color: '#5578e6',
        data: ffSeries,
      })
    }

    writeCache({
      city: city.value,
      dateRange: dateRange.value,
    }, {
      series,
    })
  } else {
    writeCache({
      city: city.value,
      dateRange: dateRange.value,
    }, {
      series: [{
        name: 'free-floating',
        label: 'Free-floating bikes',
        color: '#5578e6',
        data: [],
      }],
    })
  }
}

watch([dataInRange], ([dataInRange]) => {
  updateCachedData(dataInRange)
}, { immediate: true })
</script>

<template>
  <MetricCard
    kpi="cityCyclabilitySharedBikesPerf"
    :used-filters="['dateRange']"
    :variables="{
      dateRange,
    }"
  >
    <template #title>
      {{ t('cyclability.shared_bike_perf.title') }}
    </template>

    <template #default="{ data }">
      <BarMetric
        :data="data"
        :options="{
          color: d => PALETTE_COLORS_WEEK[d.name],
        }"
      />
    </template>
  </MetricCard>
</template>
