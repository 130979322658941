<script setup lang="ts">
import type { Direction } from '@/types/index.types'
import type { TrendType } from './Trend.types'
import { tv } from 'tailwind-variants'
import { TrendTypes } from './Trend.types'

defineOptions({
  name: 'Trend',
})

const props = withDefaults(defineProps<{
  trend: number | null
  direction?: Direction
  type?: TrendType
  pill?: boolean
}>(), {
  trend: null,
  direction: Directions.UP,
  type: TrendTypes.PERCENT,
  pill: false,
})

const { t } = useI18n()

const isZero = ref<boolean>(false)
const isMoreThanThousand = ref<boolean>(false)
const trendParsed = ref<number>(0)

watch(() => props.trend, (trend) => {
  if (trend !== null && trend !== 0) {
    isZero.value = false

    if (Math.abs(trend) >= 1000) {
      isMoreThanThousand.value = true
      trendParsed.value = 999

      return
    }

    trendParsed.value = Math.abs(trend)
  } else {
    isZero.value = true
    trendParsed.value = 0
    isMoreThanThousand.value = false
  }
}, { immediate: true })

const isUp = computed(() => props.direction === Directions.UP)

const ui = tv({
  slots: {
    base: 'text-nowrap',
  },
  variants: {
    pill: {
      true: 'rounded-full text-white! px-1 py-0.5 text-2xs ml-1 align-text-top',
      false: 'md:text-sm',
    },
    direction: {
      [Directions.UP]: '',
      [Directions.DOWN]: '',
    },
    isZero: {
      true: 'text-grey-300',
    },
  },
  defaultVariants: {
    pill: false,
  },
  compoundVariants: [
    {
      pill: true,
      direction: Directions.UP,
      class: 'bg-green-400',
    },
    {
      pill: true,
      direction: Directions.DOWN,
      class: 'bg-red-400',
    },
    {
      pill: false,
      direction: Directions.UP,
      class: 'text-green-500',
    },
    {
      pill: false,
      direction: Directions.DOWN,
      class: 'text-red-500',
    },
    {
      pill: [true, false],
      isZero: true,
      class: 'text-grey-300',
    },
  ],
})

const classes = computed(() => ui({
  isZero: isZero.value,
  direction: props.direction as Directions,
  pill: props.pill,
}))
</script>

<template>
  <span
    class="text-nowrap"
    :class="classes.base()"
    :title="isMoreThanThousand ? formatNumber(trend as number) : undefined"
  >
    <template v-if="isMoreThanThousand">&gt;</template>
    <template v-else>{{ isUp ? '+' : '-' }}</template>
    {{ trendParsed }}
    <span
      v-if="type === TrendTypes.POINT"
      class="text-xs"
    >
      {{ t('units.pts') }}
    </span>
    <template v-else-if="type === TrendTypes.PERCENT">%</template>
  </span>
</template>
