<script setup lang="ts">
import type { CyclabilityMapLayerProps } from '@/types/cyclability.types'
import type { CellsType } from '@/types/index.types'
import { GeometriesTypes } from '@/types/index.types'

export interface CyclabilityMapCellsGridProps extends CyclabilityMapLayerProps {
  cellsType?: CellsType
  tileSrc: string
  poiLayerBeforeId?: string
}

defineOptions({
  name: 'CellsGrid',
})

const props = withDefaults(defineProps<CyclabilityMapCellsGridProps>(), {
  layerId: 'cells-grid',
  cellsType: GeometriesTypes.H3_10,
  metric: '',
})

const { classBreaks, metric } = toRefs(props)
const colorBreaks = useExpressionColorFromBreaks(classBreaks, metric, 'case')
</script>

<template>
  <MapLibreSourceTile
    :id="layerId"
    type="vector"
    :tiles="[tileSrc]"
    :layer-props="[{
      'id': layerId,
      'source-layer': 'metrics',
      'type': 'fill',
      'paint': {
        'fill-opacity': 0.45,
        'fill-color': colorBreaks,
        'fill-antialias': true,
        'fill-outline-color': 'transparent',
      },
      'filter': [
        'all',
        ['==', '$type', 'Polygon'],
        ['has', metric],
      ],
    }, {
      'id': `${layerId}-points`,
      'beforeId': poiLayerBeforeId,
      'source-layer': 'metrics',
      'type': 'circle',
      'paint': {
        'circle-radius': 6,
        'circle-color': colorBreaks,
      },
      'filter': [
        'all',
        ['==', '$type', 'Point'],
        ['has', metric],
      ],
    }]"
  />
</template>
