<script setup lang="ts">
import type { Color } from '@/utils/colors'
import type { DSelectOption } from '../DSelect/DSelect.vue'

export interface DSelectPickerOption extends DSelectOption {
  icon?: string
  color?: Color | false
  subtext?: string
  subLabel?: string
  component?: { is: string, props: Record<string, unknown> }
  locked?: boolean
  default?: boolean
}

interface DSelectPickerProps {
  options: DSelectPickerOption[]
  modelValue: string | number | null | undefined
  defaultText?: string
  searchPlaceholder?: string | null
  searchable?: boolean
  isLoading?: boolean
}

const props = withDefaults(defineProps<DSelectPickerProps>(), {
  options: () => [],
  modelValue: null,
  defaultText: 'No Value',
  searchPlaceholder: null,
})

const emit = defineEmits(['update:modelValue', 'close'])
const attrs = useAttrs()
const value = useVModel(props, 'modelValue', emit)
const search = ref('')
const input = ref()

const options = computed(() => {
  return props.options.map((option) => {
    if (typeof option === 'string') {
      return {
        value: option,
        label: option,
      }
    }

    return option
  })
})

const selectedItem = computed(() => {
  return options.value.find(option => option.value === value.value) || null
})

const filteredOptions = computed(() => {
  if (!props.searchable || search.value.length === 0) {
    return options.value
  }

  return options.value.filter((option) => {
    const label = option.text || option.label

    return (
      label.toLowerCase().includes(search.value.toLowerCase())
      && option.value !== null
    )
  })
})

async function onOpen() {
  await nextTick()
  if (props.searchable) {
    get(input).$el.children[0].focus()
  }
}

function onClose() {
  set(search, '')
  emit('close')
}
</script>

<template>
  <DPicker
    :auto-close="!searchable"
    v-bind="attrs"
    @close="onClose"
    @open="onOpen"
  >
    <template #text>
      <div
        v-if="selectedItem && !selectedItem.default"
        :style="{
          color: selectedItem.color || 'inherit',
        }"
      >
        <p class="whitespace-nowrap overflow-hidden text-ellipsis">
          <slot
            name="left"
            :data="selectedItem"
          >
            <DIcon
              v-if="selectedItem.icon"
              :path="selectedItem.icon"
              size="sm"
            />
            <component
              :is="selectedItem.component.is"
              v-if="selectedItem.component"
              v-bind="selectedItem.component.props"
            />
          </slot>{{ selectedItem.text || selectedItem.label }}
        </p>
        <p
          v-if="selectedItem.subtext || selectedItem.subLabel"
          class="text-xs font-normal leading-3 whitespace-nowrap overflow-hidden text-ellipsis"
          :class="{ 'ml-6': selectedItem.icon || selectedItem.component }"
        >
          {{ selectedItem.subtext || selectedItem.subLabel }}
        </p>
      </div>
      <p v-else-if="isLoading">
        <DLoader
          size="sm"
          class="text-grey-500"
        />
      </p>
      <p v-else>
        {{ defaultText }}
      </p>
    </template>

    <template #default="{ close }">
      <div
        v-if="searchable"
        class="border-t border-grey-200"
      >
        <DInputText
          ref="input"
          v-model.lazy.trim="search"
          type="search"
          :placeholder="searchPlaceholder"
          class="border-none"
        />
      </div>

      <div class="max-h-52 overflow-auto divide-y divide-grey-200 border-t border-grey-200">
        <p
          v-for="item in filteredOptions"
          :key="item.value?.toString()"
          class="cursor-pointer text-sm text-slate-500 font-normal hover:bg-blue-100 flex flex-col justify-center px-2 h-10"
          :class="{
            'text-left': searchable,
            'text-grey-200! hover:bg-grey-50!': item.locked,
            'bg-blue-100': item.value === value,
            'text-grey-100': item.locked,
          }"
          @click.prevent="
            () => {
              if (item.locked) {
                return
              }

              value = item.value
              search = ''
              close()
            }
          "
        >
          <span class="leading-3">
            {{ item.text || item.label }}
            <DIcon
              v-if="item.locked"
              path="cadenas"
              size="sm"
              class="-mt-1"
            />
            <span
              v-if="item.subtext || item.subLabel"
              class="text-xs text-grey-200"
            >
              <br>
              {{ item.subtext || item.subLabel }}
            </span>
          </span>
        </p>

        <DLoader
          v-if="isLoading"
          class="text-grey-500"
        />
      </div>
    </template>
  </DPicker>
</template>
