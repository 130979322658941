<script setup lang="ts">
import type { VehicleTypeData } from '@/types/vehicleTypes.types'
import type { TrendMetricProps } from './TrendMetric.vue'

interface Props extends TrendMetricProps {
  vehiclesTypes: Partial<Record<VehicleTypeData, number | null>>
  accept?: VehicleTypeData[]
  trendTitle?: string
  titles?: Partial<Record<VehicleTypeData, string>>
}

defineOptions({
  name: 'TrendMetricWithVehiclesTypes',
})

const props = defineProps<Props>()

const modes = computed(() => {
  return (Object.entries(props.vehiclesTypes) as [VehicleTypeData, number | null][])
    .filter(([type, value]) => (props.accept || DATA_VEHICLE_TYPES).includes(type) && value !== null)
})
</script>

<template>
  <div>
    <template v-if="!modes || modes.length > 1">
      <TrendMetric
        class="mb-2"
        v-bind="props"
        :trend-title="trendTitle"
      >
        <template #default="scope">
          <slot v-bind="{ ...scope }" />
        </template>
      </TrendMetric>

      <p
        v-for="[type, value] in modes"
        :key="type || 'none'"
        class="mb-2 text-xl font-normal"
        :class="[type && getVehicleTypeColor(getVehicleType(type))]"
      >
        <VehicleIcon
          v-if="type && value !== null"
          :type="getVehicleType(type)"
          :title="titles?.[type]"
        />
        <span
          v-if="value"
          class="ml-4"
        >
          <slot
            name="typeValue"
            :value="value"
          >
            {{ formatNumber(value) }}
          </slot>
        </span>
      </p>
    </template>

    <template v-else-if="modes && modes.length === 1">
      <TrendMetric
        v-bind="props"
        :trend-title="trendTitle"
      >
        <VehicleIcon
          v-if="modes[0][0] && modes[0][1] !== null"
          :type="getVehicleType(modes[0][0])"
          :title="titles?.[modes[0][0]]"
        />
        <span
          v-if="modes[0][1]"
          class="ml-2"
          :class="[modes[0][0] && getVehicleTypeColor(getVehicleType(modes[0][0]))]"
        >
          <slot
            name="typeValue"
            :value="modes[0][1]"
          >
            {{ formatNumber(modes[0][1]) }}
          </slot>
        </span>
      </TrendMetric>
    </template>
  </div>
</template>
