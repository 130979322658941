<script setup lang="ts">
import type { CyclabilityDataLayer } from '@/composables/cyclability/cyclabilityData'

defineOptions({
  name: 'CyclabilityMapLegendCollapsable',
})

const props = defineProps<CyclabilityMapSegmentsProps>()
const emit = defineEmits(['toggle-expanded'])

interface CyclabilityMapSegmentsProps {
  layer: 'areas' | 'roads' | 'network'
  layerData: CyclabilityDataLayer
  expanded?: boolean
}

const cityStore = useCityStore()
const { slug } = storeToRefs(cityStore)
const { translateAreas, translateNetwork, translateRoads, translateTitle, translateAreasDescription, translateNetworkDescription, translateRoadsDescription } = useCyclabilityTranslation(slug)

function translateData(field: string) {
  if (props.layer === 'areas') {
    return translateAreas(field)
  }

  if (props.layer === 'network') {
    return translateNetwork(field)
  }

  if (props.layer === 'roads') {
    return translateRoads(field)
  }

  return ''
}

const description = computed(() => {
  if (props.layer === 'areas') {
    return translateAreasDescription(props.layerData.metric)
  }

  if (props.layer === 'network') {
    return translateNetworkDescription(props.layerData.metric)
  }

  if (props.layer === 'roads') {
    return translateRoadsDescription(props.layerData.metric)
  }

  return ''
})
</script>

<template>
  <div>
    <p
      class="mb-2 cursor-pointer"
      @click="() => emit('toggle-expanded', layer)"
    >
      <DIcon
        :name="expanded ? 'chevron-up' : 'chevron-down'"
        size="sm"
      />
      {{ translateTitle(layer) }}<br>
      <small class="text-grey-700">{{ translateData(layerData.metric) }}</small>
      <DTooltip
        is="span"
        v-if="!expanded && description"
        placement="top"
      >
        <DIcon
          path="help-circle"
          size="sm"
          class="text-blue-500"
        />
        <template #content>
          <p class="max-w-40">
            {{ description }}
          </p>
        </template>
      </DTooltip>
    </p>

    <p
      v-if="expanded && description"
      class="text-grey-400 text-ellipsis max-w-full mb-2"
    >
      <small>{{ description }}</small>
    </p>

    <MapLegend
      :class-breaks="layerData.classBreaks"
      :inline="!expanded"
    >
      <template
        v-if="layerData.labels"
        #label="{ index }"
      >
        {{ layerData.labels?.[index] }}
      </template>
    </MapLegend>
  </div>
</template>
