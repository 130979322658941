<script setup>
import faqQuery from '@/graphql/queries/faq.gql'

const { t } = useI18n()
const route = useRoute()
const { result, loading } = useQuery(faqQuery)

const root = ref()
const faq = computed(() => {
  return result.value?.faq
})

const menu = computed(
  () =>
    faq.value?.categories?.reduce((acc, c) => {
      acc.push({
        route: { name: 'Faq', hash: `#${encodeURIComponent(c.slug)}` },
        label: c.name,
        isActive: route.hash === `#${encodeURIComponent(c.slug)}`,
      })

      return acc
    }, []) || [],
)

onMounted(() => {
  useScrollIntoView({
    root: root.value,
  })
})
</script>

<template>
  <SidebarContainer @mounted="container => (root = container)">
    <template #left>
      <Sidebar :sub-menu="menu" />
    </template>

    <BoardTitle hide-on-mobile>
      {{ t('Help Center') }}
    </BoardTitle>

    <DAccordion v-if="!loading">
      <template
        v-for="category in faq.categories"
        :key="category.slug"
      >
        <BoardSubTitle
          :id="category.slug"
          class="pt-4!"
        >
          {{ category.name }}
        </BoardSubTitle>

        <DAccordionItem
          v-for="(question, index) in category.questions"
          :id="`faq-${question.slug}`"
          :key="index"
        >
          <template #title>
            {{ question.title }}
          </template>

          <div
            class="prose prose-a:font-bold prose-a:text-blue-500 prose-img:mx-auto"
          >
            <DMarkdown :source="question.content" />
          </div>
        </DAccordionItem>
      </template>

      <div class="min-w-80 mx-1/12 mt-8 bg-white shadow-md">
        <div class="p-8 text-center">
          <p class="mt-4 text-2xl font-bold">
            {{ t("Can't find what you're looking for?") }}
          </p>
          <p class="mb-5 text-grey-300">
            {{ t('Contact customer support for more information.') }}
          </p>
          <DButton href="mailto:contact@fluctuo.com">
            {{ t('Contact us') }}
          </DButton>
        </div>
      </div>
    </DAccordion>

    <div
      v-else
      class="mt-8"
    >
      <PlaceHolder class="space-y-8">
        <PlaceHolderLine :lines="1" />

        <div class="mx-10 px-8 py-4 shadow-md">
          <PlaceHolderLine :lines="1" />
        </div>
        <div class="mx-10 px-8 py-4 shadow-md">
          <PlaceHolderLine :lines="1" />
        </div>
        <div class="mx-10 px-8 py-4 shadow-md">
          <PlaceHolderLine :lines="1" />
        </div>
        <div class="mx-10 px-8 py-4 shadow-md">
          <PlaceHolderLine :lines="1" />
        </div>
        <div class="mx-10 px-8 py-4 shadow-md">
          <PlaceHolderLine :lines="1" />
        </div>

        <PlaceHolderLine :lines="1" />

        <div class="mx-10 px-8 py-4 shadow-md">
          <PlaceHolderLine :lines="1" />
        </div>
        <div class="mx-10 px-8 py-4 shadow-md">
          <PlaceHolderLine :lines="1" />
        </div>
      </PlaceHolder>
    </div>
  </SidebarContainer>
</template>
