<script setup>
import signupMutation from '@/graphql/mutations/signup.gql'
import useVuelidate from '@vuelidate/core'

import { required } from '@vuelidate/validators'

const { t } = useI18n()
const { translateExample } = useExamplesTranslation()
const router = useRouter()
const { mutate: signupEmail } = useMutation(signupMutation, {
  clientId: CAPTAIN,
})
const displayError = useDisplayErrors()

const inputs = reactive({
  email: null,
  password: null,
  name: null,
  organization: null,
})

const score = ref(0)
const loading = ref(false)

const rules = computed(() => ({
  email: {
    required,
    validatorEmail,
  },
  password: {
    required,
    password: (value) => {
      return value && score.value > 2
    },
  },
  name: {
    required,
  },
  organization: {
    required,
  },
}))
const validation = useVuelidate(rules, inputs)

async function signup() {
  validation.value.$touch()

  if (validation.value.$invalid) {
    return
  }

  loading.value = true

  let error
  try {
    const res = await signupEmail({
      email: inputs.email,
      password: inputs.password,
      name: inputs.name,
      organization: inputs.organization,
    })

    if (res?.data?.signup?.jwt) {
      // login with JWT
      router.push({
        name: 'LoginCallback',
        hash: `#jwt=${res.data.signup.jwt}`,
      })
    }

    error = res?.data?.signup?.error
  } catch (e) {
    console.error(e)
    error = e
  }

  if (error === 'existing_user') {
    displayError(t('An account is alread associated with that email.'))
  } else if (error) {
    displayError(t('An error occurred, please retry later'))
  }

  loading.value = false
}

function validateState(name) {
  const { $dirty, $error } = validation.value[name]
  return $dirty ? !$error : null
}
</script>

<template>
  <div class="min-h-screen inset-x-0 overflow-auto bg-teal-100">
    <div
      class="gradient before:content[''] relative flex flex-col overflow-hidden before:absolute before:h-full before:w-full before:translate-y-80 before:skew-y-6 before:bg-teal-100 before:shadow-lg md:h-full md:before:h-screen md:before:w-full md:before:translate-y-0 md:before:-translate-x-1/2 md:before:skew-y-0 md:before:-skew-x-6"
    >
      <LoginHeader />

      <div
        class="relative z-hop flex flex-1 flex-col overflow-auto p-4 pb-18 md:flex-row-reverse md:items-center md:justify-around md:gap-16 md:p-8"
      >
        <div class="flex flex-col justify-center md:w-1/2 md:flex-col-reverse xl:max-w-2xl">
          <HomepageKeyNumbers class="text-white md:mt-12" />

          <DAsyncImg
            src="/img/signup-screens-captures@2x.webp"
            class="mt-4 w-full"
          />
        </div>

        <div class="mt-4 md:w-1/2 md:pl-8 md:pr-32 xl:max-w-2xl">
          <h1 class="font-title text-3xl font-bold text-blue-500">
            {{ t('Get free access to City Dive today') }}
          </h1>

          <p class="mt-4 text-base">
            {{
              t(
                'The perfect dashboard for mobility professionals. View, analyze and understand shared mobility data from services across the world.',
              )
            }}
          </p>

          <div class="mt-4 flex flex-col gap-4 md:flex-row md:items-center md:justify-start md:gap-8">
            <p class="text-center text-base text-grey-300 md:mt-0 md:text-left md:text-sm">
              {{ t('Complete the form below or connect with:') }}
            </p>

            <LoginOAuthIcons />
          </div>

          <form
            class="mt-8"
            @submit.prevent="signup"
          >
            <DField>
              <template #label>
                {{ t('Full name') }}
                <span class="text-red-500">*</span>
              </template>
              <DInputText
                v-model="inputs.name"
                autocomplete="name"
                :placeholder="translateExample('john_doe')"
                :state="validateState('name')"
              />
              <DFieldValidationErrors
                name="name"
                :validation="validation"
              />
            </DField>

            <div class="xl:flex xl:gap-4">
              <DField class="md:flex-2">
                <template #label>
                  {{ t('Company') }}
                  <span class="text-red-500">*</span>
                </template>
                <DInputText
                  v-model="inputs.organization"
                  autocomplete="organization"
                  :placeholder="translateExample('freshmobility')"
                  :state="validateState('organization')"
                />
                <DFieldValidationErrors
                  name="organization"
                  :validation="validation"
                />
              </DField>

              <DField class="md:flex-1">
                <template #label>
                  {{ t('Work email') }}
                  <span class="text-red-500">*</span>
                </template>
                <DInputText
                  v-model="inputs.email"
                  type="email"
                  autocomplete="email"
                  :placeholder="translateExample('john_doe_email')"
                  :state="validateState('email')"
                />
                <DFieldValidationErrors
                  name="email"
                  :validation="validation"
                />
              </DField>
            </div>

            <DField>
              <template #label>
                {{ t('Your password') }}
                <span class="text-red-500">*</span>
              </template>
              <p class="text-grey-300">
                {{
                  t(
                    'Create a strong password (min. 8 characters with no repeated words)',
                  )
                }}
              </p>
              <DInputText
                v-model="inputs.password"
                type="password"
                maxlength="72"
                autocomplete="new-password"
                placeholder="********"
                :state="validateState('password')"
              />
              <PasswordStrengthMeter
                :password="inputs.password"
                @score="s => (score = s)"
              />

              <DFieldValidationErrors
                name="password"
                :validation="validation"
              />
            </DField>

            <p class="text-center md:text-left">
              <DButton
                type="submit"
                variant="gradient-primary"
                :loading="loading"
                :disabled="validation.$error || loading"
                size="lg"
              >
                {{ t('Create your account') }}
              </DButton>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@reference '@/assets/style/index.css';

@media (min-width: theme('screens.md')) {
  div.gradient {
    background-position-x: calc(100vw / 2.5);
  }
}
</style>
