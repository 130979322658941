<script setup lang="ts">
import type { GeoJSONFeature, LngLatLike, Map, MapLayerMouseEvent, Marker, Offset, PositionAnchor } from 'maplibre-gl'
import { Popup } from 'maplibre-gl'

// Extended GeoJSONFeature with layer property
export interface ExtendedGeoJSONFeature extends GeoJSONFeature {
  layer: {
    id: string
    [key: string]: any
  }
}

defineOptions({
  name: 'MapLibrePopup',
})

const props = defineProps({
  lngLat: {
    type: [Array, Object] as PropType<LngLatLike>,
    default: null,
  },
  text: {
    type: String,
    default: null,
  },
  anchor: {
    type: String as PropType<PositionAnchor>,
    default: null,
    validator: (value: string) =>
      [
        'center',
        'top',
        'bottom',
        'left',
        'right',
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right',
      ].includes(value),
  },
  offset: {
    type: [Array, Object, Number] as PropType<Offset>,
    default: () => [0, 0],
  },
  className: {
    type: String,
    default: null,
  },
  maxWidth: {
    type: String,
    default: '240px',
  },
  closeButton: Boolean,
  closeOnClick: Boolean,
  closeOnMove: Boolean,
  focusAfterOpen: Boolean,
  layerIds: {
    type: [String, Array] as PropType<string | string[]>,
    default: null,
  },
})

const {
  lngLat,
  text,
  anchor,
  offset,
  className,
  maxWidth,
  closeButton,
  closeOnClick,
  closeOnMove,
  focusAfterOpen,
} = toRefs(props)

const popup = ref()
const element = ref()
const highlighted = ref<ExtendedGeoJSONFeature | null>(null)

const options = reactive({
  anchor,
  offset,
  className,
  maxWidth,
  closeButton,
  closeOnClick,
  closeOnMove,
  focusAfterOpen,
})

const map = inject<Ref<Map | null>>('map', ref(null))
const marker = inject<Ref<Marker | null>>('marker', ref(null))

// Normalize layerIds to always be an array
const normalizedLayerIds = computed<string[]>(() => {
  if (!props.layerIds) {
    return []
  }
  return Array.isArray(props.layerIds) ? props.layerIds : [props.layerIds]
})

whenever(lngLat, (lngLat) => {
  popup.value?.setLngLat(lngLat)
})

onMounted(() => {
  const popupInstance = new Popup(options)
  const mapRef = map.value

  if (text.value) {
    popupInstance.setText(text.value)
  } else if (element.value) {
    popupInstance.setDOMContent(element.value)
  }

  if (lngLat.value) {
    popupInstance.setLngLat(lngLat.value)
  }

  if (!mapRef) {
    return
  }

  const markerRef = marker.value

  if (markerRef) {
    markerRef.setPopup(popupInstance)
  } else {
    popupInstance.addTo(mapRef)

    // Add event listeners for each layer ID
    if (normalizedLayerIds.value.length > 0) {
      normalizedLayerIds.value.reverse().forEach((layerId) => {
        mapRef.on('mousemove', layerId, onMouseMove)
        mapRef.on('mouseleave', layerId, unsetHiglitedFeature)
      })
    }
  }

  popup.value = popupInstance
})

onUnmounted(() => {
  const mapRef = map.value
  popup.value?.remove()

  // Remove event listeners for each layer ID
  if (mapRef && normalizedLayerIds.value.length > 0) {
    normalizedLayerIds.value.forEach((layerId) => {
      mapRef.off('mousemove', layerId, onMouseMove)
      mapRef.off('mouseleave', layerId, unsetHiglitedFeature)
    })
  }
})

// Generate a unique ID based on the feature's coordinates
function generateFeatureId(feature: ExtendedGeoJSONFeature): string | undefined {
  try {
    if (!feature.geometry) {
      return undefined
    }

    // Pour les points
    if (feature.geometry.type === 'Point') {
      const pointGeometry = feature.geometry as GeoJSON.Point
      if (pointGeometry.coordinates) {
        const [lng, lat] = pointGeometry.coordinates
        return `point_${lng.toFixed(5)}_${lat.toFixed(5)}`
      }
    }

    // Pour les lignes
    if (feature.geometry.type === 'LineString') {
      const lineGeometry = feature.geometry as GeoJSON.LineString
      if (lineGeometry.coordinates && lineGeometry.coordinates.length > 0) {
        const [lng, lat] = lineGeometry.coordinates[0]
        return `linestring_${lng.toFixed(5)}_${lat.toFixed(5)}`
      }
    }

    // Pour les polygones
    if (feature.geometry.type === 'Polygon') {
      const polygonGeometry = feature.geometry as GeoJSON.Polygon
      if (polygonGeometry.coordinates && polygonGeometry.coordinates.length > 0
        && polygonGeometry.coordinates[0].length > 0) {
        const [lng, lat] = polygonGeometry.coordinates[0][0]
        return `polygon_${lng.toFixed(5)}_${lat.toFixed(5)}`
      }
    }
  } catch (e) {
    console.warn('Impossible de générer un ID à partir des coordonnées', e)
  }

  return undefined
}

// Extract an ID from a feature in a robust way
function getFeatureId(feature: ExtendedGeoJSONFeature): string | number | undefined {
  // Use the native ID if available (case of IDs promoted via promoteId)
  if (feature.id !== undefined) {
    return feature.id
  }

  // Otherwise, try to find an identifier in the properties
  // These properties are often used as identifiers in GeoJSON data
  const commonIdProps = ['id', 'ID', 'osm_id', 'OSM_ID']

  if (feature.properties) {
    for (const prop of commonIdProps) {
      if (feature.properties[prop] !== undefined) {
        return feature.properties[prop]
      }
    }
  }

  // If no ID is found, generate an ID based on the coordinates
  return generateFeatureId(feature)
}

function onMouseMove(event: MapLayerMouseEvent) {
  event.preventDefault()

  popup.value.setLngLat(event.lngLat)
  const features = normalizedLayerIds.value.length > 0 ? event.features?.filter(f => normalizedLayerIds.value.includes(f.layer.id)) : event.features

  if (features?.length) {
    // Find the feature to display respecting the order of layerIds
    let feature: ExtendedGeoJSONFeature | null = null

    if (features && features.length > 0) {
      if (normalizedLayerIds.value.length > 0) {
        // Search for features in the order of the specified layerIds
        for (const layerId of normalizedLayerIds.value) {
          const matchingFeature = features.find(f => f.layer.id === layerId)
          if (matchingFeature) {
            feature = matchingFeature
            break
          }
        }
      }

      // If no feature was found with the layerIds, take the first one
      if (!feature) {
        feature = features[0]
      }
    }

    const newFeatureId = feature ? getFeatureId(feature) : undefined
    const highlightedFeatureId = highlighted.value ? getFeatureId(highlighted.value) : undefined

    if (feature && newFeatureId !== highlightedFeatureId) {
      unsetHiglitedFeature()
      setHiglitedFeature(feature)
    }
  }
}

function unsetHiglitedFeature(event?: MapLayerMouseEvent) {
  event?.preventDefault()

  const mapRef = map.value

  highlighted.value = null
  popup.value.remove()

  if (mapRef) {
    mapRef.getCanvas().style.cursor = ''
  }
}

function setHiglitedFeature(feature: ExtendedGeoJSONFeature) {
  const mapRef = map.value

  highlighted.value = feature

  if (mapRef) {
    popup.value.addTo(mapRef)
    mapRef.getCanvas().style.cursor = 'pointer'
  }
}
</script>

<template>
  <div
    v-if="$slots.element"
    ref="element"
  >
    <slot
      name="element"
      :feature="highlighted"
    />
  </div>
  <slot :popup="popup" />
</template>

<style>
@reference '@/assets/style/index.css';

.maplibregl-popup {
  @apply z-popup;

  .maplibregl-popup-content {
    @apply !bg-white/90 !rounded-md !text-gray-600 !px-3 !py-2 !text-base;
  }

  &.maplibregl-popup-anchor-left {
    .maplibregl-popup-tip {
      @apply !border-r-white/90 border-4;
    }
  }

  &.maplibregl-popup-anchor-right {
    .maplibregl-popup-tip {
      @apply !border-l-white/90 border-4;
    }
  }
}
</style>
