<script setup>
defineOptions({
  name: 'Sidebar',
})

const props = defineProps({
  to: {
    type: [String, Object],
    default: null,
  },
  text: {
    type: String,
    default: null,
  },
  menu: {
    type: Array,
    default: () => null,
  },
  subMenu: {
    type: Array,
    default: () => null,
  },
  label: {
    type: String,
    default: null,
  },
  hideReset: Boolean,
})

const emit = defineEmits(['reset'])

const { t } = useI18n()
const route = useRoute()
const slots = useSlots()
const navigationStore = useNavigationStore()

const { city } = storeToRefs(useCityStore())
const { provider } = storeToRefs(useProviderStore())
const { providerRegions } = useFilterRegion(provider)

const showFilters = computed(() => {
  const showRegions
    = route.meta.filters?.region && get(providerRegions).length > 1
  const showModes = route.meta.filters?.mode
  const hasAnotherFilter
    = route.meta.filters === true
    || (route.meta.filters
      && Object.entries(route.meta.filters).some(
        ([key, value]) =>
          key !== 'mode'
          && key !== 'region'
          && key !== 'comparativeRange'
          && value,
      ))

  if (hasAnotherFilter || showModes || showRegions || slots.filters) {
    return true
  } else {
    return false
  }
})

const activeParentMenu = computed(() => {
  return props.menu?.find((item) => {
    if (item.subMenu) {
      if (
        item.subMenu.find((subItem) => {
          return subItem.route?.name === route.name
        })
      ) {
        return item
      }
    }
    return null
  })
})

onMounted(async () => {
  await nextTick()
  navigationStore.sidebarIsMounted = true
})

onBeforeUnmount(() => {
  navigationStore.sidebarIsMounted = false
})
</script>

<template>
  <div
    class="relative w-full flex flex-col max-h-screen bg-white/90 px-4 md:w-80 md:mx-auto md:my-0 md:h-full md:overflow-auto"
  >
    <div class="md:my-6">
      <NavigationSearchInput />
    </div>

    <div
      v-if="to"
      class="hidden md:block mb-4 text-grey-300 text-xs"
    >
      <RouterLink
        class="font-title font-bold uppercase"
        :to="to"
      >
        <span>&lt;&nbsp;</span>
        {{ text || t('Back') }}
      </RouterLink>
    </div>

    <div
      class="fixed inset-x-0 bottom-0 z-sidebar flex flex-col md:flex-col-reverse md:relative md:bottom-auto md:inset-auto md:z-auto pointer-events-none md:mb-4"
    >
      <div
        class="m-4 md:m-0 flex justify-end items-end relative"
        :class="{
          'md:mt-4': activeParentMenu || subMenu,
        }"
      >
        <SidebarSubNavigation
          v-if="activeParentMenu || subMenu"
          class="flex-1 pointer-events-auto"
          :menu="activeParentMenu?.subMenu || subMenu"
          :icon="activeParentMenu?.subMenuIcon"
          :label="activeParentMenu?.subMenuLabel"
        />

        <div
          id="teleport-sidebar-controls"
          class="flex gap-2 pointer-events-auto grow-0"
        >
          <!-- block for teleport map controls and other -->
        </div>
      </div>

      <div
        class="flex"
        :class="{ 'bg-white': navigationStore.settingsIsOpen }"
      >
        <div
          class="flex-1"
          @click.stop="navigationStore.hideAll()"
        >
          <SidebarNavigation
            v-if="menu"
            :label="label"
            :menu="menu"
            :city="city"
            :provider="provider"
            class="pointer-events-auto"
          >
            <div
              v-if="to"
              class="md:hidden text-white pt-4"
            >
              <RouterLink
                class="font-title font-bold uppercase underline"
                :to="to"
              >
                <DIcon path="chevron-left" />{{
                  text || t('Back')
                }}
              </RouterLink>
            </div>
          </SidebarNavigation>
        </div>

        <div
          v-if="showFilters"
          class="relative md:hidden h-14 w-14 bg-blue-500 text-white hover:bg-blue-600 transition-colors pointer-events-auto"
          :class="{
            'rounded-md m-2 overflow-hidden': !menu,
          }"
          @click.stop="navigationStore.toggleSettings()"
        >
          <div class="absolute inset-0 flex flex-center bg-black/20">
            <DIcon
              v-if="!navigationStore.settingsIsOpen"
              path="settings"
            />
            <DIcon
              v-else
              path="cross"
              size="sm"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="$slots.default">
      <slot />
    </div>

    <!-- Mobile: max-height is screen without the navigation-bar and the sidebar-navigation-menu `max-h-[calc(100vh-120px)]` -->
    <Filters
      v-if="showFilters"
      class="md:relative md:mb-4 md:overflow-y-auto"
      :class="{
        'fixed z-sidebar inset-x-0 md:z-auto md:bottom-auto md:inset-x-auto max-h-[calc(100vh-120px)] overflow-auto md:max-h-min':
          navigationStore.settingsIsOpen,
        'bottom-14': navigationStore.settingsIsOpen && menu,
        'bottom-18': navigationStore.settingsIsOpen && !menu,
        'hidden md:block': !navigationStore.settingsIsOpen,
      }"
      :hide-reset="hideReset"
      @reset="() => emit('reset')"
    >
      <!-- Slot for filters and other settings to add -->
      <slot name="filters" />

      <div id="teleport-sidebar">
        <!-- block for teleport filters and settings from sub page -->
      </div>
    </Filters>
  </div>
</template>
