<script setup>
const props = defineProps({
  light: Boolean,
  noPadding: Boolean,
  autoClose: {
    type: Boolean,
    default: true,
  },
})

const reference = ref(null)
const floating = ref(null)

const { isOpen, floatingStyles } = useOpenAndFloat(reference, floating)

function onClickInside() {
  if (props.autoClose) {
    isOpen.value = false
  }
}
</script>

<template>
  <div class="d-actions flex h-10 w-10 justify-center">
    <DIconButton
      ref="reference"
      path="action"
      :light="light"
      @click="isOpen = true"
    />

    <Teleport to="body">
      <div
        v-if="isOpen"
        ref="floating"
        class="d-actions__floating relative z-popup bg-white shadow min-w-[160px] p-4 flex flex-col gap-2"
        :class="{ 'p-0': noPadding }"
        :style="[floatingStyles]"
        @click="onClickInside"
      >
        <slot />
      </div>
    </Teleport>
  </div>
</template>

<style>
@reference '@/assets/style/index.css';

.d-actions__floating > .d-button {
  @apply block w-full rounded-none text-grey-500 bg-transparent;

  &:focus,
  &:hover {
    @apply shadow-none text-blue-500 bg-transparent;
  }

  &.active {
    @apply text-white bg-blue-500;
  }
}
</style>
