import type { CyclabilityMapCellsGridProps } from '@/components/Maps/CyclabilityMap/CellsGrid.vue'
import type { CyclabilityMapNetworkProps } from '@/components/Maps/CyclabilityMap/CityNetwork.vue'
import type { CyclabilityMapSegmentsProps } from '@/components/Maps/CyclabilityMap/CitySegments.vue'
import type { CellsType, GeometryType } from '@/types/index.types'

import type { LngLatLike } from 'maplibre-gl'
import type { PicturePoi, RoadMetric } from './cyclabilityRoads'
import defu from 'defu'
import { AUTO_VALUE } from './cyclability'

export interface CyclabilityData {
  areas?: CyclabilityMapCellsGridProps
  network?: CyclabilityMapNetworkProps
  roads?: CyclabilityMapSegmentsProps
  pictures?: PicturePoi[]
}

export type CyclabilityDataLayer = CyclabilityMapCellsGridProps
  | CyclabilityMapNetworkProps
  | CyclabilityMapSegmentsProps

export const useCyclabilityMapOptions = createSharedComposable(() => {
  const cityStore = useCityStore()
  const { city } = storeToRefs(cityStore)

  const mapOptions = reactive<{
    zoom: number
    center?: LngLatLike
  }>({
    zoom: 7,
    center: undefined,
  })

  watch(city, (city, lastCity) => {
    if (city && city.name !== lastCity?.name) {
      mapOptions.center = [city.center.lng, city.center.lat]
      mapOptions.zoom = city.default_zoom
    }
  }, { immediate: true })

  return {
    mapOptions,
  }
})

interface CyclabilityDataOptions {
  excludeData: ('network' | 'areas' | 'roads')[]
  excludeMetric: ('network')[]
}

const URL_TILE_FILE_BASE = import.meta.env.VITE_SCREEN_URL_TILE_FILE_BASE || 'https://fluctuo-data-screen-tiles.s3.eu-west-1.amazonaws.com/prod'

export const CYCLERAP_CLASSBREAKS = makeClassBreaks([0, 1, 2, 3, 4], PALETTE_COLORS_CYCLERAP)
export const POTHOLE_CLASSBREAKS = makeClassBreaks([-1, 0, 1], [PALETTE_COLORS_CYCLERAP[0], PALETTE_COLORS_CYCLERAP[2]])
export const AIR_QUALITY_CLASSBREAKS = makeClassBreaks([0, 1, 2, 3, 4, 5], undefined, ['limegreen', 'orange', 'orangered'])
export const AIR_POLLUTION_CLASSBREAKS = makeClassBreaks([0, 5, 15, 20, 25, 50, 100, 200], undefined, ['lightgreen', 'navy'])
export const CYCLERAP_LEGEND_LABELS = ['low', 'medium', 'high', 'extreme']
export const POTHOLE_LEGEND_LABELS = ['no_pothole', 'pothole_detected']
export const AIR_QUALITY_LEGEND_LABELS = ['very_low', 'low', 'medium', 'medium+', 'high', 'very_high']

const levelByType = Object.values(GeometriesTypes).filter(t => isCellsType(t)).reduce((acc, type: CellsType) => {
  acc[type] = Number.parseInt(type.split('_')[1])
  return acc
}, {} as Record<CellsType, number>)

export function useCyclabilityData(options?: Partial<CyclabilityDataOptions>) {
  const cityStore = useCityStore()
  const { slug, isLoading } = storeToRefs(cityStore)
  const { mapOptions } = useCyclabilityMapOptions()

  const loading = ref(0)

  const definedOptions = defu<Partial<CyclabilityDataOptions>, [CyclabilityDataOptions]>(options, {
    excludeData: [],
    excludeMetric: [],
  })

  const {
    areaActiveData,
    geometryType,
    networkType,
  } = useCyclability(slug, mapOptions)
  const { translateCyclerap, translatePothole, translateQuality, translateRoadsType, translateRoadsDescription, translateRoads, translateAreas, translateAreasDescription, translateNetwork, translateNetworkDescription, translateSlope } = useCyclabilityTranslation(slug)

  const data = reactive<CyclabilityData>({})
  const updateData = createEventHook<CyclabilityData>()

  if (!definedOptions.excludeData.includes('areas')) {
    const { areasMetricActiveData } = useCyclabilityAreas(slug)

    watch([geometryType, areasMetricActiveData], ([geometryType, areasMetricActiveData]) => {
      if (!areasMetricActiveData || !geometryType) {
        data.areas = undefined
        updateData.trigger(data)
        return
      }

      if (geometryType === AUTO_VALUE) {
        geometryType = areaActiveData.value?.name || undefined
      }

      if (geometryType && isCellsType(geometryType as GeometryType)) {
        const classBreaks = areasMetricActiveData?.classBreaks || []

        data.areas = {
          tileSrc: `${URL_TILE_FILE_BASE}/${slug.value}/h3/${levelByType[geometryType as CellsType]}/{z}/{x}/{y}/file.mvt`,
          classBreaks,
          cellsType: geometryType as CellsType,
          labels: classBreaks.map(c => translateQuality(c.label || '')),
          metric: areasMetricActiveData?.metric,
          metricInfo: {
            name: translateAreas(areasMetricActiveData?.metric),
            description: translateAreasDescription(areasMetricActiveData?.metric),
          },
        }

        updateData.trigger(data)
      }
    }, { immediate: true })
  }

  if (!definedOptions.excludeData.includes('network')) {
    const { networkMetricActiveData } = useCyclabilityNetwork(slug)

    watch([networkType, networkMetricActiveData], ([networkType, networkMetricActiveData]) => {
      const isCycleInfra = networkType?.endsWith(NETWORK_CYCLE_INFRA_SUFFIX) || false
      const networkTypeBase = isCycleInfra ? networkType?.slice(0, -NETWORK_CYCLE_INFRA_SUFFIX.length) : networkType

      if (networkType && networkMetricActiveData) {
        const { classBreaks, metric } = networkMetricActiveData

        data.network = {
          tileSrc: `${URL_TILE_FILE_BASE}/${slug.value}/network/${networkTypeBase}/{z}/{x}/{y}/file.mvt`,
          classBreaks,
          labels: classBreaks.map(c => translateQuality(translateSlope(c.label || ''))),
          metric,
          isCycleInfra,
          metricInfo: {
            name: translateNetwork(metric),
            description: translateNetworkDescription(metric),
          },
        }
      } else if (networkType && !networkMetricActiveData) {
        const roadTypes = isCycleInfra ? getCyclabilityRoadTypes.getCyclingInfraTypes() : getCyclabilityRoadTypes.getAllTypes()

        data.network = {
          tileSrc: `${URL_TILE_FILE_BASE}/${slug.value}/network/${networkTypeBase}/{z}/{x}/{y}/file.mvt`,
          classBreaks: makeClassBreaks(
            [0, ...roadTypes.map(([_, value]) => value)],
            roadTypes.map(([key]) => PALETTE_COLORS_NETWORK_CLASS[key]),
          ),
          labels: roadTypes.map(([key]) => translateRoadsType(key)),
          metric: 'road',
          isCycleInfra,
          metricInfo: {
            name: translateNetwork('road'),
            description: translateNetworkDescription('road'),
          },
        }
      } else {
        data.network = undefined
      }

      updateData.trigger(data)
    }, { immediate: true })
  }

  if (!definedOptions.excludeData.includes('roads')) {
    const {
      roadsGeojson,
      pictures,
      pathsMetric,
      loading: roadsLoading,
    } = useCyclabilityRoads(slug)

    watch([pathsMetric, roadsGeojson, pictures], ([pathsMetric, roadsGeojson, pictures]) => {
      if (pathsMetric) {
        data.roads = {
          geojson: roadsGeojson,
          classBreaks: [],
          labels: undefined,
          metric: pathsMetric,
          metricInfo: {
            name: translateRoads(pathsMetric),
            description: translateRoadsDescription(pathsMetric),
          },
        }

        if (getRoadMetrics.cycleRap().includes(pathsMetric as RoadMetric)) {
          // Road types
          data.roads.classBreaks = CYCLERAP_CLASSBREAKS
          data.roads.labels = CYCLERAP_LEGEND_LABELS.map(label => translateCyclerap(label))
        } else if (pathsMetric === 'pothole') {
          // Pothole
          data.roads.classBreaks = POTHOLE_CLASSBREAKS
          data.roads.labels = POTHOLE_LEGEND_LABELS.map(label => translatePothole(label))
        } else if (getRoadMetrics.airQuality().includes(pathsMetric as RoadMetric)) {
          // Air quality
          data.roads.classBreaks = AIR_QUALITY_CLASSBREAKS
          data.roads.labels = AIR_QUALITY_LEGEND_LABELS.map(label => translateQuality(label))
        } else if (getRoadMetrics.airPollution().includes(pathsMetric as RoadMetric)) {
          // Air pollution
          data.roads.classBreaks = AIR_POLLUTION_CLASSBREAKS
          data.roads.outline = {
            classBreaks: AIR_QUALITY_CLASSBREAKS,
            labels: AIR_QUALITY_LEGEND_LABELS.map(label => translateQuality(label)),
            metric: `${pathsMetric}Quality`,
            metricInfo: {
              name: translateRoads(`${pathsMetric}Quality`),
              description: translateRoadsDescription(`${pathsMetric}Quality`),
            },
          }
        } else {
          // Other metrics
          const { classBreaks: pathsClassBreaks } = useClassBreaks(useExtractMean(roadsGeojson, pathsMetric), 5, ['lightgreen', 'navy'])

          data.roads.classBreaks = pathsClassBreaks.value
        }
      } else {
        data.roads = undefined
      }

      if (pictures) {
        data.pictures = pictures
      } else {
        data.pictures = undefined
      }

      updateData.trigger(data)
    })

    watch(roadsLoading, (roadsLoading) => {
      if (roadsLoading) {
        loading.value++
      } else {
        loading.value--
      }
    })
  }

  return {
    data,
    onData: updateData.on,
    loading: computed(() => loading.value > 0 || isLoading.value),
    mapOptions,
  }
}

export function useCyclabilityLabels() {
  const { translateCyclerap, translatePothole, translateQuality } = useCyclabilityTranslation()
  const translatedLabels = {
    cyclerap: CYCLERAP_LEGEND_LABELS.map(l => translateCyclerap(l)),
    pothole: POTHOLE_LEGEND_LABELS.map(l => translatePothole(l)),
    quality: AIR_QUALITY_LEGEND_LABELS.map(l => translateQuality(l)),
  }

  const getCycleRapLabel = (value?: number) => value ? translatedLabels.cyclerap[value - 1] : undefined
  const getPotholeLabel = (value?: number) => value !== undefined ? translatedLabels.pothole[value] : undefined
  const getAirQualityLabel = (value?: number) => value !== undefined ? translatedLabels.quality[value] : undefined

  function getMetricValue(metrics: Record<RoadMetric | string, number | string> | undefined, metric?: RoadMetric | null, forceNumber?: true): number | undefined
  function getMetricValue(metrics: Record<RoadMetric | string, number | string> | undefined, metric?: RoadMetric | null, forceNumber?: boolean): number | string | undefined {
    if (!metric) {
      return undefined
    }

    const value = metrics?.[metric]

    if (forceNumber && typeof value === 'string') {
      return Number.parseFloat(value)
    }

    return value
  }

  function getMetricIndice(metrics: Record<RoadMetric | string, number | string> | undefined, metric?: RoadMetric | null) {
    if (!metric) {
      return undefined
    }

    const value = getMetricValue(metrics, metric)

    if (metric === 'pothole') {
      return getPotholeLabel(value)
    }

    if (getRoadMetrics.cycleRap().includes(metric)) {
      return getCycleRapLabel(value)
    }

    if (getRoadMetrics.airQuality().includes(metric)) {
      return getAirQualityLabel(value)
    }

    return undefined
  }

  return {
    getCycleRapLabel,
    getPotholeLabel,
    getAirQualityLabel,
    getMetricValue,
    getMetricIndice,
  }
}
