<script setup lang="ts">
import type { CyclabilityData } from '@/composables/cyclability/cyclabilityData'
import type { PicturePoi, RoadFeatureProperties, RoadMetric } from '@/composables/cyclability/cyclabilityRoads'
import type { FeatureCollection, LineString } from 'geojson'

defineOptions({
  name: 'CyclabilitySidebarSegment',
})

const props = defineProps<{
  data: CyclabilityData
  showLinkToSegment?: boolean
}>()

const { t } = useI18n()
const { selectedArea, unselectAllAreas } = useSelectedAreaFromUrl()
const { getCycleRapLabel, getPotholeLabel, getMetricValue, getMetricIndice } = useCyclabilityLabels()
const { translateIfExists } = useLabelTranslation()

// State
const properties = ref<Partial<RoadFeatureProperties>>({})
const metrics = ref<Record<RoadMetric, number | string>>()
const metric = ref<RoadMetric>('score')
const picturesList = ref<PicturePoi[]>([])
const shownPoi = ref<string>()
const showOthers = ref(false)

// Initialize segment data from geojson features
function init(
  areaId: string,
  geojson?: FeatureCollection<LineString, RoadFeatureProperties>,
  pictures?: PicturePoi[],
) {
  const features = geojson?.features || []

  if (features.length === 0) {
    return
  }

  // Retrieve properties of the selected area to show segment data
  properties.value = features.find(f => f.properties?.name === areaId)?.properties || {}

  // Extract metrics from properties
  metrics.value = Object.entries(properties.value)
    .filter(([m]) => getRoadMetrics.all().includes(m as RoadMetric))
    .reduce((acc, [m, v]) => {
      if (v === null) {
        return acc
      }

      acc[m as RoadMetric] = v
      return acc
    }, {} as Record<RoadMetric, number | string>)

  // Retrieve POIs to show pictures
  if (selectedArea.value && pictures) {
    const index = pictures.findIndex(p => p.name === areaId)

    if (index >= 0) {
      // Get the next 3 pois
      picturesList.value = pictures
        .slice(index, index + 4)
    } else {
      picturesList.value = []
    }
  }
}

// Get global metric value from properties
function getGlobalMetricValue(metric: RoadMetric) {
  return properties.value?.[`${metric}Value` as keyof RoadFeatureProperties]
}

// Get metric value with optional number conversion
function getValue(metric: RoadMetric, forceNumber?: true) {
  return getMetricValue(metrics.value, metric, forceNumber)
}

// Get metric indice for display
function getIndice(metric: RoadMetric) {
  return getMetricIndice(metrics.value, metric)
}

// Treatment information with translations
const treatment = computed(() => {
  const treatment = properties.value?.treatment

  if (!treatment) {
    return undefined
  }

  return {
    title: translateIfExists(`cyclability.data.treatment.${treatment}.title`, null),
    description: translateIfExists(`cyclability.data.treatment.${treatment}.description`, null),
  }
})

// Filter metrics for CycleRAP section
const cycleRapMetrics = computed(() => {
  if (!metrics.value) {
    return []
  }

  return Object.entries(metrics.value)
    .filter(([name]) =>
      getRoadMetrics.cycleRap().includes(name as RoadMetric)
      && name !== 'score'
      && name !== metric.value,
    ) as [RoadMetric, number][]
})

// Filter metrics for Other section
const otherMetrics = computed(() => {
  if (!metrics.value) {
    return []
  }

  return Object.entries(metrics.value)
    .filter(([name]) =>
      !getRoadMetrics.cycleRap().includes(name as RoadMetric)
      && getRoadMetrics.withoutAirQuality().includes(name as RoadMetric)
      && name !== 'score'
      && name !== metric.value,
    ) as [RoadMetric, number][]
})

// Initialize data when selected area or data changes
watch(
  [selectedArea, () => props.data?.roads?.geojson, () => props.data?.pictures],
  ([selectedArea, segmentGeojson, pictures]) => {
    if (selectedArea && selectedArea[0] && segmentGeojson) {
      init(
        selectedArea[0],
        segmentGeojson as FeatureCollection<LineString, RoadFeatureProperties>,
        pictures,
      )
    } else {
      properties.value = {}
      metrics.value = undefined
      picturesList.value = []
    }
  },
  { immediate: true },
)

// Update metric when data metric changes
whenever(() => props.data.roads?.metric, (newMetric) => {
  metric.value = (newMetric as RoadMetric) || 'score'
}, { immediate: true })
</script>

<template>
  <MapSidebar v-if="selectedArea.length > 0">
    <div
      v-if="metrics"
      class="rounded-md bg-white mb-8 py-4 px-8"
    >
      <h4 class="font-bold gap-2 mb-4 text-lg">
        <DIcon
          name="chevron-left"
          @click="unselectAllAreas"
        />

        {{ t('Segment selected') }}
      </h4>

      <div class="flex flex-col md:flex-row gap-4 justify-between">
        <LabeledValue
          v-if="properties.scoreValue"
          :label="t('CycleRAP score')"
          :sub-label="t('Global')"
          :value="properties.scoreValue"
          :indice="getCycleRapLabel(properties.score)"
          :break-value="metrics?.score as number"
          :class-breaks="CYCLERAP_CLASSBREAKS"
          upper-bound-included
        />

        <LabeledValue
          v-if="metric && metric !== 'score' && getValue(metric) !== undefined"
          :label="data.roads?.metricInfo.name"
          :value="getGlobalMetricValue(metric) || getValue(metric)"
          :break-value="getValue(metric, true)"
          :class-breaks="data.roads?.classBreaks"
          :indice="getIndice(metric)"
          upper-bound-included
        />
      </div>

      <p
        class="text-blue-500 font-bold cursor-pointer text-center my-4"
        @click="showOthers = !showOthers"
      >
        <template v-if="showOthers">
          {{ t('Hide other values') }}
          <DIcon
            path="chevron-up"
            size="sm"
          />
        </template>
        <template v-else>
          {{ t('Display other values') }}
          <DIcon
            path="chevron-down"
            size="sm"
          />
        </template>
      </p>

      <hr v-if="showOthers">

      <Transition name="slide-down">
        <div v-if="showOthers">
          <div class="flex flex-col divide-1">
            <p class="font-bold text-grey-400 text-xs mt-4">
              {{ t('CycleRAP Score') }}
            </p>

            <LabeledValue
              v-for="[name, value] in cycleRapMetrics"
              :key="name"
              class="p-2 odd:bg-slate-100"
              :label="translateIfExists(`cyclability.data.roads.${name}`, name)"
              :value="getGlobalMetricValue(name) || value"
              :break-value="value"
              :class-breaks="CYCLERAP_CLASSBREAKS"
              :indice="getCycleRapLabel(value)"
              inline
              upper-bound-included
            />
          </div>

          <p class="font-bold text-grey-400 text-xs mt-4">
            {{ t('Others') }}
          </p>

          <div class="flex flex-col divide-y">
            <LabeledValue
              v-for="[name, value] in otherMetrics"
              :key="name"
              class="p-2"
              :label="translateIfExists(`cyclability.data.roads.${name}`, name)"
              :value="name === 'pothole' ? getPotholeLabel(value) : getGlobalMetricValue(name) || value"
              :break-value="value"
              :class-breaks="name === 'pothole' ? POTHOLE_CLASSBREAKS : undefined"
              inline
              upper-bound-included
            />
          </div>
        </div>
      </Transition>
    </div>

    <p>
      <DButton
        v-if="showLinkToSegment"
        :to="{
          name: 'CyclabilityMapPath',
          params: {
            areas: selectedArea[0],
          },
        }"
      >
        {{ t('Localize on Map') }}
      </DButton>
    </p>

    <template v-if="treatment">
      <h5 class="leading-4 my-4 font-bold text-lg">
        {{ t('Suggested treatments') }}
      </h5>

      <DColored
        :value="properties.treatmentScoreRisk !== undefined ? properties.treatmentScoreRisk - 1 : undefined"
        :colors="PALETTE_COLORS_CYCLERAP"
        no-style
      >
        <template #default="{ style }">
          <div
            class="border-l-8 border-red bg-white px-8 py-4"
            :style="{ borderColor: style.color }"
          >
            <h4
              v-if="treatment.title"
              class="font-bold text-lg"
            >
              {{ treatment.title }}
            </h4>

            <p
              v-if="treatment.description"
              class="mt-4"
            >
              {{ treatment.description }}
            </p>

            <LabeledValue
              v-if="properties.treatmentImpact"
              class="mt-4"
              :label="t('Score improvement')"
              :value="-(properties.treatmentImpact)"
              :title="t('Impact of treatment')"
              :indice="properties.treatmentScoreValue"
              :indice-title="t('Score after treatment')"
              :metric-value="properties.treatmentScoreRisk"
              :class-breaks="CYCLERAP_CLASSBREAKS"
              upper-bound-included
            />
          </div>
        </template>
      </DColored>
    </template>

    <h5 class="leading-4 my-4 font-bold text-lg mt-8">
      {{ t('Cyclist View') }}<br>
      <small class="font-thin">{{ t('4 panoramas') }}</small>
    </h5>

    <div
      v-for="picture in picturesList"
      :key="picture.name"
      class="aspect-video w-full h-auto overflow-auto rounded-md bg-slate-200 mb-4 hover:cursor-pointer relative group"
      @click="() => {
        if (picture.path) { shownPoi = picture.path }
      }"
    >
      <div class="absolute inset-0 aspect-video bg-grey-700/50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <DIcon
          class="text-white"
          path="search"
          size="md"
        />
      </div>
      <DAsyncImg
        v-if="picture.path"
        :src="picture.path"
      />
    </div>

    <CyclabilityPictureDialog
      v-model="shownPoi"
      :picture="shownPoi"
    />
  </MapSidebar>
</template>
