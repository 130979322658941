import type { AnyVehicleTypes, VehicleType, VehicleTypeData } from '@/types/vehicleTypes.types'
import { VehicleTypes } from '@/types/vehicleTypes.types'

export interface TypeObject<T> {
  type: T
}

export type VehicleTypeObject<T = VehicleTypeData> = TypeObject<T> & Record<string, any>

type VehicleTypeObjectOrData = VehicleTypeObject<VehicleTypeData> | VehicleTypeData
type VehicleTypesObj = Record<VehicleTypeData, VehicleTypeObjectOrData>

function getType<T extends AnyVehicleTypes>(d: T | VehicleTypeObject<T>): T {
  if (typeof d === 'object' && 'type' in d) {
    return d.type
  }
  return d
}

export function isVehicleType(type: string): boolean {
  return Object.values(VehicleTypes).includes(type as VehicleTypes)
    || Object.keys(VehicleTypes).includes(type)
    || Object.keys(VehicleTypes).map(v => v.toLowerCase()).includes(type)
}

export function getVehicleType(dataType: AnyVehicleTypes | VehicleTypeObject<AnyVehicleTypes>): VehicleType {
  const type = getType(dataType)

  return Object.entries(ENUM_VEHICLE_TYPES).find(
    ([, value]) => value === type || (lowercase(value) as VehicleType) === type,
  )?.[0] as VehicleType || type
}

export function getVehicleTypeLabel(dataType: AnyVehicleTypes, plural: boolean): AnyVehicleTypes | `${AnyVehicleTypes}s` {
  return `${getVehicleType(dataType)}${plural ? 's' : ''}`
}

export function getVehicleTypeClasses(type: VehicleType) {
  return {
    'border-bike! text-bike': type === VehicleTypes.BIKE,
    'border-scooter! text-scooter': type === VehicleTypes.SCOOTER,
    'border-moped! text-moped': type === VehicleTypes.MOTORSCOOTER,
    'border-car! text-car': type === VehicleTypes.CAR,
  }
}

const vehicleTypesColors: Partial<Record<VehicleType, string>> = {
  [VehicleTypes.BIKE]: 'text-bike',
  [VehicleTypes.SCOOTER]: 'text-scooter',
  [VehicleTypes.MOTORSCOOTER]: 'text-moped',
  [VehicleTypes.CAR]: 'text-car',
}

export const getVehicleTypeColor = (type: VehicleType) => vehicleTypesColors[type]

export function sortVehicleTypes(
  vehicleTypes?: VehicleTypesObj | VehicleTypeObjectOrData[],
  middleware = getType,
) {
  const arr = Array.isArray(vehicleTypes) ? vehicleTypes : Object.values(vehicleTypes || {})

  return arr.slice().sort(
    (a, b) =>
      DATA_VEHICLE_TYPES.indexOf(middleware(a))
      - DATA_VEHICLE_TYPES.indexOf(middleware(b)),
  )
}

export function vehicleTypesDataToArray(vehicleTypesData: Record<VehicleType, object>): Array<{ type: VehicleTypeData }> {
  return Object.entries(vehicleTypesData).reduce((acc: Array<{ type: VehicleTypeData }>, [type, data]) => {
    acc.push({
      ...data,
      type: type as VehicleTypeData,
    })
    return acc
  }, [])
}

export function filterVehicleTypes(vehicleTypes: VehicleTypesObj | VehicleTypeObject<VehicleTypeData>[] | VehicleTypeData[], middleware = getType) {
  const arr = Array.isArray(vehicleTypes) ? vehicleTypes : Object.values(vehicleTypes || {})

  return arr.filter(data =>
    DATA_VEHICLE_TYPES.includes(middleware(data)),
  )
}

export function sortAndFilterVehicleTypes(
  vehicleTypes: VehicleTypesObj | VehicleTypeObject<VehicleTypeData>[] | VehicleTypeData[],
  middleware = getType,
) {
  return sortVehicleTypes(filterVehicleTypes(vehicleTypes, middleware), middleware)
}
