<script setup>
const { t } = useI18n()
const { mode } = useMode()
const { comparativeRange } = useDateRangeFilter()

const atAGlance = ref()
const fleets = ref()
const trips = ref()

onMounted(() => {
  useScrollIntoView({
    root: useParentElement(),
    targets: [atAGlance.value, fleets.value, trips.value].filter(Boolean),
  })
})
</script>

<template>
  <div>
    <BoardTitle hide-on-mobile>
      {{ t('Fleets & Trips') }}
    </BoardTitle>

    <BoardSubTitle
      id="at-a-glance"
      ref="atAGlance"
      icon="glance"
      class="pt-4!"
    >
      {{ t('At a glance') }}
    </BoardSubTitle>

    <BoardGrid>
      <CityMetricsSummary />
      <CityCarsComparison v-if="mode === VEHICLE_TYPE_CAR" />
    </BoardGrid>

    <BoardSubTitle
      id="fleets"
      ref="fleets"
      icon="fleet"
    >
      {{ t('Fleets') }}
    </BoardSubTitle>

    <BoardGrid>
      <CityTableSummary
        kpi="cityFleetSummary"
        :title="t('Fleet summary')"
      />
      <CityBarMetricPerTime
        kpi="cityAvailableVehiclesPerTime"
        :title="t('Vehicles available')"
        :is-compare="Boolean(mode && comparativeRange)"
      />
      <CityTableCompare
        v-if="mode && comparativeRange"
        kpi="availableVehiclesComparative"
        :title="t('Vehicles available vs previous period')"
      />
      <CityBarMetricPerTime
        kpi="cityUsedVehiclesPerTime"
        :title="t('Vehicles used')"
      />
    </BoardGrid>

    <BoardSubTitle
      id="trips"
      ref="trips"
      icon="trips"
    >
      {{ t('Trips') }}
    </BoardSubTitle>

    <BoardGrid class="lg:grid-cols-2">
      <CityTableSummary
        class="lg:col-span-2"
        kpi="cityTripsSummary"
        :title="t('Trip summary')"
      />
      <CityBarMetricPerTime
        class="lg:col-span-2"
        kpi="cityTripsPerTime"
        :title="t('Trips')"
        :is-compare="Boolean(mode && comparativeRange)"
      />
      <CityTableCompare
        v-if="mode && comparativeRange"
        class="lg:col-span-2"
        kpi="tripsComparative"
        :title="t('Trips vs previous period')"
      />
      <CityBarMetricPerTime
        class="lg:col-span-2"
        kpi="cityTripsPerVehiclePerTime"
        :title="t('Trips per vehicle')"
        :is-stacked="false"
      />
      <CityTripsWeek />
      <CityTripsHourly />
      <CityCarRentalDistribution
        v-if="mode === VEHICLE_TYPE_CAR"
        class="lg:col-span-2"
      />
    </BoardGrid>
  </div>
</template>
