<script setup lang="ts">
defineOptions({
  name: 'CityCyclabilityOthersAnalysisMetric',
})

const { t } = useI18n()
</script>

<template>
  <MetricCard :used-filters="[]">
    <template #title>
      {{ t('cyclability.others_analysis.title') }}
    </template>

    <template #subtitle>
      {{ t('2022') }}
    </template>

    <div class="flex flex-col divide-y font-bold">
      <div class="flex justify-between py-2">
        <p>{{ t('cyclability.others_analysis.active_mobility') }}</p>
        <p class="leading-3 text-right">
          27.6%<br><small>{{ t('cyclability.others_analysis.of_total_trips') }}</small>
        </p>
      </div>
      <div class="flex justify-between py-2">
        <p>{{ t('cyclability.others_analysis.shared_mobility') }}</p>
        <p class="leading-3 text-right">
          24%<br><small>{{ t('cyclability.others_analysis.of_total_bike_trips') }}</small>
        </p>
      </div>
      <div class="flex justify-between py-2">
        <p>{{ t('cyclability.others_analysis.intermodality') }}</p>
        <p class="leading-3 text-right">
          12.4%<br><small>{{ t('cyclability.others_analysis.of_total_bike_trips') }}</small>
        </p>
      </div>
    </div>
  </MetricCard>
</template>
