<script setup>
defineOptions({
  name: 'SidebarContainer',
})

defineProps({
  haveMenu: Boolean,
  noPadding: Boolean,
})

const emit = defineEmits(['mounted'])

// container used by auto load on scroll in newsfeed
const container = ref()
provide('sidebarContainerElement', container)

onMounted(() => {
  emit('mounted', container.value)
})
</script>

<template>
  <div class="sidebar-container flex h-full flex-col md:flex-row gradient-y bg-fixed relative">
    <slot name="left" />

    <div
      ref="container"
      class="flex-1 bg-white/80 overflow-auto"
      :class="{
        'px-4 pb-20 pt-4 md:pt-0 md:px-12 md:pb-12': !noPadding,
        'mb-14 md:mb-0': haveMenu,
      }"
    >
      <slot :container="container" />
    </div>
  </div>
</template>
