import type { DateRange } from '@/stores/filters'
import {
  differenceInDays,
  endOfDay,
  isWithinInterval,
  startOfDay,
  subDays,
  subMonths,
} from 'date-fns'
import Papa from 'papaparse'

export interface CyclabilityData {
  date: Date
  freeFloatingVehicles: number
  stationBasedVehicles: number
  freeFloatingTrips: number
  stationBasedTrips: number
  counter: number
}

interface ParsedCyclabilityData {
  day: string
  nb_vehic_avail_ff: number
  nb_vehic_avail_sb: number
  nb_trip_ff: number
  nb_trip_sb: number
  counter: number
}

function getLastDateRange(dateRange: DateRange) {
  const { start, end } = dateRange
  const nbrDays = differenceInDays(end, start)

  return {
    start: startOfDay(subDays(start, nbrDays + 1)),
    end: endOfDay(subDays(start, 1)),
  }
}

function useCyclabilityCitiesDataCsv(subfix?: string) {
  const { slug } = storeToRefs(useCityStore())
  const cityDataPath = computed(() => `/data/cyclability_data_${slug.value}${subfix ? `_${subfix}` : ''}.csv`)

  // Fetch and parse the CSV data
  const { data, isFetching, execute } = useFetch<CyclabilityData[]>(cityDataPath, {
    afterFetch: (context) => {
      const parsed = Papa.parse<ParsedCyclabilityData>(context.data, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      })

      context.data = parsed.data.map((row) => {
        return {
          date: new Date(row.day),
          freeFloatingVehicles: row.nb_vehic_avail_ff,
          stationBasedVehicles: row.nb_vehic_avail_sb,
          freeFloatingTrips: row.nb_trip_ff,
          stationBasedTrips: row.nb_trip_sb,
          counter: row.counter,
        }
      })

      return context
    },
    immediate: false,
  })

  onMounted(() => {
    watch(slug, async (slug, oldSlug) => {
      if (slug && slug !== oldSlug) {
        await execute()
      }
    }, { immediate: true })
  })

  function getDataInRange(dateRange: DateRange) {
    return data.value?.filter((item) => {
      return isWithinInterval(item.date, { start: dateRange.start, end: dateRange.end })
    }) || []
  }

  return {
    data,
    loading: isFetching,
    city: slug,
    getDataInRange,
  }
}

export const useCyclabilityCitiesDataFromCounter = createSharedComposable(() => {
  const { data, loading, city, getDataInRange } = useCyclabilityCitiesDataCsv('counter')

  const filtersStore = useFiltersStore()
  const dateRangeCounter = ref<DateRange>(defaultDateRange())
  const dateRangeCounterLimit = ref<DateRange>()

  const dataInRange = ref<CyclabilityData[]>([])
  const comparedDataInRange = ref<CyclabilityData[]>([])
  const lastDateRange = ref<DateRange>()

  watch(data, (data) => {
    if (data) {
      const end = data[data.length - 1].date

      dateRangeCounter.value = {
        start: data.length > 60 ? startOfDay(subMonths(end, 2)) : data[0].date,
        end,
      }

      dateRangeCounterLimit.value = {
        start: data[0].date,
        end,
      }
    }
  })

  watch(dateRangeCounter, (dateRange) => {
    if (!dateRange) {
      return
    }

    dataInRange.value = getDataInRange(dateRange)

    if ((data.value?.length ?? 0) > 120) {
      lastDateRange.value = getLastDateRange(dateRange)
      comparedDataInRange.value = getDataInRange(lastDateRange.value)
    }
  }, { immediate: true, deep: true })

  function resetDateRange() {
    filtersStore.$reset()
  }

  return {
    dateRangeCounter,
    dateRangeCounterLimit,
    lastDateRange,
    data,
    dataInRange,
    comparedDataInRange,
    loading,
    city,
    getDataInRange,
    resetDateRange,
    getLastDateRange,
  }
})

export const useCyclabilityCitiesData = createSharedComposable(() => {
  const { data, loading, city, getDataInRange } = useCyclabilityCitiesDataCsv()

  const filtersStore = useFiltersStore()
  const { dateRange, dateRangeLimit } = storeToRefs(filtersStore)

  const dataInRange = ref<CyclabilityData[]>([])
  const comparedDataInRange = ref<CyclabilityData[]>([])
  const lastDateRange = ref<DateRange>()

  watch(data, (data) => {
    if (data) {
      const end = data[data.length - 1].date

      dateRange.value = {
        start: data.length > 60 ? startOfDay(subMonths(end, 2)) : data[0].date,
        end,
      }

      dateRangeLimit.value = {
        start: data[0].date,
        end,
      }
    }
  })

  watch(dateRange, (dateRange) => {
    dataInRange.value = getDataInRange(dateRange)
    lastDateRange.value = getLastDateRange(dateRange)
    comparedDataInRange.value = getDataInRange(lastDateRange.value)
  }, { immediate: true, deep: true })

  function resetDateRange() {
    filtersStore.$reset()
  }

  return {
    dateRange,
    dateRangeLimit,
    lastDateRange,
    data,
    dataInRange,
    comparedDataInRange,
    loading,
    city,
    getDataInRange,
    resetDateRange,
    getLastDateRange,
  }
})
