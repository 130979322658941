<script setup>
defineOptions({
  name: 'AreaMap',
})

const props = defineProps({
  zoom: {
    type: Number,
    default: 7,
  },
  center: {
    type: Array,
    default: () => [-0.09, 51.505],
  },
  zones: {
    type: Array,
    default: () => [],
  },
  crawls: {
    type: Array,
    default: () => [],
  },
  data: {
    type: Object,
    default: () => ({
      geojson: {
        features: [],
      },
      providers: [],
    }),
  },
  options: {
    type: Object,
    default: () => ({
      dataScale: 5,
      geometryType: 'h3_8',
      providers: [],
    }),
  },
  legendTitle: {
    type: String,
    default: null,
  },
  loading: Boolean,
})

const { dataScale, providers, geometryType } = toRefs(props.options)

const { t } = useI18n()
const { getAreaName }
  = useSelectedAreaFromUrl(geometryType)

const geojson = computed(
  () =>
    props.data?.geojson || newFeatureCollection(),
)

const { classBreaks } = useClassBreaks(useExtractMean(geojson), dataScale)
const colorBreaks = useExpressionColorFromBreaks(classBreaks)

const areaPaintExpression = computed(() => {
  return {
    'fill-color': colorBreaks.value,
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.6,
      0.4,
    ],
    'fill-outline-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#fff',
      'transparent',
    ],
  }
})

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
  >
    <MapLibrePopup
      :offset="[0, -5]"
      layer-ids="city-map"
    >
      <template #element="{ feature }">
        <div class="flex w-28 flex-col whitespace-normal text-xs">
          <p>{{ getAreaName(feature?.properties?.name, ' ') }}</p>
          <p class="font-bold">
            {{ `${formatNumber(feature?.properties?.mean)} ${t('vehicles')}` }}
          </p>
        </div>
      </template>
    </MapLibrePopup>

    <MapLibreSourceGeojson
      v-if="!loading"
      id="city-map"
      :layer-props="{
        type: 'fill',
        paint: areaPaintExpression,
      }"
      :data="geojson"
      :geojson-options="{
        promoteId: 'name',
      }"
    />

    <CrawlZones
      :data="crawls"
      :sources="[
        ...new Set(
          data?.providers
            .filter(p => providers.length === 0 || providers.includes(p.name))
            .map(p => p.source) || [],
        ),
      ]"
    />

    <!-- <Zones /> -->

    <DefineTemplate>
      <DLoader v-if="loading" />
      <MapLegend
        v-else
        :class-breaks="classBreaks"
      >
        <template
          v-if="legendTitle"
          #title
        >
          {{ legendTitle }}
        </template>
      </MapLegend>
    </DefineTemplate>

    <SidebarMobileControls>
      <template #default="{ isMobile }">
        <MapLibreControl
          v-if="!isMobile"
          position="bottom-left"
        >
          <ReuseTemplate />
        </MapLibreControl>
      </template>

      <template #mobile>
        <ReuseTemplate />
      </template>
    </SidebarMobileControls>
  </MapLibre>
</template>
