<script setup>
import summaryAvailableVehiclesQuery from '@/graphql/queries/summaryAvailableVehicles.gql'
import summaryCitiesQuery from '@/graphql/queries/summaryCities.gql'

const route = useRoute()
const router = useRouter()
const { unlockedRegions } = storeToRefs(useUserStore())
const filtersStore = useFiltersStore()

const regionCode = ref(filtersStore.region || RegionsCodes.EU)

const variables = computed(() => ({
  regions: get(regionCode) ? [get(regionCode)] : undefined,
}))

const { result: citiesResult, loading: citiesLoading } = useQuery(
  summaryCitiesQuery,
  null,
  {
    returnPartialData: false,
  },
)

const {
  result: summaryResult,
  loading: summaryLoading,
  load,
  refetch,
} = useLazyQuery(summaryAvailableVehiclesQuery, variables, {
  returnPartialData: false,
})

const cities = computed(
  () =>
    get(citiesResult)?.summaryCities || {
      data: [],
      help: null,
      metric: null,
    },
)

const availableVehicles = computed(
  () =>
    get(summaryResult)?.summaryAvailableVehicles || {
      data: [],
      help: null,
      metric: null,
    },
)

const loading = computed(() => get(citiesLoading) || get(summaryLoading))

async function summaryLoadOrRefetch() {
  return load() || refetch()
}

watch(regionCode, (regionCode) => {
  if (route.params.regionCode !== regionCode) {
    router.push({ name: 'Overview', params: { regionCode } })
  }

  summaryLoadOrRefetch()
})

onMounted(() => {
  const regionParam = route.params.regionCode?.length
    ? route.params.regionCode
    : null

  if (get(unlockedRegions).includes(regionParam)) {
    set(regionCode, regionParam)
  } else {
    router.replace({ name: 'Overview', params: { regionCode: null } })
  }

  summaryLoadOrRefetch()
})
</script>

<template>
  <div class="relative flex overflow-hidden">
    <div class="flex-1">
      <OverviewMap
        v-model:region-code="regionCode"
        :cities="cities"
        :available-vehicles="availableVehicles"
        :loading="loading"
      >
        <MapLibreControl
          position="top-left"
          no-style
          class="ml-4! mt-6!"
        >
          <NavigationSearchInput class="md:w-72" />
        </MapLibreControl>
      </OverviewMap>
    </div>

    <router-view v-slot="{ Component }">
      <Transition name="slideRight">
        <component
          :is="Component"
          :cities="cities"
          :loading="loading"
        />
      </Transition>
    </router-view>
  </div>
</template>
