<script setup>
const props = defineProps({
  city: {
    type: Object,
    required: true,
  },
  selectedVehicles: {
    type: Array,
    default: () => VEHICLE_TYPES,
  },
  mode: {
    type: String,
    default: 'list',
  },
})

const { t } = useI18n()
const { isCityFavorite, setCityFavorite } = useFavorite()
const { providerNameBySlugOrNA } = useProviderName()

const availableVehicles = computed(
  () => props.city.data?.[AVAILABLE_VEHICLES] || {},
)
const availableVehiclesPerCapita = computed(
  () => props.city.data?.[`${AVAILABLE_VEHICLES}-per-capita`] || {},
)
</script>

<template>
  <RowCard
    :class="{
      'border-grey-500!': !city.isMonitored,
      'border-amber-700!': city.isMonitored && !city.unlocked,
    }"
    :mode="mode"
  >
    <div class="relative mx-2">
      <DTooltip :content="city.countryLocalized">
        <DFlag :country-code="city.countryCode" />
      </DTooltip>
      <span
        v-if="!city.unlocked"
        class="pointer-events-none absolute! -top-1 -right-1 -m-1"
      >
        <DIcon
          path="cadenas"
          size="sm"
          class="stroke-white stroke-4 drop-shadow-md"
          style="paint-order: stroke"
        />
      </span>
    </div>

    <p class="my-0 mx-2 flex-1 font-bold leading-3">
      {{ city.nameLocalized }}<br>
      <span class="text-xs text-grey-300">
        <DIcon
          path="population"
          size="xs"
          class="-ml-1"
        />
        {{ formatNumber(city.info?.find(k => k.titleKey === 'pop')?.value) }}
      </span>
    </p>

    <FavoriteStar
      class="order-none"
      :class="{ 'md:order-first': mode === 'list' }"
      :favorite="isCityFavorite(city.name)"
      @click.prevent.stop="() => setCityFavorite(city.name)"
    />

    <template #right>
      <VehiclesTypesList
        :vehicle-types="city.vehicleTypes"
        :selected="selectedVehicles"
        fixed-places
      />
    </template>

    <template
      v-if="city.isMonitored && !city.unlocked"
      #metrics
    >
      <I18nMd
        class="w-full text-xs font-bold"
        keypath="upgrade_to_see_data_from"
        tag="p"
        :variables="{
          subject: city.nameLocalized,
        }"
      >
        <template #link="{ translation }">
          <RouterLink
            :to="{ name: 'SubscribePlans' }"
            class="underline"
          >
            {{ translation }}
          </RouterLink>
        </template>
        <template #bold="{ translation }">
          <span class="font-bold">{{ translation }}</span>
        </template>
      </I18nMd>
    </template>

    <template
      v-else-if="!city.data"
      #metrics
    >
      <p class="font-bold">
        <DIcon
          name="warning-colored"
          size="sm"
        />{{
          t('No data for this city.')
        }}
      </p>
    </template>

    <template
      v-else-if="!city.isMonitored"
      #metrics
    >
      <RowCardMetrics :rows="[t('Vehicles (AVG)'), 'N/A']" />
      <RowCardMetrics :rows="[t('Per 10k inhab. (AVG)'), 'N/A']" />
      <RowCardMetrics :rows="[t('Services'), 'N/A']" />
    </template>

    <template
      v-else
      #metrics
    >
      <RowCardMetrics
        :rows="[
          t('Vehicles (AVG)'),
          formatNumber(availableVehicles.value),
          availableVehicles.total === availableVehicles.value
            ? null
            : `/ ${formatNumber(availableVehicles.total)}`,
        ]"
        :tooltip="
          t(
            'The first value is calculated using your active filters and tags. The second does not change when filters and tags are applied. Both numbers can change, depending on the time range chosen.',
          )
        "
      />
      <RowCardMetrics
        :rows="[
          t('Per 10k inhab. (AVG)'),
          formatAverage(availableVehiclesPerCapita.value),
          availableVehiclesPerCapita.total === availableVehiclesPerCapita.value
            ? null
            : `/ ${formatAverage(availableVehiclesPerCapita.total)}`,
        ]"
        :tooltip="
          t(
            'The first value is calculated using your active filters and tags. The second does not change when filters and tags are applied. Both numbers can change, depending on the time range chosen.',
          )
        "
      />

      <RowCardMetrics
        :rows="[
          t('Services', { count: city.providers.all?.length ?? 0 }),
          formatNumber(city.providers.filtered?.length),
          city.providers.filtered?.length === city.providers.all?.length
            ? null
            : `/ ${formatNumber(city.providers.all?.length)}`,
        ]"
        :tooltip="
          city.providers.filtered
            && `${city.providers.filtered
              .slice(0, 10)
              .map(provider => providerNameBySlugOrNA(provider.slug))
              .join(', ')}${city.providers.filtered?.length > 10 ? '...' : ''}`
        "
      />
    </template>
  </RowCard>
</template>
