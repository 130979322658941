<script setup lang="ts">
import type { RasterTileSource, VectorTileSource } from 'maplibre-gl'
import { useSource } from './layerSource'

defineOptions({
  name: 'MapLibreSourceTile',
})

const props = withDefaults(defineProps<Props>(), {
  tileSize: 512,
  scheme: 'xyz',
  type: 'vector',
})

type Layer = Record<string, any>

interface Props {
  id: string
  url?: string
  tiles?: string[]
  tileSize?: number
  maxzoom?: number
  minzoom?: number
  scheme?: 'xyz' | 'tms'
  type?: 'vector' | 'raster'
  layerProps?: Layer | Layer[]
  promoteId?: string
}

const { id, tiles, url } = toRefs(props)

const { getSource } = useSource<VectorTileSource | RasterTileSource>(id)

const layers = computed(() => {
  const layers = props.layerProps
  return Array.isArray(layers) ? layers : [layers]
})

whenever(tiles, (tiles) => {
  getSource()?.setTiles(tiles)
})

whenever(url, (url) => {
  const source = getSource() as RasterTileSource
  if (source) {
    // Update the URL and trigger a reload by calling load()
    source.tiles = [url]
  }
})
</script>

<template>
  <MapLibreSource
    :id="id"
    :source="{
      type,
      ...(maxzoom ? { maxzoom } : {}),
      ...(minzoom ? { minzoom } : {}),
      ...(scheme ? { scheme } : {}),
      ...(type === 'vector' ? { tiles, tileSize } : { url }),
      ...(promoteId ? { promoteId } : {}),
    }"
  >
    <MapLibreLayer
      v-for="(layer, key) in layers"
      :id="layer.id || id"
      :key="layer.id || key"
      :source="id"
      v-bind="layer"
    />
  </MapLibreSource>
  <slot />
</template>
