import type { PeriodInterval } from '@/composables/vehicleTypesPeriods'

import type { CityData } from '@/stores/cities'
import type { VehicleTypeData } from '@/types/vehicleTypes.types'
import cityDetailsQuery from '@/graphql/queries/cityDetails.gql'
import cityWeatherQuery from '@/graphql/queries/cityWeather.gql'
import { defineStore } from 'pinia'

interface CityDetails {
  cover: { imageUrl: string, credit: string }
  flashNews: []
  info: { titleKey: string, value: any }[]
  intro: string
  motto: string
  name: string
  providers: Record<string, any[]>
  vehicleTypesPeriods: Record<VehicleTypeData, PeriodInterval[]>
}

export interface City extends CityDetails, CityData {}

export const useCityStore = defineStore('city', () => {
  const { onCubeLoaded, waitingCube } = useCubeStore()
  const citiesStore = useCitiesStore()
  const filtersStore = useFiltersStore()
  const { setPref, getPref } = usePreferences()

  const route = useRoute()
  const router = useRouter()

  const slug = ref<string>()
  const city = ref<City | null>(null)
  const weather = ref(null)
  const loading = ref(false)
  const hasSlug = computedEager(() => !!slug.value)

  const { periods, periodsEmpty } = useVehicleTypesPeriods(city)

  const { load, refetch, onResult } = useLazyQuery(
    cityDetailsQuery,
    computed(() => ({
      city: slug.value || null,
    })),
    {
      errorPolicy: 'all',
      enabled: hasSlug,
      returnPartialData: false,
    },
  )

  onResult(async ({ partial, data, errors }) => {
    if (partial) {
      return
    }

    const res = data?.city || null

    if (res) {
      mergeCityWithCube(res)
    } else {
      set(city, null)
    }

    if (errors) {
      errors.forEach(e => console.error('city:', e.message))
    }

    set(loading, false)
  })

  const { onResult: onWeatherResult } = useQuery(
    cityWeatherQuery,
    computed(() => ({
      city: slug.value || null,
      dateRange: toRaw(filtersStore.dateRangeStr),
    })),
    {
      enabled: hasSlug,
      returnPartialData: false,
    },
  )

  onWeatherResult(async ({ partial, data }) => {
    if (partial) {
      return
    }

    const res = data?.weather || null
    set(weather, res || null)
  })

  function mergeCityWithCube(cityData: CityDetails) {
    set(city, {
      ...cityData,
      ...citiesStore.getCity(cityData.name),
    })
  }

  function $reset() {
    set(city, null)
    set(weather, null)
    set(loading, false)
  }

  onCubeLoaded(() => {
    if (city.value) {
      mergeCityWithCube(city.value)
    }
  })

  return {
    slug,
    city,
    periods,
    periodsEmpty,
    weather,
    $reset,
    load: async (citySlug: string) => {
      if (!citySlug) {
        return
      }

      set(loading, true)
      set(slug, citySlug)
      await nextTick()

      // to use cities store, we need to wait for the cube to be loaded
      await waitingCube()
      const cityInfo = citiesStore.getCity(citySlug)

      // TODO: find a solution to remove the route dependency
      if (
        (route.meta.needToBeUnlocked && cityInfo?.unlocked)
        || !route.meta.needToBeUnlocked
      ) {
        setPref(
          'visited',
          [
            { city: citySlug },
            ...(getPref('visited') || []).filter((v: { city: string }) => v.city !== citySlug),
          ].splice(0, 10),
        )

        try {
          return load() || refetch()
        } catch (e: any) {
          console.error(e.message)
          set(loading, false)
          return
        }
      }

      set(loading, false)
      return router.replace({ name: 'Cities' })
    },
    unload: () => {
      set(slug, null)
      set(city, null)
      set(weather, null)
    },
    isLoading: loading,
    hasSlug,
  }
})

// make sure to pass the right store definition, `useCityStore` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCityStore, import.meta.hot))
}
