export const useCyclabilityAreas = createSharedComposable((citySlug: MaybeRef<string | undefined>) => {
  const { areaActiveData, areasMetric, availableAreasMetrics } = useCyclability(citySlug)

  const loading = ref(0)

  const areasMetricActiveData = computed(() => {
    return areaActiveData.value?.metrics.find(m => m.metric === areasMetric.value)
  })

  return {
    loading: computed(() => loading.value > 0),
    areaActiveData,
    areasMetricActiveData,
    availableAreasMetrics,
  }
})
