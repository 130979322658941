<script setup>
import getExportTokenQuery from '@/graphql/queries/getExportToken.gql'
import SwaggerUI from 'swagger-ui'

const { t } = useI18n()
const route = useRoute()
const { organizationId, hasExport } = storeToRefs(useUserStore())
const toast = useToast()

const ui = ref()
const token = ref()
const jsonDoc = ref()
const menu = ref([])
const version = ref()

const root = ref()
useScrollIntoView({
  root,
})

const { onResult, refetch } = useQuery(
  getExportTokenQuery,
  {
    organizationId: get(organizationId),
  },
  {
    returnPartialData: false,
  },
)

onResult(({ partial, data }) => {
  if (partial) {
    return
  }

  if (data?.getExportToken) {
    token.value = data.getExportToken
    setPreauthorizeApiKey()
  } else {
    token.value = null
    toast.push({
      message: t('An error occurred while fetching the token.'),
      type: 'alert',
    })
  }
})

onMounted(async () => {
  const url = `${import.meta.env.VITE_CITY_DIVE_SERVER}/export/doc`

  ui.value = new SwaggerUI({
    dom_id: '#swagger',
    url,
    persistAuthorization: true,
    deepLinking: true,
    tryItOutEnabled: true,
    onComplete: () => {
      setPreauthorizeApiKey()
    },
  })

  const doc = await fetch(`${import.meta.env.VITE_CITY_DIVE_SERVER}/export/doc`)
  set(jsonDoc, await doc.json())
})

watch(
  [() => route.hash, jsonDoc],
  ([hash, jsonDoc]) => {
    if (jsonDoc) {
      renderMenu(jsonDoc, hash)
    }
  },
  { immediate: true },
)

async function refetchToken() {
  await refetch()
  setPreauthorizeApiKey()
}

function setPreauthorizeApiKey() {
  if (token.value && ui.value) {
    ui.value.preauthorizeApiKey('bearerAuth', token.value.value)
  }
}

function renderMenu(jsonDoc, hash) {
  set(version, jsonDoc.info.version)
  let tags = new Set()

  Object.keys(jsonDoc.paths).forEach((k) => {
    jsonDoc.paths[k].get.tags.forEach((t) => {
      tags.add(t)
    })
  })

  tags = [...tags]
    .sort((a, b) => a.localeCompare(b))
    .map(el => ({
      route: { name: 'Export', hash: `#/${el}` },
      label: el,
      isActive: hash === `#/${el}`,
    }))

  menu.value = [...tags]
}
</script>

<template>
  <SidebarContainer
    have-menu
    @mounted="container => (root = container)"
  >
    <template #left>
      <Sidebar :sub-menu="menu" />
    </template>

    <BoardTitle>{{ `${t('Export API')} - ${version}` }} </BoardTitle>

    <RowCardContainer>
      <template #title>
        {{ t('API Token') }}
      </template>
      <Token
        :has-export="hasExport"
        :organization-id="organizationId"
        :token="token"
        @created-token="refetchToken"
      />
    </RowCardContainer>

    <RowCardContainer>
      <template #title>
        {{ t('Documentation') }}
      </template>
      <div id="swagger" />
    </RowCardContainer>
  </SidebarContainer>
</template>

<style>
@reference '@/assets/style/index.css';

#swagger {
  .scheme-container,
  .try-out {
    display: none;
  }
}

.swagger-ui {
  .opblock.opblock-get {
    @apply border-blue-500;

    .opblock-summary-method {
      @apply bg-blue-500;
    }

    .opblock-summary {
      @apply border-blue-500;
    }

    .tab-header .tab-item.active h4 span:after {
      @apply bg-blue-500;
    }
  }

  .btn.execute {
    @apply border-blue-500 bg-blue-500;
  }

  .info a {
    @apply text-blue-500;
  }

  .scopes h2 a {
    @apply text-blue-500;
  }
}
</style>
