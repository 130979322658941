<script setup>
defineProps({
  menu: {
    type: Object,
    required: true,
  },
  icon: {
    type: String,
    default: 'scroll',
  },
  label: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()
const element = ref()

const { isOpen } = useOpenAndHoverOnMobile(element)
</script>

<template>
  <div
    ref="element"
    class="rounded-b-md md:rounded-md bg-white md:py-4 py-1 shadow-sm md:shadow-none border-t-4 border-blue-500 md:border-t-0"
    @click="isOpen = !isOpen"
  >
    <p class="uppercase text-xs md:text-sm ml-2 text-grey-300">
      <DIcon
        :path="icon"
        size="sm"
      /> {{ label || t('Scroll to') }}
    </p>
    <div class="flex px-2 md:hidden">
      <p class="text-blue-500 font-bold text-base">
        {{ (menu.find(item => item.isActive) || menu[0])?.label }}
        <DIcon
          class="ml-1"
          path="chevron-down"
          size="sm"
        />
      </p>
    </div>
    <nav
      class="flex-col md:px-4 px-2"
      :class="{
        'hidden md:flex': !isOpen,
        'flex': isOpen,
      }"
    >
      <DLink
        v-for="item in menu"
        :key="item.label"
        :to="item.route"
        :class="{
          'items-active': item.isActive,
          'items-desactive': item.isActive === false,
        }"
        :disabled="item.isActive"
        exact
        class="py-2 md:py-0"
      >
        {{ item.label }}
      </DLink>
    </nav>
  </div>
</template>

<style>
@reference '@/assets/style/index.css';

.items-active {
  @apply !text-blue-500 hidden md:block;
}

.items-desactive {
  @apply !text-grey-500 hover:!text-blue-500;
}
</style>
