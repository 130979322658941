<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { useImage } from '@vueuse/core'

defineOptions({
  name: 'DAsyncImg',
})

const props = defineProps<AsyncImgProps>()

interface AsyncImgProps {
  src?: string
  alt?: string
  errorSrc?: string
  class?: HTMLAttributes['class']
}

const imageOptions = computed(() => ({
  src: props.src || '',
  alt: props.alt || '',
}))

const { isLoading, error } = useImage(imageOptions)
const { t } = useI18n()
</script>

<template>
  <slot
    v-if="isLoading"
    name="loading"
  >
    <DLoader />
  </slot>
  <img
    v-else-if="error && errorSrc"
    :src="errorSrc"
    :alt="alt || t('image_not_loaded')"
    class="h-full w-full object-cover"
    :class="[props.class]"
  >
  <img
    v-else
    :src="props.src || ''"
    class="h-full w-full object-cover"
    :class="[props.class]"
  >
</template>
