<script setup>
const props = defineProps({
  news: {
    type: Object,
    required: true,
  },
  containerTop: {
    type: Number,
    default: 0,
  },
  filterTags: {
    type: Array,
    default: () => [],
  },
  filterCities: {
    type: Array,
    default: () => [],
  },
  filterProviders: {
    type: Array,
    default: () => [],
  },
  filterVehicleTypes: {
    type: Array,
    default: () => [],
  },
})
const { translateTag, translateVehicleType, translateLocation }
  = useLabelTranslation()
const { providerNameBySlug } = useProviderName()

const { t } = useI18n()
const { isAdmin } = storeToRefs(useUserStore())

const el = ref(null)

const hasAnyTag = computed(() => {
  return (
    props.news.cities?.length
    || props.news.provider?.length
    || props.news.tags?.length
    || props.news.vehicleTypes?.length
  )
})

const isFiltered = computed(() => {
  return (
    props.filterCities?.length
    || props.filterProviders?.length
    || props.filterTags?.length
    || props.filterVehicleTypes?.length
  )
})

const cities = computed(() => {
  return (
    props.news.cities?.map(city => ({
      key: city,
      label: translateLocation(city),
      isFiltered: props.filterCities
        ?.map(city => city.replace(/(^Q\w+):.*$/, '$1'))
        .includes(city.replace(/(^Q\w+):.*$/, '$1')),
    })) || []
  )
})

const providers = computed(() => {
  return (
    props.news.providers?.map(provider => ({
      key: provider,
      label: providerNameBySlug(provider),
      isFiltered: props.filterProviders?.includes(provider),
    })) || []
  )
})

const tags = computed(() => {
  return (
    props.news.tags?.map(tag => ({
      key: tag,
      label: translateTag(tag),
      isFiltered: props.filterTags?.includes(tag),
    })) || []
  )
})

const vehicleTypes = computed(() => {
  return (
    props.news.vehicleTypes?.map(vehicleType => ({
      key: vehicleType,
      label: translateVehicleType(vehicleType),
      isFiltered: props.filterVehicleTypes?.includes(vehicleType),
    })) || []
  )
})
</script>

<template>
  <div
    ref="el"
    class="bg-white font-normal shadow-sm rounded-md overflow-auto"
  >
    <DLink
      class="group flex flex-col-reverse hover:text-inherit md:flex-row"
      :href="news.url"
      target="_blank"
    >
      <div class="flex flex-1 flex-col overflow-auto py-4 px-6">
        <p class="mt-2 text-xl font-bold group-hover:underline">
          {{ news.title || t('N/A') }}
        </p>
        <p class="mt-4 flex-1 text-sm">
          {{ news.description }}
        </p>
        <NewsfeedDateLang
          :news="news"
          class="mt-4 text-sm"
        />
      </div>

      <figure
        class="min-h-40 relative aspect-square max-h-40 min-w-[160px] flex-none"
      >
        <NewslinkImage
          :image="news.image"
          :tags="news.tags"
        />
        <div class="absolute bottom-4 w-full text-center hidden md:block">
          <DButton
            variant="fill-secondary"
            size="sm"
            icon-right="external-link"
          >
            {{ t('Read more') }}
          </DButton>
        </div>
      </figure>
    </DLink>

    <div
      v-if="hasAnyTag"
      class="bg-blue-100"
    >
      <p
        class="flex flex-wrap divide-x px-6 py-2 text-sm font-bold"
        :class="{
          'divide-blue-300 text-blue-300': isFiltered,
          'divide-blue-500 text-blue-500': !isFiltered,
        }"
      >
        <span
          v-for="tag in cities"
          :key="tag.key"
          class="my-0.5 whitespace-nowrap px-2 capitalize first:pl-0 last:pr-0"
          :class="{ 'text-blue-500': tag.isFiltered }"
        >{{ tag.label }}</span>
        <span
          v-for="tag in providers"
          :key="tag.key"
          class="my-0.5 whitespace-nowrap px-2 capitalize first:pl-0 last:pr-0"
          :class="{ 'text-blue-500': tag.isFiltered }"
        >{{ tag.label }}</span>
        <span
          v-for="tag in tags"
          :key="tag.key"
          class="my-0.5 whitespace-nowrap px-2 capitalize first:pl-0 last:pr-0"
          :class="{ 'text-blue-500': tag.isFiltered }"
        >{{ tag.label }}</span>
        <span
          v-for="tag in vehicleTypes"
          :key="tag"
          class="my-0.5 whitespace-nowrap px-2 capitalize first:pl-0 last:pr-0"
          :class="{ 'text-blue-500': tag.isFiltered }"
        >{{ tag.label }}</span>

        <template v-if="isAdmin">
          <span class="flex-1 border-none!" />
          <DLink
            class="self-end border-none! text-blue-500 underline"
            :href="`https://captain.fluctuo.com/citydive/newslinks/${news.id}`"
          >
            {{ t('Edit this article') }}
          </DLink>
        </template>
      </p>
    </div>
  </div>
</template>
