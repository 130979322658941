<script setup lang="ts">
import type { MapLayerMouseEvent } from 'maplibre-gl'
import blueIcon from '@/assets/marker-blue.png'

import lockedIcon from '@/assets/marker-locked.png'
import mauveIcon from '@/assets/marker-mauve@2x.png'
import pinkIcon from '@/assets/marker-pink.png'
import providerFootprintsQuery from '@/graphql/queries/providerFootprints.gql'

interface Linked {
  name: string
  lat: number
  lng: number
}

interface Footprint {
  name: string
  lat: number
  lng: number
  lnglat: number[]
  availableInCD: boolean
  linked?: Linked
  locked?: boolean
}

interface FootprintLinked extends Omit<Footprint, 'linked'> {
  linked: true
  linkedCity?: string
  linkedLat?: number
  linkedLng?: number
}

type FootprintFeature = GeoJSON.Feature<GeoJSON.Point, Footprint | FootprintLinked>
type FootprintLinkedFeature = GeoJSON.Feature<GeoJSON.Point, FootprintLinked>

defineOptions({
  name: 'ProviderFootprintsMap',
})

const props = defineProps<{
  providerSlug?: string
}>()

const { t } = useI18n()
const router = useRouter()
const filtersStore = useFiltersStore()
const citiesStore = useCitiesStore()

const clusterOptionsAvailable = {
  cluster: true,
  clusterRadius: 5,
  clusterProperties: {
    name: ['any', ['get', 'name']],
  },
}

const clusterOptionsUnavailable = {
  cluster: true,
  clusterRadius: 20,
  clusterProperties: {
    linked: ['any', ['get', 'linked']],
    name: ['any', ['get', 'name']],
  },
}

const layerUnavailble = [
  {
    id: 'footprints-unavailable',
    type: 'symbol',
    images: {
      pink: pinkIcon,
      blue: blueIcon,
      mauve: mauveIcon,
    },
    layout: {
      'icon-image': ['case', ['==', ['get', 'linked'], true], 'blue', 'pink'],
      'icon-allow-overlap': true,
      'icon-size': 1,
    },
    filter: ['!', ['has', 'point_count']],
  },
  {
    id: 'clusters',
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      'icon-image': 'mauve',
      'icon-allow-overlap': true,
      'icon-size': 0.5,
    },
  },
  {
    id: 'cluster-count',
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count}',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
    paint: {
      'text-color': 'white',
    },
  },
]

const layerLines = {
  type: 'line',
  paint: {
    'line-color': '#455eef',
    'line-width': 3,
    'line-opacity': 0.6,
    'line-dasharray': [3, 4],
  },
}

const spiderOptions = {
  minZoomLevel: 8,
  zoomIncrement: 2,
  spiderLeavesLayout: {
    'icon-image': ['case', ['==', ['get', 'linked'], true], 'blue', 'pink'],
    'icon-allow-overlap': true,
    'icon-size': 1,
  },
}

const variables = computed(() => ({
  provider: props.providerSlug,
}))

const { onResult, loading } = useQuery<{
  providerFootprints: {
    data: Footprint[]
  }
}>(providerFootprintsQuery, variables, {
  returnPartialData: false,
})

const otherGeojson = shallowRef(newFeatureCollection<GeoJSON.Point, Footprint | FootprintLinked>())
const availableGeojson = shallowRef(newFeatureCollection<GeoJSON.Point, Footprint | FootprintLinked>())

onResult((res) => {
  if (res?.partial) {
    return
  }

  const footprints = res?.data?.providerFootprints?.data || []

  set(otherGeojson, newFeatureCollection(
    footprints
      .filter(d => !d.availableInCD)
      .map((footprint): FootprintFeature => {
        return {
          type: 'Feature',
          properties: {
            ...footprint,
            ...(footprint.linked
              ? {
                  linked: true,
                  linkedCity: footprint.linked.name,
                  linkedLat: footprint.linked.lat,
                  linkedLng: footprint.linked.lng,
                }
              : {}),
          },
          geometry: {
            type: 'Point',
            coordinates: [footprint.lng, footprint.lat],
          },
        }
      }),
  ))

  set(availableGeojson, newFeatureCollection(
    footprints
      .filter(d => d.availableInCD)
      .map((footprint): FootprintFeature => {
        return {
          type: 'Feature',
          properties: {
            ...footprint,
            locked: citiesStore.isCityLocked(footprint.name),
          },
          geometry: {
            type: 'Point',
            coordinates: [footprint.lng, footprint.lat],
          },
        }
      }),
  ))
})

function selectCity(cityName: string) {
  router.push({
    name: 'City',
    params: { citySlug: cityName },
  })
}

function getTitle(name: string, linkedCity?: string) {
  if (linkedCity) {
    return t('{city} (captured under {linkedCity})', {
      city: name,
      linkedCity: citiesStore.getCityNameLocalized(linkedCity),
    })
  } else {
    return citiesStore.getCityNameLocalized(name)
  }
}

function onClickOnUnavailable(e: MapLayerMouseEvent) {
  const feature = e.features?.[0]

  if (feature?.properties.linked) {
    selectCity(feature.properties.name)
  }
}

function onClickOnAvailable(e: MapLayerMouseEvent) {
  const feature = e.features?.[0]

  if (feature?.properties.name) {
    selectCity(feature.properties.name)
  }
}

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
</script>

<template>
  <div class="provider-footprints-map relative h-full">
    <MapLibre
      :center="filtersStore.regionDetails?.center"
      :zoom="filtersStore.regionDetails?.zoom"
      :max-zoom="12"
      :min-zoom="3"
    >
      <MapLibrePopup
        anchor="left"
        :offset="10"
        layer-ids="footprints-unavailable"
      >
        <template #element="{ feature }">
          {{
            getTitle(
              feature?.properties?.cityName,
              feature?.properties?.linkedCity,
            )
          }}
        </template>
      </MapLibrePopup>

      <MapLibrePopup
        :offset="20"
        anchor="left"
        layer-ids="footprints-available"
      >
        <template #element="{ feature }">
          {{ getTitle(feature?.properties?.cityName) }}
        </template>
      </MapLibrePopup>

      <MapLibreSourceGeojson
        id="footprints"
        :layer-props="layerUnavailble"
        :data="otherGeojson"
        :cluster-options="clusterOptionsUnavailable"
        :spiderify="['clusters']"
        :spiderify-options="spiderOptions"
        @click="onClickOnUnavailable"
      >
        <template #default="{ features }">
          <MapLibreSourceGeojson
            id="footprints-linked-lines"
            :data="newFeatureCollection<GeoJSON.LineString, FootprintLinked>(
              features
                .filter(({ properties }) => properties?.linked)
                .map((feature) => {
                  const { properties, geometry } = feature as FootprintLinkedFeature

                  return {
                    type: 'Feature',
                    properties,
                    geometry: {
                      type: 'LineString',
                      coordinates: [
                        geometry.coordinates,
                        [properties.linkedLng, properties.linkedLat],
                      ],
                    },
                  } as GeoJSON.Feature<GeoJSON.LineString, FootprintLinked>
                }))"
            :layer-props="layerLines"
          />
        </template>
      </MapLibreSourceGeojson>

      <MapLibreSourceGeojson
        id="footprints-available"
        :layer-props="[
          {
            type: 'symbol',
            beforeId: 'footprints',
            images: {
              provider: `${CDN_PATH}/markers/v2/${props.providerSlug}-2x.png`,
            },
            layout: {
              'icon-image': 'provider',
              'icon-allow-overlap': true,
              'icon-size': 0.33,
              'icon-anchor': 'bottom',
              'icon-offset': [0, 50],
              'icon-padding': -12,
            },
            filter: ['!', ['has', 'point_count']],
          },
          {
            id: 'footprints-lockers',
            type: 'symbol',
            beforeId: 'footprints-lockers',
            images: {
              locked: lockedIcon,
            },
            layout: {
              'icon-image': 'locked',
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom-left',
              'icon-offset': [-5, -13],
              'icon-size': 0.8,
            },
            filter: ['get', 'locked'],
          },
        ]"
        :data="availableGeojson"
        :cluster-options="clusterOptionsAvailable"
        @click="onClickOnAvailable"
      />
    </MapLibre>

    <DefineTemplate>
      <MapLegend>
        <div
          v-if="loading"
          class="spinner"
        >
          <DLoader /> {{ t('loading') }}
        </div>

        <div v-else>
          <h5 class="text-base font-bold">
            {{ t('Available cities', { providerSlug }) }}
          </h5>
          <ul>
            <li>
              <img
                width="60"
                height="67"
                :src="`${CDN_PATH}/markers/v2/${providerSlug}.png`"
                :alt="t('maps_markers.provider', { provider: providerSlug })"
                class="-my-1 -mx-4 inline"
              >
              {{ t('Monitored in City Dive') }}
            </li>
            <li>
              <img
                src="@/assets/marker-blue.png"
                width="23"
                height="23"
                :alt="t('maps_markers.blue')"
                class="inline"
              >
              &nbsp;{{ t('Satellite city') }}
            </li>
            <li>
              <img
                src="@/assets/marker-pink.png"
                :alt="t('maps_markers.pink')"
                class="inline"
              >
              &nbsp;{{ t('Not yet available in City Dive') }}
            </li>
          </ul>
        </div>
      </MapLegend>
    </DefineTemplate>

    <SidebarMobileControls>
      <template #default="{ isMobile }">
        <div
          v-if="!isMobile"
          class="absolute bottom-8 right-8 z-400 bg-white p-4 shadow-2xl"
        >
          <ReuseTemplate />
        </div>
      </template>

      <template #mobile>
        <ReuseTemplate />
      </template>
    </SidebarMobileControls>
  </div>
</template>
