type OSMRoadType = string
type CityRoadType = string
export type RoadType = OSMRoadType | CityRoadType

interface RoadFeaturePropertiesBase {
  id: string
  name: string
  roadName?: string
  scoreValue?: number
  treatment?: string
  treatmentScoreValue?: number
  treatmentScoreRisk?: number
  treatmentImpact?: number
  picture?: string
  path: string | null
}

export interface PicturePoi {
  id: string
  name: string
  path: string | null
  coords: number[]
}

export enum RoadMetrics {
  score = 'cycleRap_score',
  scoreBicycle = 'cycleRap_scoreBicycle',
  scoreBicycleToBicycle = 'cycleRap_scoreBicycleToBicycle',
  scoreVehicleToBicycle = 'cycleRap_scoreVehicleToBicycle',
  scoreBicycleToPedestrian = 'cycleRap_scoreBicycleToPedestrian',
  treatmentImpact = 'treatmentImpact',
  soundAvg = 'soundAvg',
  soundMax = 'soundMax',
  pm1 = 'pollution_pm1',
  pm2_5 = 'pollution_pm2_5',
  pm10 = 'pollution_pm10',
  NO2 = 'pollution_NO2',
  pm1Quality = 'airQuality_pm1',
  pm2_5Quality = 'airQuality_pm2_5',
  pm10Quality = 'airQuality_pm10',
  NO2Quality = 'airQuality_NO2',
  airQuality = 'airQuality',
  bumps = 'bumps',
  pothole = 'pothole',
  counter = 'counter',
  parking = 'parking',
  parking_slot = 'parking_slot',
}

export type RoadFeatureProperties = RoadFeaturePropertiesBase & Record<RoadMetric, number | undefined>
export type RoadMetric = keyof typeof RoadMetrics

function filterRoadMetrics(search: string) {
  return Object.entries(RoadMetrics)
    .reduce((acc, [key, value]) => {
      if (value.includes(search)) {
        acc.push(key as RoadMetric)
      }
      return acc
    }, [] as RoadMetric[])
}

export const getRoadMetrics: Record<string, (predicate?: any) => RoadMetric[]> = {
  all: () => Object.keys(RoadMetrics) as RoadMetric[],
  cycleRap: () => filterRoadMetrics('cycleRap'),
  airPollution: () => filterRoadMetrics('pollution'),
  airQuality: () => filterRoadMetrics('airQuality'),
  withoutAirQuality: () => Object.keys(RoadMetrics)
    .filter(key => key !== 'airQuality') as RoadMetric[],
  selectable: () => Object.entries(RoadMetrics)
    .reduce((acc, [key, value]) => {
      if (!value.includes('airQuality')) {
        acc.push(key as RoadMetric)
      }
      return acc
    }, [] as RoadMetric[]),
}

export const useCyclabilityRoads = createSharedComposable((citySlug: MaybeRef<string | undefined>) => {
  const { urlToFetch } = useCyclability(citySlug)
  const { translateRoads } = useCyclabilityTranslation(citySlug)
  const pictures = ref<PicturePoi[]>([])
  const roadsGeojson = ref(newFeatureCollection<GeoJSON.LineString, RoadFeatureProperties>())

  const availablePathsMetrics = computed(() => {
    if (roadsGeojson.value.features.length === 0) {
      return []
    }

    const metrics = getRoadMetrics.selectable().filter((metric) => {
      const road = roadsGeojson.value.features.find(road => road.properties?.[metric] !== undefined)
      return road !== undefined
    })

    return metrics.map(metric => ({
      name: metric,
      label: translateRoads(metric),
    }))
  })

  const pathsMetric = ref<RoadMetric>('score')

  const isFetching = ref(false)

  onMounted(async () => {
    isFetching.value = true

    const res = await fetchGzipJson(urlToFetch('/paths-augmented.geojson.gz')) as any
    const geojson = res as GeoJSON.FeatureCollection<GeoJSON.Point | GeoJSON.LineString>

    const tmpPois: PicturePoi[] = []
    const tmpRoadsGeojson = newFeatureCollection<GeoJSON.LineString, RoadFeatureProperties>()

    for (const feature of geojson.features) {
      if (!feature.properties) {
        continue
      }

      if (feature.geometry.type === 'Point') {
        const name = feature.properties?.picture
        const path = name ? `https://screen-data.s3.fr-par.scw.cloud/${unref(citySlug)}/pictures/${name}` : null

        tmpPois.push({
          coords: feature.geometry.coordinates,
          id: feature.properties.id,
          name,
          path,
        })
      } else {
        const linestring = feature as GeoJSON.Feature<GeoJSON.LineString, RoadFeatureProperties>
        const name = feature.properties?.picture
        const path = name ? `https://screen-data.s3.fr-par.scw.cloud/${unref(citySlug)}/pictures/${name}` : null

        linestring.properties = {
          id: feature.properties.id,
          ...Object.keys(feature.properties).reduce((acc, metric) => {
            const value = feature.properties?.[metric as RoadMetric]

            if (value === undefined || value === null) {
              return acc
            }

            acc[metric as RoadMetric] = value
            return acc
          }, {} as Record<RoadMetric, number>),
          name: feature.properties?.picture,
          path,
        }

        const roadLine = linestring as GeoJSON.Feature<GeoJSON.LineString, RoadFeatureProperties>

        tmpRoadsGeojson.features.push(roadLine)
      }
    }

    pictures.value = tmpPois
    roadsGeojson.value = tmpRoadsGeojson
    isFetching.value = false
  })

  return {
    loading: isFetching,
    roadsGeojson,
    pictures,
    availablePathsMetrics,
    pathsMetric,
  }
})
