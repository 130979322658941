<script setup lang="ts">
defineOptions({
  name: 'CyclabilityOverviewBarcelona',
})

const { t } = useI18n()
const { dateRange, resetDateRange } = useCyclabilityCitiesData()
const { translateRange } = useDateTranslation()

onBeforeUnmount(() => {
  resetDateRange()
})
</script>

<template>
  <BoardSubTitle icon="list">
    <span class="capitalized">{{ t('dates.daterange_formated_human', translateRange(dateRange)) }}</span>
  </BoardSubTitle>

  <BoardGrid class="mt-4 grid-cols-1 lg:grid-cols-6">
    <CityCyclabilityPassagesMetric
      :subtitle="t('cyclability.cycling_passages.subtitle')"
      class="lg:col-span-3 2xl:col-span-4"
    />
    <CityCyclabilityBikesPerformance class="lg:col-span-3 2xl:col-span-2" />
    <CityCyclabilityPassagesHistoryMetric
      :subtitle="t('cyclability.cycling_passages_history.subtitle')"
      class="lg:col-span-6"
    />
    <CityCyclabilitySharedBikesPerfMetric class="lg:col-span-6" />
  </BoardGrid>

  <BoardSubTitle icon="list">
    {{ t('cyclability.additional_informations') }}
  </BoardSubTitle>

  <BoardGrid class="mt-4 grid-cols-1 lg:grid-cols-6">
    <CityCyclabilityCyclistAnalysisMetric class="lg:col-[1_/_span_3]" />
    <CityCyclabilityNetworkOverview class="lg:col-span-3 row-span-2" />
    <CityCyclabilityOthersAnalysisMetric class="lg:col-[1_/_span_3]" />
  </BoardGrid>
</template>
