<script setup lang="ts">
defineOptions({
  name: 'CityCyclabilityPassagesMetric',
})

defineProps<{
  subtitle?: string
}>()

interface DataItem {
  counter: number
}

const { t } = useI18n()
const { writeCache } = useWriteCacheForMetric('cityCyclabilityPassages')
const { dateRangeCounter, dataInRange, comparedDataInRange, lastDateRange, city } = useCyclabilityCitiesDataFromCounter()
const { translateRange } = useDateTranslation()

function updateCachedData(dataInRange: any, comparedDataInRange: any) {
  if (!dateRangeCounter.value) {
    return
  }

  const avgLength = (dataInRange?.length || 1)
  const avgDaily = dataInRange?.reduce((acc: number, item: DataItem) => acc + item.counter, 0) / avgLength
  const maximum = dataInRange?.reduce((acc: number, item: DataItem) => acc < item.counter ? item.counter : acc, 0)
  const total = dataInRange?.reduce((acc: number, item: DataItem) => acc + item.counter, 0)

  if (comparedDataInRange && comparedDataInRange.length > 0) {
    const avgLengthLast = (comparedDataInRange?.length || 1)
    const avgDailyLast = comparedDataInRange?.reduce((acc: number, item: DataItem) => acc + item.counter, 0) / avgLengthLast
    const maximumLast = comparedDataInRange?.reduce((acc: number, item: DataItem) => acc < item.counter ? item.counter : acc, 0)
    const totalLast = comparedDataInRange?.reduce((acc: number, item: DataItem) => acc + item.counter, 0)

    const dailyTrendPercent = (avgDaily - avgDailyLast) / avgDaily * 100
    const dailyTrendDirection = dailyTrendPercent > 0 ? Directions.UP : Directions.DOWN
    const dailyTrendPositive = dailyTrendPercent > 0

    const maximumTrendPercent = (maximum - maximumLast) / maximum * 100
    const maximumTrendDirection = maximumTrendPercent > 0 ? Directions.UP : Directions.DOWN
    const maximumTrendPositive = maximumTrendPercent > 0

    const totalTrendPercent = (total - totalLast) / total * 100
    const totalTrendDirection = totalTrendPercent > 0 ? Directions.UP : Directions.DOWN
    const totalTrendPositive = totalTrendPercent > 0

    writeCache({
      city: city.value,
    }, {
      date: dateRangeCounter.value.start.toString(),
      daily: {
        __typename: 'Percent',
        value: Math.round(avgDaily),
        trend: {
          percent: Number(dailyTrendPercent.toFixed(2)),
          direction: dailyTrendDirection,
          positive: dailyTrendPositive,
        },
      },
      maximum: {
        __typename: 'Percent',
        value: Math.round(maximum),
        trend: {
          percent: Number(maximumTrendPercent.toFixed(2)),
          direction: maximumTrendDirection,
          positive: maximumTrendPositive,
        },
      },
      total: {
        __typename: 'Percent',
        value: Math.round(total),
        trend: {
          percent: Number(totalTrendPercent.toFixed(2)),
          direction: totalTrendDirection,
          positive: totalTrendPositive,
        },
      },
    })
  } else {
    writeCache({
      city: city.value,
    }, {
      date: dateRangeCounter.value.start.toString(),
      daily: {
        __typename: 'Percent',
        value: Math.round(avgDaily),
        trend: null,
      },
      maximum: {
        __typename: 'Percent',
        value: Math.round(maximum),
        trend: null,
      },
      total: {
        __typename: 'Percent',
        value: Math.round(total),
        trend: null,
      },
    })
  }
}

watch([dataInRange, comparedDataInRange, city], ([dataInRange, comparedDataInRange, city]) => {
  if (!dataInRange || !city) {
    return
  }

  updateCachedData(dataInRange, comparedDataInRange)
}, { immediate: true })
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityPassages"
    :used-filters="[]"
  >
    <template #title>
      {{ t('cyclability.cycling_passages.title') }}
    </template>

    <template #subtitle>
      {{ t('dates.daterange_formated_human', translateRange(dateRangeCounter)) }}{{ subtitle ? ` - ${subtitle}` : '' }}
    </template>

    <template #default="{ data }">
      <div class="flex flex-col xl:flex-row gap-4 flex-wrap">
        <div class="rounded-md bg-grey-50 p-4 flex-1">
          <p class="text-sm mb-2">
            {{ t('cyclability.cycling_passages.daily') }}
          </p>
          <TrendMetric
            :data="data.daily"
            :trend-title="lastDateRange ? `VS ${t('dates.daterange_formated_human', translateRange(lastDateRange))}` : undefined"
          />
        </div>
        <div class="rounded-md bg-grey-50 p-4 flex-1">
          <p class="text-sm mb-2">
            {{ t('cyclability.cycling_passages.maximum') }}
          </p>
          <TrendMetric
            :data="data.maximum"
            :trend-title="lastDateRange ? `VS ${t('dates.daterange_formated_human', translateRange(lastDateRange))}` : undefined"
          />
        </div>
        <div class="rounded-md bg-grey-50 p-4 flex-1">
          <p class="text-sm mb-2">
            {{ t('cyclability.cycling_passages.total') }}
          </p>
          <TrendMetric
            :data="data.total"
            :trend-title="lastDateRange ? `VS ${t('dates.daterange_formated_human', translateRange(lastDateRange))}` : undefined"
          />
        </div>
      </div>
    </template>
  </MetricCard>
</template>
