<script setup>
defineProps({
  freemiumPlan: {
    type: Object,
    required: true,
  },
  showExploreBtn: {
    type: Boolean,
    default: true,
  },
  showUpgradeBtn: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['explore', 'upgrade'])

const { t } = useI18n()
</script>

<template>
  <div>
    <div class="mt-4 flex justify-evenly gap-2 md:gap-4">
      <div class="text-right uppercase text-grey-400">
        <p
          v-for="(item, i) in [
            t('Cities', 2),
            t('Operators', 2),
            t('Newsfeed'),
            t('Data export'),
          ]"
          :key="item"
          :class="{
            'mt-28': i === 0,
          }"
          class="flex h-9 items-center justify-end text-xs md:text-base"
        >
          {{ item }}
        </p>
      </div>

      <div>
        <div class="py-0.5 md:py-1 uppercase text-teal-500">
          <h2 class="flex-center flex h-28 flex-col font-title text-base font-bold sm:text-xl">
            <img
              class="my-2"
              src="@/assets/svg/icon-backpacker.svg"
            >
            {{ t('data.plans.freemium') }}
            <span class="text-base font-normal">{{ t('free') }}</span>
          </h2>
          <div class="divide-y divide-grey-100">
            <p class="flex-center flex h-9">
              {{ freemiumPlan?.data?.cities?.length ?? 0 }}
            </p>
            <p
              v-for="i in 2"
              v-once
              :key="i"
              class="flex-center flex h-9"
            >
              {{ t('limited') }}
            </p>
            <p
              v-for="i in 2"
              v-once
              :key="i"
              class="flex-center flex h-9"
            >
              X
            </p>
          </div>
        </div>
        <DButton
          v-if="showExploreBtn"
          class="mt-4 w-full bg-grey-500! hidden md:block"
          @click="emit('explore')"
        >
          <slot name="explore-text">
            {{ t('Start exploring') }}
          </slot>
        </DButton>
      </div>

      <div>
        <div
          class="rounded-lg bg-linear-to-b from-blue-500 to-blue-700 px-1 md:px-6 py-0.5 md:py-1 uppercase text-white"
        >
          <h2 class="flex-center flex h-28 flex-col whitespace-nowrap font-title text-base font-bold sm:text-xl">
            <img
              class="my-2"
              src="@/assets/svg/icon-great-explorer.svg"
            >
            {{ t('data.plans.premium') }}
            <span class="text-base font-normal">{{ t('premium') }}</span>
          </h2>
          <div class="divide-y divide-grey-100">
            <p class="flex-center flex h-9 font-title font-bold sm:text-2xl">
              180+
            </p>
            <p class="flex-center flex h-9 font-title font-bold sm:text-2xl">
              240+
            </p>
            <p
              v-for="i in 3"
              v-once
              :key="i"
              class="flex-center flex h-9"
            >
              <DIcon
                path="check"
                size="lg"
              />
            </p>
          </div>
        </div>

        <DButton
          v-if="showUpgradeBtn"
          class="mt-4 w-full hidden md:block"
          @click="emit('upgrade')"
        >
          <slot name="upgrade-text">
            {{ t('switch_to_plan', { subscriptionPlan: t('data.plans.premium') }) }}
          </slot>
        </DButton>
      </div>
    </div>

    <div class="md:hidden">
      <DButton
        v-if="showUpgradeBtn"
        class="mt-4 w-full"
        @click="emit('upgrade')"
      >
        <slot name="upgrade-text">
          {{ t('switch_to_plan', { subscriptionPlan: t('data.plans.premium') }) }}
        </slot>
      </DButton>

      <p class="uppercase text-grey-300 text-lg py-2 text-center">
        {{ t('or') }}
      </p>

      <DButton
        v-if="showExploreBtn"
        class="w-full bg-grey-500!"
        @click="emit('explore')"
      >
        <slot name="explore-text">
          {{ t('Start exploring') }}
        </slot>
      </DButton>
    </div>
  </div>
</template>
