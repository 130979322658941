<script setup>
defineOptions({
  name: 'Filters',
})

defineProps({
  hideReset: Boolean,
})

const emit = defineEmits(['reset'])

const { t } = useI18n()
const route = useRoute()
const { mode } = useMode()
const navigationStore = useNavigationStore()
const { onReset } = useResetFilters(emit)

const metaFilters = computed(() => route.meta.filters)
const metaType = computed(() => route.meta.type)

const showFilter = reactive({
  mode: get(metaFilters)?.mode,
  dateRange: get(metaFilters)?.dateRange,
  comparativeRange:
    get(metaFilters)?.dateRange && get(metaFilters)?.comparativeRange,
  timeMode: get(metaFilters)?.timeMode,
  region: get(metaFilters)?.region,
  country: get(metaFilters)?.country,
  filterBySpecs:
    mode.value === VEHICLE_TYPE_CAR && get(metaFilters)?.filterBySpecs,
})

watch(
  metaFilters,
  (metaFilters) => {
    showFilter.mode = metaFilters?.mode
    showFilter.dateRange = metaFilters?.dateRange
    showFilter.comparativeRange
      = metaFilters?.dateRange && metaFilters?.comparativeRange
    showFilter.timeMode = metaFilters?.timeMode
    showFilter.region = metaFilters?.region
    showFilter.country = metaFilters?.country
    showFilter.filterBySpecs
      = mode.value === VEHICLE_TYPE_CAR && metaFilters?.filterBySpecs
  },
  { immediate: true, deep: true },
)

onMounted(async () => {
  await nextTick()
  navigationStore.filtersIsMounted = true
})

onBeforeUnmount(() => {
  navigationStore.filtersIsMounted = false
})
</script>

<template>
  <div
    class="space-y-2 flex flex-col items-stretch bg-white md:rounded-md px-4 pt-4"
  >
    <div class="flex justify-between">
      <p class="uppercase text-sm text-grey-300">
        <DIcon
          path="settings"
          size="sm"
        />
        {{ t('Settings') }}
      </p>
      <DLink
        v-if="!hideReset"
        class="text-sm text-blue-700 hover:underline"
        @click="() => onReset()"
      >
        {{ t('Reset all') }}
      </DLink>
    </div>
    <FilterMode v-if="showFilter.mode" />
    <FilterBySpecs
      v-if="showFilter.filterBySpecs"
      :type="metaType"
    />
    <FilterDateRange
      v-if="showFilter.dateRange"
      :no-comparison="!showFilter.comparativeRange"
    />
    <FilterRegion v-if="showFilter.region" />
    <FilterCountry v-if="showFilter.country" />
    <FilterTimeMode v-if="showFilter.timeMode" />

    <slot />

    <div class="sticky bottom-0 -mx-4! my-0! p-0!">
      <div class="bg-linear-to-t from-white to-transparent h-4 w-full" />
    </div>
  </div>
</template>
